// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData} from "framework/src/Utilities";
import { createRef } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface CategoryStructure {
  "id": "8",
  "type": "category",
  "attributes": {
    "name": "science",
    "image": {
      "url": "/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6d2d6992001eb7f2f2028b8dd19912c9a5e62764/img.jpg"
    },
    "created_at": "2024-09-17T07:22:23.376Z",
    "updated_at": "2024-09-17T07:22:23.391Z"
  }
}

export interface SubCategoryStructure {
  "id": "1",
  "type": "sub_category",
  "attributes": {
    "id": 1,
    "name": "Pre Primary (kg)",
    "created_at": "2024-06-26T23:32:49.745Z",
    "updated_at": "2024-06-26T23:32:49.745Z",
    "categories": [],
    "image": null
  }
}
interface PodcastFormValues {
  podcastCover: File | null;
  podcastName: string;
  description: string;
  hostFirstName: string;
  hostLastName: string;
  hostEmail: string;
  hostLocation: string;
  podcastLocation: string;
  podcastCategory: string;
  podcastSubCategory: string;
}
export interface S {
  files: unknown[],
  imageUrl: string,
  categoryList: CategoryStructure[],
  subCategoryList: SubCategoryStructure[],
  selectedCategory: any,
  invalidFileMessage: string,
  firstName: string,
  lastName: string,
  location: string,
  emailAdd: string
}

export interface SS {
  id: any;
}

export default class EmailForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  podcastCategoryApiCallId: string = "";
  podcastSubCategoryApiCallId: string = "";
  userDetailsApiCallId: string = "";
  scrollRef: any = createRef();

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      files: [],
      imageUrl: "",
      categoryList: [],
      subCategoryList: [],
      selectedCategory: 1,
      invalidFileMessage: "",
      firstName: "",
      lastName: "",
      location: "",
      emailAdd: ""
    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.handleResForPodcastCategoryApi(from, message)
    this.handleResForPodcastSubCategoryApi(from, message)
    this.handleResForuserSettingsApi(from,message)
  }


  async componentDidMount() {
    this.categoryApi()
    this.subCategoryApi()
    this.userDetails()
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }
  userDetails = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleResForuserSettingsApi = async (from: string, message: Message) => {
    if (this.userDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({ firstName: responseJson.data.attributes.first_name, lastName: responseJson.data.attributes.last_name, location: responseJson.data.attributes.user_location, emailAdd: responseJson.data.attributes.email })
      }
    }
  };
  handleDrop = (event: React.DragEvent<HTMLDivElement>, setFieldValue: any) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (files.length) {
      this.handleFile(files[0], setFieldValue);
    }
  };

  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length) {
      this.handleFile(files[0], setFieldValue);
    }
  };

  handleFile = (file: File, setFieldValue: any) => {
    if (file) {
      const img = new Image();
      img.onload = () => {
        if (img.width !== 3000 || img.height !== 3000) {
          this.setState({ invalidFileMessage: "Photo is not 3000x3000, please upload new photo of that size to move forward" });
        }
        else {
          this.setState({ invalidFileMessage: "" });
        }
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ imageUrl: reader.result as string });
        const base64String = reader.result as string;
        img.src = base64String;
        setFieldValue("podcastCover", file);
      };
      reader.readAsDataURL(file);
    };
  }

    categoryApi = () => {
      const header = {
        "Content-Type": configJSON.loginApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.podcastCategoryApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCategoryDropdown
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleResForPodcastCategoryApi = async (from: string, message: Message) => {
      if (
        this.podcastCategoryApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson) {
          this.setState({ categoryList: responseJson.data })
        }
      }
    };


    subCategoryApi = async () => {
      let token = await getStorageData("token");
      const selectedCategory = this.state.selectedCategory;
      const header = {
        token: JSON.parse(token),
      };
      let apiEndpoint = configJSON.getSubCategoryDropdown;

      if (selectedCategory && selectedCategory.id) {
        apiEndpoint = `${apiEndpoint}?category_id=${selectedCategory.id}`;
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.podcastSubCategoryApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    };


  handleResForPodcastSubCategoryApi = async (from: string, message: Message) => {
    if (
      this.podcastSubCategoryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ subCategoryList: responseJson.data })
        console.log(responseJson.data,"responseJson.data subcategory")
      }           
    }
  };

  handleFileToLocalStorage = (file: File | null) => {
    if(file) {
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    }
  };

  handleSubmit = async (values: PodcastFormValues) => {
   console.log(values)
  }
 
}
// Customizable Area End
