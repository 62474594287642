// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from 'react'
export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void
}

interface SS {
    id: any;
}
  
interface S {
  channelActiveImg: string | null;
  podcastEpisodes:any;
  podcastData:any;
  loading:boolean;
  isDeleteEpisode:boolean;
  deleteId:string;
}

export default class HostPartnerPortalController extends BlockComponent<Props, S, SS> {
  apiPodcastEpisodesCallId: string = "";
  apiDeleteEpisodesCallId: string = "";
  scrollRef: any = createRef();
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      channelActiveImg: null,
      podcastEpisodes:[],
      podcastData:[],
      loading:false,
      isDeleteEpisode:false,
      deleteId:"",
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
    
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
  }

  handleAcceptedOffers = () => {
    this.props.handleCommonPath("AcceptedHostOffers")
  }

  handlePendingOffers = () => {
    this.props.handleCommonPath("PendingHostOffers")
  }

  handleRejectedOffers = () => {
    this.props.handleCommonPath("RejectedHostOffers")
  }

 
 // Customizable Area End
}
