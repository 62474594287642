// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from 'react'
export const configJSON = require("./config.js");
import {offersImg} from "./assets";

export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void
}

interface SS {
    id: any;
}
export interface AdData {
  title: string;
  description: string;
  image: string;
  expiry: string;
}

interface S {
  channelActiveImg: string | null;
  loading:boolean;
  isDeleteEpisode:boolean;
  deleteId:string;
  displayAds: AdData[];
  preRollAds: AdData[];
  postRollAds: AdData[];
  sponsoredRecommendations: AdData[];
  sponsoredPosts: AdData[];
}

export default class AcceptedOffersController extends BlockComponent<Props, S, SS> {
  apiPodcastEpisodesCallId: string = "";
  apiDeleteEpisodesCallId: string = "";
  scrollRef: any = createRef();
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      channelActiveImg: null,
      loading:false,
      isDeleteEpisode:false,
      deleteId:"",
      displayAds: [
        { title: 'Heinz', description: 'Ad Spot: Host Channel', image: offersImg, expiry: '12/8/24' },
        { title: 'Febreeze', description: 'Ad Spot: Host Channel', image: offersImg, expiry: '12/9/24' },
        { title: 'Burger Seven', description: 'Ad Spot: Episode View', image: offersImg, expiry: '12/9/24' },
      ],
      preRollAds: [{ title: 'Colgate', description: 'Ad Spot: Pre-Roll Ad', image: offersImg, expiry: '12/8/24' }],
      postRollAds: [{ title: 'Burger Fest', description: 'Ad Spot: Post-Roll Ad', image: offersImg, expiry: '12/8/24' }],
      sponsoredRecommendations: [
        { title: 'CreArt', description: 'Ad Spot: Sponsored Rec', image: offersImg, expiry: '12/8/24' },
        { title: 'MacDigital', description: 'Ad Spot: Sponsored Rec', image: offersImg, expiry: '12/8/24' },
        { title: 'McDonald\'s', description: 'Ad Spot: Sponsored Rec', image: offersImg, expiry: '12/8/24' },
      ],
      sponsoredPosts: [
        { title: 'Samos Cad', description: 'Partner', image: offersImg, expiry: '12/8/24' },
        { title: 'Apple', description: 'Partner', image: offersImg, expiry: 'N/A' },
        { title: 'Samsung', description: 'Partner', image: offersImg, expiry: '12/9/24' },
      ],
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
    
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
  }
 
 // Customizable Area End
}
