// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    Button,
    Dialog,
    DialogContent
} from "@mui/material";
import HostPaymentPlanController, {
    Props,
} from "./HostPaymentPlanController";
import { darkBg, lightBg,star } from "./assets";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";

const customPricing = "https://forms.zohopublic.com/podcasttown/form/FreePodcastStrategySessionForm/formperma/wCcjSM2RW3qE0TiKERkPz4A1e6Pj1x9Lz4XvuMOU_wk"

export default class HostPaymentPlan extends HostPaymentPlanController {
    constructor(props: Props) {
        super(props);
    }
   
    render() {
        const partner = this.props.loginType == "partner";
        const isHostLogin = this.state.isHostLogin;
        const { selectedBox } = this.state;
        return (
            <>
            <Box ref={this.scrollRef} >
            <StyleWrapperHostPaymentPlan>
                <Grid className="container">
                    <Grid className="upper">
                        <Box className="title">Choose Your Pricing Model</Box>
                        <Box className="desc">Our pricing is straightforward and "All-Inclusive," giving you access to the full range of features, benefits, and our vibrant community at Podcast Town. For content networks looking for a tailored solution, connect with us. Our platform specialists will work closely with you to understand your unique needs and deliver a customized approach that aligns with your goals.</Box>
                    </Grid>
                   {isHostLogin ? <Grid>
                        <Box className="lowerContainer">
                            <Box  className={`boxS box1 ${selectedBox === "box1" ? "selectedBox" : ""}`}>
                                <Box className="imageContainer">
                                    <img src={lightBg} alt="lightBg" className="img" />
                                </Box>
                                <Box className="label"><b>Simple "All-Inclusive" Pricing</b></Box>
                                <Box className="price">${this.state.annualPrice}</Box>
                                <Box className="secondaryTitle"><span style={{fontWeight : "bold"}}><b>Hosts & Partners</b></span> <span style={{ fontWeight: "bold", fontStyle: "italic", fontFamily: "Nexa-Regular" }}>Annual Subscription</span></Box>
                                <hr className="horizontal" />
                                <Button  onClick={() => this.handleBoxSelect("box1")} className="selectPlanBtn" style={{ background: "#030303",border:"1px solid #FFFFFF"}} data-test-id="currentbtn"><b>Current Plan</b></Button>
                            </Box>

                            <Box  className={`boxS box2 ${selectedBox === "box2" ? "selectedBox" : ""}`}>
                                <Box className="imageContainer">
                                    <img src={darkBg} alt="lightBg" className="img" />
                                </Box>
                                <Box className="label" style={{color : "black"}}><b>Simple "All-Inclusive" Pricing</b></Box>
                                <Box className="price" style={{color : "black"}}>${this.state.monthlyPrice}</Box>
                                <Box className="secondaryTitle" style={{color : "black"}}><span style={{fontWeight : 900}}><b>Hosts & Partners</b></span> <span style={{ fontWeight: "bold", fontStyle: "italic", fontFamily: "Nexa-Regular" }}>Monthly Subscription</span></Box>
                                <hr className="horizontal" style={{color:"#030303"}}/>
                                <Button onClick={() => this.handleBoxSelect("box2")} className="selectPlanBtn" style={{ background: "#FF0807" }} data-test-id="secondPlan"><b>Select Plan</b></Button>
                            </Box>
                      
                            <Box className="boxS box3">
                                <Box className="imageContainer">
                                    <img src={lightBg} alt="lightBg" />
                                </Box>

                                <Box className="label"><b>Simple</b></Box>
                                <Box className="customPrice">Custom Pricing</Box>
                                <Box className="secondaryTitle"><span style={{fontWeight : 900,fontFamily:"Nexa-Heavy"}}><b>Enterprise &</b><br /> Networks</span> <span style={{fontWeight: 700,fontFamily:"Nexa-Regular"}}>Customized Solutions</span></Box>
                                <hr className="horizontal"/>
                                <Button className="selectPlanBtn" style={{ background: "black" }} data-test-id="customPricing"   onClick={() => this.openLink(customPricing)}><b>Get a Quote</b></Button>
                            </Box>

                        </Box>
                        <Box sx={{height:"100px"}}>
                        <Button onClick={() => { this.handleOpenSucessModal() }} className="continueBtn" style={{ background: "#FF0807" }} data-test-id="continuebtn"><b>Continue</b></Button>
                        </Box>
                         </Grid>:
                    <Grid>
                        <Box className="lowerContainer">
                            <Box className="boxS box1">
                                <Box className="imageContainer">
                                    <img src={lightBg} alt="lightBg" className="img" />
                                </Box>
                                <Box className="label"><b>Simple "All-Inclusive" Pricing</b></Box>
                                <Box className="price">${this.state.annualPrice}</Box>
                                <Box className="secondaryTitle"><span style={{fontWeight : "bold"}}><b>Hosts & Partners</b></span> <span style={{ fontWeight: "bold", fontStyle: "italic", fontFamily: "Nexa-Regular" }}>Annual Subscription</span></Box>
                                <hr className="horizontal" />
                                <Button onClick={()=>this.props.handleCommonPath("HostPaymentDetails")} className="selectPlanBtn" style={{ background: "#FF0807" }}><b>Select Plan</b></Button>
                            </Box>

                            <Box className="boxS box2">
                                <Box className="imageContainer">
                                    <img src={darkBg} alt="lightBg" className="img" />
                                </Box>
                                <Box className="label" style={{color : "black"}}><b>Simple "All-Inclusive" Pricing</b></Box>
                                <Box className="price" style={{color : "black"}}>${this.state.monthlyPrice}</Box>
                                <Box className="secondaryTitle" style={{color : "black"}}><span style={{fontWeight : 900}}><b>Hosts & Partners</b></span> <span style={{ fontWeight: "bold", fontStyle: "italic", fontFamily: "Nexa-Regular" }}>Monthly Subscription</span></Box>
                                <hr className="horizontal" style={{color:"#030303"}}/>
                                <Button onClick={()=>this.props.handleCommonPath("HostPaymentDetails")} className="selectPlanBtn" style={{ background: "#FF0807" }}><b>Select Plan</b></Button>
                            </Box>
                      
                            <Box className="boxS box3">
                                <Box className="imageContainer">
                                    <img src={lightBg} alt="lightBg" />
                                </Box>

                                <Box className="label"><b>Simple</b></Box>
                                <Box className="customPrice">Custom Pricing</Box>
                                <Box className="secondaryTitle"><span style={{fontFamily:"Nexa-Heavy",fontWeight : 900}}><b>Enterprise &</b><br /> Networks</span> <span style={{fontWeight: 700,fontFamily:"Nexa-Regular"}}>Customized Solutions</span></Box>
                                <hr className="horizontal"/>
                                <Button className="selectPlanBtn"  data-test-id="customPricing" style={{ background: "black" }} onClick={() => this.openLink(customPricing)}><b>Get a Quote</b></Button>
                            </Box>

                        </Box>
                    </Grid>}
                </Grid>
            </StyleWrapperHostPaymentPlan>
             <Dialog
                open={this.state.isSucessOpen}
                PaperProps={{
                    sx: {
                        height: "337px",
                        borderRadius: "8px 8px 32px 8px",
                        backgroundColor: "transparent",
                        width: "600px",
                    },
                }}
                componentsProps={{
                    backdrop: {
                    style: { backgroundColor: 'rgba(0, 0, 0, 1)' },
                    },
                }}
            >
                <DialogContent
                    style={{ backgroundColor: "#44403C", padding: 0 , color: "white"}}>
                    <Box>
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8%" }}>
                            <img src={star} alt="star" />
                        </Box>

                        <h2 style={{  fontFamily: "Nexa-Heavy",textAlign: "center" }}>Success</h2>
                        <p style={{fontFamily: "Nexa-Regular",textAlign: "center"}}>You Are Already Subscribed
                        </p>
                    </Box>
                    <Box style={{
                        justifyContent: "center",
                        width: "100%", height: "104px", borderTop: "1px solid white", display: "flex",
                        alignItems: "center", marginTop: "50px"
                    }} id="loginModal">
                            <Button 
                            onClick={()=>{this.props.handleCommonPath("PartnerSetupMethod")}}
                            data-test-id="buttonClick"
                            style={{
                                height: "56px",
                                width: "auto",
                                borderRadius: "8px",
                                padding:"0 5%",
                                backgroundColor: "#FF0807",
                                textTransform: "capitalize",
                                color: "white",
                                fontWeight: 900,
                                fontSize: "14px",
                                margin: "auto",
                                marginTop: "24px",
                                marginBottom : "30px"
                            }}>Start To Setup My {partner ? "Advertising Profile" :"Podcast"}</Button>
                    </Box>
                </DialogContent>
            </Dialog> 
            <Footer navigation={this.props.navigation} id={"1"} />
            </Box>
            </>
            
        );
    }

}
const StyleWrapperHostPaymentPlan = styled('div')({
    margin: "0 auto",
    height: "auto",
    padding:"20px 50px",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .customPrice":{
        fontFamily : "Nexa-Heavy",
        color : "white",
        fontWeight : 900,
        textAlign:"center",
        fontSize:"60px",
        lineHeight:1,
        marginLeft : "auto",
        height : "136px"
    },
    "& .selectPlanBtn": {
        textTransform : "inherit",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "18px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        color: "#F5F5F5",
        cursor: "pointer",
        height : "56px",
        width : "277px",
        marginTop : "6%",
        borderRadius : "8px"
    },
    "& .continueBtn": {
        textTransform : "inherit",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "18px",
        display: "flex",
        justifyContent: "center",
        height : "56px",
        width : "auto",
        padding:"0 10%",
        borderRadius : "8px",
        alignItems: "center",
        margin: "auto",
        color: "#F5F5F5",
        cursor: "pointer",
    },
    "& .horizontal": {
        width: "80%",
        height:"1px",
        textAlign: "center",
        marginTop : "1%",
        color:"Light grey",
        marginBottom : "3%"
    },
    "& .secondaryTitle": {    
        margin: "auto",
        marginTop: "6%",
        marginBottom: "8%",
        width: "277px",
        height: "65px",
        display: "flex",
        flexDirection: "column",
        color: "#F5F5F5",
        fontFamily: "Nexa-Heavy",
        fontSize: "24px",
        textAlign: "center",
        justifyContent: "center",
        lineHeight : 1,
    },   
    "& .price": {
        marginTop: "13%",
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "60px",
        textAlign: "center",
    },
    "& .label": {
        textAlign: "center",
        width: "277px",
        height: "54px",
        margin: "auto",
        fontFamily: "Nexa-Bold",
        marginTop: "7%",
        display: "flex",
        justifyContent: "center",
        fontSize: "24px",
        fontWeight: 400
    },
    "& .imageContainer": {
        display: "flex",
        justifyContent: "center",
        marginTop: "10%"
    },
    "& .lowerContainer": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width : "90%",
        margin : "auto",
        marginBottom: "5%",
        alignItems: "stretch", 
        "@media (max-width: 1200px)": {
            flexDirection: "column", 
            alignItems: "center",
        },
    },
    "& .boxS": {
        height: "533px",
        width: "31%",
        borderRadius: "26px",
        border: "1px solid #B2B3B3",
        "@media (max-width: 1200px)": {
            marginBottom: "20px", 
            width: "350px",
        },
    },
    "& .box1": {
        background: "black"
    },
    "& .box2": {
        background: "white"
    },
    "& .box3": {
        background: "#FF0807"
    },
    "& .title": {
        color: "white",
        fontWeight: 900,
        fontSize: "30px",
        fontFamily: "Nexa-Heavy",
    },
    "& .desc": {
        margin: "auto",
        marginTop: "2%",
        marginBottom : "3%",
        fontWeight: 100,
        fontSize: "16px",
        fontFamily: "Nexa-Regular",
        width: "88%"
    },
    "& .upper": {
        height: "auto",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "5%",
        margin: "auto",
        textAlign: "center"
    },
    "& .container": {
        color: "white",
        height : "auto",
        margin: "auto",
        background: "#44403C",
        "@media (max-width: 1200px)": {
            height: "auto",
        },     
    },
    "& .scroll":{
        position: "relative",
        height: "44rem",
        paddingRight: "40px", 
        overflow: "hidden",     
        scrollbarWidth: "thin", 
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
    },
   "& .selectedBox":{
        border: "5px solid red",
        borderRadius: "10px",
    }
})
// Customizable Area End
