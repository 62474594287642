import React from "react";

// Customizable Area Start
import { Box, Button, Typography, styled } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { engageBackImg, analyticsItem1, analyticsItem2, analyticsItem3 } from "./assets";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ overflowY: "auto", maxHeight: "100%" }}>
        <StyledWrapper>
          <img
            width={"100%"} style={{ objectFit: "contain", borderRadius: "8px" }}
            src={engageBackImg} alt="backgroundImg"
          />
          <Box bgcolor={"#44403C"} pb={"3rem"}>
            <Typography style={{
              fontSize: "30px", fontWeight: 900, padding: "32px 0px", 
              color: "#FFFFFF", textAlign: "center", fontFamily: "Nexa-Heavy"
            }}>
              Analytics
            </Typography>
            <Box style={{
              marginBottom: "16px", display: "flex", gap: "20px",
              alignItems: "center", justifyContent: "center",
            }}>
              <Box style={{
                flexDirection: "column", display: "flex", justifyContent: "space-between",
                alignItems: "center", background: "#312F2D", borderRadius: "16px",
                paddingBottom: "15px", width: "300px", height: "299px",
              }}>
                <img
                  style={{
                    maxWidth: "100%", width: "300px", height: "223px",
                    borderRadius: "16px 16px 0px 0px",
                  }}
                  src={analyticsItem1}
                />
                <Button style={{
                  width: "268px", height: "36px", textTransform: "none",
                  fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "16px",
                  background: "#FF0807", color: "#FFFFFF", borderRadius: "8px",
                }}
                >
                  Listener/Viewer Analytics
                </Button>
              </Box>
              <Box style={{
                width: "300px", height: "299px", borderRadius: "16px",
                paddingBottom: "15px", alignItems: "center", display: "flex",
                flexDirection: "column", justifyContent: "space-between", background: "#312F2D"
              }}>
                <img
                  src={analyticsItem2}
                  style={{
                    maxWidth: "100%", width: "300px",
                    borderRadius: "16px 16px 0px 0px", height: "223px",
                  }}
                />
                <Button style={{
                  fontWeight: 900, fontFamily: "Nexa-Heavy", fontSize: "16px",
                  width: "268px", height: "36px", textTransform: "none",
                  color: "#FFFFFF", borderRadius: "8px", background: "#FF0807",
                }}
                >
                  Community Analytics
                </Button>
              </Box>
              <Box style={{
                justifyContent: "space-between", display: "flex", paddingBottom: "15px",
                width: "300px", height: "299px", flexDirection: "column",
                alignItems: "center", background: "#312F2D", borderRadius: "16px",
              }}>
                <img
                  style={{
                    borderRadius: "16px 16px 0px 0px", height: "223px", width: "300px",
                    maxWidth: "100%", 
                  }}
                  src={analyticsItem3}
                />
                <Button style={{
                  color: "#FFFFFF", borderRadius: "8px", background: "#FF0807",
                  width: "268px", height: "36px", textTransform: "none",
                  fontFamily: "Nexa-Heavy", fontSize: "16px", fontWeight: 900,
                }}
                  data-test-id="navigateToWalletTestBtn"
                  onClick={this.navigateToWalletScreen}
                >
                  Wallet
                </Button>
              </Box>
            </Box>
          </Box>
        </StyledWrapper>
        <Footer navigation={this.props.navigation} id={"1"} />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const StyledWrapper = styled(Box)({
  padding: "20px 50px",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
})

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
