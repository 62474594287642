// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
    Typography,
} from "@mui/material";
import PartnerSetupMethodController, {
    Props,
} from "./PartnerSetupMethodController";
import { advertising, checkbox, checkboxTicked,rightImage, } from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
const privacyPolicyLink = "https://www.podcasttown.net/privacy-policy"

export default class PartnerSetupMethod extends PartnerSetupMethodController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { selectedContainer } = this.state;
        return (
            <>
                <Box ref={this.scrollReff}>
                    <StyledWrapperHostSetup>
                        <Box className="container">
                            <Box className="upper"></Box>
                            <Box className="title">
                                <Box className="title1">Let’s get Started!</Box>
                                <Box className="title1">What Are Your Advertising Preferences?</Box>
                            </Box>
                            <Box className="tickCheckBox">
                                <img src={ResolveCondition(this.state.checkTick, checkboxTicked, checkbox)} onClick={this.handleTickMark} data-test-id="tickmark" alt="checkbox" />
                                <Box className="checkboxContent">
                                    <Box>
                                        You have to agree with&nbsp;
                                        <span
                                            style={{ color: "#007BFF", cursor: "pointer" }}
                                            data-test-id="privacyPolicy"
                                            onClick={() => this.openLink(privacyPolicyLink)}>
                                            Privacy policy&nbsp;
                                            <span style={{ color: "#fff" }}>and&nbsp;</span>
                                            <br />
                                        </span>
                                        <span
                                            data-test-id="termsConditions"
                                            style={{ color: "#007BFF", cursor: "pointer" }}
                                            onClick={() => {
                                                this.props.handleCommonPath("TermsConditions")
                                            }}>
                                            Terms and conditions&nbsp;
                                        </span>
                                        to Sign up
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="mainContainer">
                                <Box className={`container1 ${selectedContainer.includes(1) ? "selected" : ""}`}
                                 data-test-id="selectBtn" onClick={() => this.handleSelectContainer(1)}>
                                    <Box className="image">
                                        <img src={advertising} alt="AdvertisingImage" className="image" />
                                    </Box>
                                    <Box className="desc">
                                   <Typography className="heading">Ad Offers to Hosts</Typography> 
                                   <Typography className="subheading">I want to make Ad Offers directly to Hosts so they can apply them to their channel or episodes</Typography>
                                    </Box>
                                </Box>
                                <Box className={`container1 ${selectedContainer.includes(2) ? "selected" : ""}`}
                                 onClick={() => this.handleSelectContainer(2)}>
                                    <Box className="image">
                                        <img src={rightImage} alt="middleImage" className="image" />
                                    </Box>
                                    <Box className="desc">
                                        <Typography className="heading">Ad Offers to the Platform</Typography>
                                        <Typography className="subheading">I want to make Ad Offers to the Platform so they can be displayed throughout the Platform and can help facilitate larger deals across various podcasters</Typography>
                                   </Box>

                            </Box>
                            </Box>
                            <Box className="buttons">
                                <Button
                                    onClick={() => {
                                        this.props.handleCommonPath("TypesOfAds")
                                    }}
                                    data-test-id="navigationBtn"
                                    className={ResolveCondition(this.state.checkTick, "btn1color", "btn1")}
                                    disabled={(this.state.checkTick ? false : true)}>Continue</Button>
                            </Box>
                        </Box>
                    </StyledWrapperHostSetup>
                    <Footer navigation={this.props.navigation} id={"1"} />
                </Box>
            </>

        );
    }

}
const StyledWrapperHostSetup = styled("div")({
    height: "auto",
    padding:"20px 50px",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .checkboxContent": {
        color: "white",
        fontSize: "14px",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        marginLeft: "1%",
        textTransform: "capitalize",
        borderLeft: "4px solid #FF0807",
        height: "70%",
        paddingLeft: "1%",
        display: "flex",
        borderRadius: "4px",
        alignItems: "center",
        whiteSpace: "nowrap",
    },
    "& .TermsCondition": {
        color: "#1D4ED8",
    },
    "& .tickCheckBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0%",
        marginTop: "2%",
        marginBottom: "2%",
        height: "20%",
        width: "100%"
    },
    "& .buttons": {
        // width: "753px",
        height: "90px",
        margin: "auto",
        marginBottom: "30px",
        display: "flex",
        marginTop: "1%",
        justifyContent: "center",
        gap: "20px",
    },
    "& .btn1": {
        height: "56px",
        width: "315px",
        background: "#B2B3B3",
        color: "white !important",
        borderRadius: "8px",
        textTransform: "capitalize",
        fontSize: "14px",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        "&:hover": {
            background: "#FF0807",
        },
        cursor: "pointer"
    },
    "& .btn1color": {
        height: "56px",
        width: "315px",
        background: "#FF0807",
        color: "white",
        borderRadius: "8px",
        textTransform: "capitalize",
        fontSize: "14px",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        "&:hover": {
            background: "#FF0807",
        },
        cursor: "pointer"
    },
    "& .desc": {
        height: "auto",
        width: "315px",
        display: "flex",
        flexDirection:"column",
        gap:"10px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        marginTop: "2%",
        padding: "0px 10px"
    },
    "& .image": {
        height: "223px",
        width: "100%",
        borderRadius:"16px"
    },
    "& .container1": {
        height: "370px",
        width: "315px",
        background: "#312F2D",
        borderRadius:"16px",
        display:"flex",
        flexDirection:"column",
        gap:""
    },
    "& .mainContainer": {
        height: "340px",
        // width: "1150px",
        margin: "auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",        
        gap:"24px"
    },

    "& .title": {
        height: "14%",
        width: "100%",
        margin: "auto",
        marginTop: "2%",
        justifyContent: "space-between"
    },
    "& .title1": {
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        fontWeight: 900,
        fontSize: "30px",
    },
    "& .container": {
        borderRadius: "8px",
        color: "white",
        height: "auto",
        width: "90%",
        margin: "auto",
        background: "#44403C",
        marginBottom: "3%"
    },
    "& .upper": {
        height: "15%",
        background: "#FF0807",
        borderRadius: "8px",
    },
    "& .heading":{
      fontFamily:"Nexa-Heavy",
      fontSize:"18px",
      fontWeight:900,
      color:"#FFFFFF",

    },
    "& .subHeading":{
        fontFamily:"Nexa-regular",
        fontSize:"14px",
        fontWeight:400,
        color:"#FFFFFF",
    },
    "& .selected":{
        border:"5px solid red",
        borderRadius:"10px"
    }
})
// Customizable Area End
