// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { createRef } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface S {
 
}

export interface SS {
  id: any;
}

export default class BlockedUsersController extends BlockComponent<
  Props,
  S,
  SS
> {
  scrollRef: any = createRef();

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    
    this.state = {
    
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }
  handleGoBack = () => {
    this.props.handleCommonPath('AccountSettings');
  }
}
// Customizable Area End
