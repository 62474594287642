// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
    Typography,
    Card,
    CardContent,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import AdOffersSetupController, {
    Props,AdCategory, MicrotargetingCategory
} from "./AdOffersSetupController";
import Footer from "../../navigationmenu/src/Footer.web";

export default class AdOffersSetup extends AdOffersSetupController {
    constructor(props: Props) {
        super(props);
    }
    renderAdOption = (title: string, category: AdCategory, options: string[], showMicrotargeting: boolean) => (
        <Card sx={{ mb: 2, background: "#4D4D4D", color: "#FFFFFF" }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>{title}</Typography>
            <Box>
            <Typography className="types">What types of podcasts ads are you offering? (select all that apply)</Typography>
            <Box sx={{ display: "flex", background:"#030303",borderRadius:"8px",paddingLeft:"10px"}}>
              {options.map((option,index) => (
                <FormControlLabel
                sx={{
                  width: `calc(100% / ${options.length})`,
                  textAlign: "center",
                }}
                  key={option}
                  control={
                    <Radio
                      checked={this.state.selectedAds[category].includes(option)}
                      data-test-id={`option ${index}`}
                      onChange={() => this.toggleSelection(category, option)}
                      className="RadioBtn"
                    />
                  }
                  label={option}
                />
              ))}
            </Box>
            </Box>
            {showMicrotargeting && (
              <Box mt={2}>
                <Typography className="types">Do you want to have Microtargeting?</Typography>
                <RadioGroup
                  row
                  sx={{background:"#030303",borderRadius:"8px",paddingLeft:"10px"}}
                  value={this.state.microtargeting[category as MicrotargetingCategory]}
                  onChange={(e) => this.handleMicrotargetingChange(category as MicrotargetingCategory, e.target.value)}
                >
                  <FormControlLabel value="Yes" data-test-id="option-yes" control={<Radio className="RadioBtn"/>} label="Yes"  sx={{ flex: 1 }}/>
                  <FormControlLabel value="No" control={<Radio className="RadioBtn"/>} label="No"  sx={{ flex: 1 }}/>
                </RadioGroup>
              </Box>
            )}
          </CardContent>
        </Card>
      );
    render() {
        return (
            <>
                <Box ref={this.scrollRef} sx={{ maxHeight: "100%", overflowY: "auto" }}>
                <StyledOffersWrapper>
                <Box className="mainTitle">Setting Up Ad Offers To Hosts</Box>
                <Box>
                <Box className="redContainer"></Box>
                <Box className="container">
                <Box sx={{padding:"5% 10% 2%"}}>
                {this.renderAdOption("Pre/Post Roll Ads", "prePostRollAds", ["Audio", "Video"], true)}
                {this.renderAdOption("Display Ads", "displayAds", ["Audio", "Video", "Graphic", "Text"], true)}
                {this.renderAdOption("Sponsored Posts", "sponsoredPosts", ["Audio", "Video", "Graphic", "Text"], false)}
                {this.renderAdOption("Sponsored Recommendations", "sponsoredRecommendations", ["Channel", "Episode"], false)}
                </Box>
                <Box textAlign="center" mt={0} mb={3}>
                <Button variant="contained" className={`btn ${this.isButtonDisabled() ? "disabled" : "active"}`} >Finish Setting Up Profile</Button>
                </Box>
                </Box>
                </Box>
                </StyledOffersWrapper>
                <Footer navigation={this.props.navigation} id={"1"} />
                </Box>  
            </>
        );
    }

}
const StyledOffersWrapper = styled("div")({
    background: "black",
    padding: "20px 50px",
    "& *": {
        boxSizing: "border-box",
    },
    "& .redContainer": {
      margin: 0,
      height: "80px",
      borderRadius: "16px 16px 0px 0px",
      backgroundColor: "#FF0807",
      width: "95%"
  },
  "& .mainTitle": {
    margin: "auto",
    height: "40px",
    color: "white",
    fontFamily: "Nexa-Heavy",
    fontWeight: 900,
    fontSize: "30px",
    marginBottom: "21px"
  },
  "& .types":{
   fontFamily:"Nexa-Regular",
   fontWeight: 900,
   fontSize:"14px",
   marginBottom:"10px",
  },
  "& .RadioBtn":{
   color: "white",
      "&.Mui-checked":{
        color:"red",
      }
     },
    "& .scrollHostLogin":{
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
        position: "relative",
        height: "34rem",
        paddingRight: "40px", 
        overflow: "hidden",     
        scrollbarWidth: "thin", 
    },
     "& .btn" :{
        cursor : "pointer",
        color:"#FFFFFF",
        padding:"1% 10%",
        textTransform:"none",
        fontWeight : 900,
        fontSize : "14px",
        fontFamily : "Nexa-Heavy",
    },
    "& .disabled" :{
        background: "#B2B3B3",
        "&:hover" : {
            background : "#B2B3B3",        
        }
    },
    "& .active":{
        background: "#FF0807",
        "&:hover" : {
            background : "#FF0807",        
        }
    },
    "& .container": {
      height: "auto",
      backgroundColor: "#312F2D",
      width:"95%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItens: "center",
      margin: 0,
      borderRadius: "0px 0px 8px 8px",
    },
})   
// Customizable Area End
