// Customizable Area Start
import React from "react";
import VideoPlayerComponentController , {Props} from "./VideoPlayerComponentController";

export default class VideoPlayerComponent extends VideoPlayerComponentController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div>
                <div style={styles.podcastWrapper} >
                    <img width="76px" height="74px" alt="Cover" />
                    <div style={styles.podcastDetails}>
                        <div style={{ fontSize: "16px", color: "#F8FAFC", fontFamily: "Nexa-Heavy", fontWeight: 900 }}>Vageta is the one #89</div>
                        <div style={{ fontSize: "16px", color: "#F8FAFC", fontFamily: "Nexa-Heavy", fontWeight: 100, width: "117px", height: "auto" }}>Sultan Mirza</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {    
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '106px',
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flexGrow: 1,
    },
    playerWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "8px",
        width: "95%",
        gap: "24px"
    },
    podcastWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 15
    },
    podcastDetails: {
        display: "flex",
        flexDirection: "column" as const,
        color: "white"
    },
    playerIcons: {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center" as const,
        alignItems: "center" as const,
    },
    select: {
        backgroundColor: 'black',
        color: 'white',
        border: "none",
        fontWeight: 900,
        borderRadius: 8,
        fontSize: "16px",
        padding: "8px 0"
    },
    time: {
        color: "#F8FAFC",
        fontWeight: 700
    },
    count: {
        fontWeight: 900,
        fontSize: 14,
        color: "#fff",
    }
};
// Customizable Area End
