import React from "react";


// Customizable Area Start
import AdManagerController, { Props }  from "./AdManagerController.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { monetizationBackImg, walletImg, PartnerPortalImg, inventoryImg } from "./assets";
import {Box, Button, Typography,Card, CardActions, CardMedia, styled} from "@mui/material"

// Customizable Area End

export default class AdManager extends AdManagerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  isDisable = false;
  traverseAds = (idCount: string, link: string) => {
    this.clickCount(idCount);
    setTimeout(() => {
      window.location.href = link;
    }, 1000);
  };
  monetizationCard(imageUrl: string, buttonLabel: string, buttonClick: () => void){
    return (
      <Card data-test-id = "card" sx={{ width: 300, borderRadius: "16px", backgroundColor: "#312F2D", height: "320px" }}>
      <CardMedia
        data-test-id="card-media"  
        sx={{ height: "230px", objectFit: "contain",filter: this.isDisable ? "grayscale(100%)" : "none",}}
        image={imageUrl}
      />
      <CardActions sx={{ backgroundColor: "#312F2D",  justifyContent: "center", padding: "30px" }}>
        <Button
        onClick={buttonClick}
         data-test-id="card-button"
         className={`btn ${this.isDisable ? 'disable' : ''}`}
         size="small"
         disabled= {this.isDisable}
        >
          {buttonLabel}
        </Button>
      </CardActions>
    </Card>

    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box style={{ overflowY: "auto", maxHeight: "100%" }} ref={this.scrollRef}>
        <StyledWrapper>

        <img src={monetizationBackImg} alt="backgroundImg" width={"100%"} style={{objectFit:"contain", borderRadius:"8px"}} />

        <Box bgcolor={"#44403C"} pb={"3rem"}>
        <Typography data-test-id="title" fontFamily={"Nexa-Heavy"} padding={"32px 0"} fontSize={"30px"} fontWeight={900} lineHeight={"40px"} textAlign={"center"} color={"white"}>Monetize</Typography> 

        <Box mb={4} display={"flex"} gap={3} alignItems={"center"} justifyContent={"center"}>
          {this.monetizationCard(walletImg,"Wallet",this.handleWallet)}
          {this.monetizationCard(PartnerPortalImg,"Partner Portal",this.handlePartnerPortal)}
          {this.monetizationCard(inventoryImg,"Inventory Management",this.handlePartnerPortal)}
        </Box>
         <Box textAlign="center" mt={0} mb={3}>
            <Button variant="contained" onClick={()=>this.props.handleCommonPath("HostMonetizationSetup")} className="monetizationBtn" data-test-id="monetizationBtn">{this.isDisable ? "Finish Setting Up Monetization":"Re-Set Up Monetization"}</Button>
        </Box>
      </Box>
      </StyledWrapper>
        <Footer navigation={this.props.navigation} id={"1"} />    
          </Box>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled("div")({
  padding: "20px 50px",
  background: "black",
  "& *": {
      boxSizing: "border-box",
  },
  "& .monetizationBtn" :{
    padding:"1% 10%",
    textTransform:"none",
    fontFamily : "Nexa-Heavy",
    cursor : "pointer",
    color:"#FFFFFF",
    fontWeight : 900,
    fontSize : "14px",   
    background:"#FF0807"  
},
"& .btn" : {
    backgroundColor: "red",
    color: "white",
    width: "268px",
    padding: "8px",
    textTransform:"capitalize",
    fontSize:"15px",
    fontFamily: "Nexa-Heavy",
    fontWeight:900,
    borderRadius:"8px",
    "&:hover": {
      backgroundColor: "red", 
      color: "white" 
    }
},
"& .disable":{
  background:"#B2B3B3",
}
})
// Customizable Area End
