import React from "react";
// Customizable Area Start
import { styled, Box, Typography, Button, IconButton, Card, CardContent, CardMedia, Link, Grid, Dialog, DialogContent, Divider, DialogActions, Avatar, Menu, MenuItem, ListItemIcon, ListItemText, TextField, Modal, Checkbox } from "@mui/material";
import { Star, StarBorder, Phone, InfoOutlined, AttachMoney, Laptop, ShoppingCartOutlined, LocalOfferOutlined, MenuBook, ThumbUpOffAlt, ThumbUp, ChatBubbleOutline, Share, FavoriteBorder, PlaylistAdd, PlayCircleFilled, CloseOutlined, Close, FileUploadOutlined, PushPinOutlined, MoreHoriz, NotificationsNoneOutlined, VisibilityOff, LocationOn } from '@mui/icons-material';
import { podcastImage, tshirtIcon, recommended_episode_1, recommended_episode_2, recommended_episode_3, ad_1, ad_2, goBack } from "./assets";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import HostCatalogueController, { Props, configJSON } from "./HostCatalogueController";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
import CloseIcon from '@mui/icons-material/Close';
import Rating from '@mui/material/Rating';
import moment from "moment";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PostCommentObject } from "./CatalogueController";
import { leftArrow } from "../../../blocks/dashboard/src/assets";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class HostCatalogue extends HostCatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCommentInput = (postType: string, comment: string, padding: number) => {
    return (
      <PodcastCard style={{ paddingLeft: `${padding}px`, borderRadius: `${padding ? "0px 0px 0px 0px" : "20px 20px 0px 0px"}` ,background:`${this.getSecondaryColor()}`}}>
        <Box display="flex" flexDirection="column" flex={1}>
          <PodcastContentBox>
            <Box sx={{ display: 'flex' }}>
              <ProfileImages src={this.state.userProfileDataInHost?.attributes?.profile_picture} alt="Avatar" />
              <Box sx={{ flex: 1, position: 'relative' }}>
                <TextFieldWrapper>
                  <StyledText
                    placeholder="Leave a comment"
                    value={this.state.textareaValue}
                    onChange={this.handleTextareaChange}
                    variant="outlined"
                    rows={4}
                    fullWidth
                    multiline
                    InputProps={{ readOnly: !!this.state.selectedFile, }}
                  />
                </TextFieldWrapper>
                <ReplyButton data-test-id="LeaveComment" onClick={()=>this.handleCommentOnPost(postType, comment)}>
                  <StyledIcon>
                    <SendRoundedIcon style={{ width:"13.5px", height:"12.6px", fill:`${this.getSecondaryColor()}`}} />
                  </StyledIcon>
                </ReplyButton>
              </Box>
            </Box>
          </PodcastContentBox>
          {this.renderMentionsPopup()}
        </Box>
      </PodcastCard>
    )
  }

  renderComments = (comments: Array<PostCommentObject>, paddingLeft: number, commentType: string) => {
    return (
      <>
        {comments.map((comment: PostCommentObject, index: number) => {
          return (
            <Box key={index} sx={{background: `${this.getSecondaryColor()}`,}}>
              <PodcastCard
                style={{
                  backgroundColor: `${this.getSecondaryColor()}`
                }}
              >
                <PodcastInnerBox>
                  <PodcastInnerBoxContent
                    style={{
                      padding: `22px 33px 22px ${paddingLeft}px`,
                    }}
                  >
                    <Box sx={{ display: 'flex' }} data-test-id="comment-box">
                      <ProfileImages src={comment.attributes.account_details.profile} alt="Avatar" />
                      <Box>
                        <CommunityList>
                          <Typography
                            component="div"
                            variant="h6"
                            style={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 900 }}
                          >
                            {`${comment.attributes.account_details.first_name} ${comment.attributes.account_details.last_name}`}
                          </Typography>
                        </CommunityList>
  
                        <Typography
                          variant="body2"
                          style={{ fontWeight: 100, fontSize: '10px', color: '#FFFFFF' }}
                        >
                          {moment(comment.attributes.created_at).format('DD/MM/YYYY')}
                        </Typography>
  
                        <Typography
                          variant="body2"
                          style={{ fontWeight: 100, fontSize: '14px', color: '#FFFFFF', marginTop: '10px' }}
                        >
                          {this.renderTextBold(comment.attributes.comment)}
                        </Typography>
  
                        <CommunityListButtons>
                          <IconButton sx={{ color: '#FFFFFF' }} onClick={()=>this.handleLike(comment.id,comment.attributes.likes.liked,comment.attributes.likes.liked_id)}>
                            <ThumbUpOffAlt />
                          </IconButton>
                          <Typography sx={{ color: '#FFFFFF', marginRight: '15px', fontSize: '14px' }}>
                            {comment.attributes.likes.count}
                          </Typography>
                          <IconButton sx={{ color: '#FFFFFF', cursor: "pointer" }}>
                            <ChatBubbleOutline data-test-id="nestedComment" onClick={() => this.handleNestedComments(comment.id, comment, commentType)} />
                            <Typography sx={{ color: '#FFFFFF', marginLeft: '10px', fontSize: '14px' }}>
                              {comment.attributes.reply_count}
                            </Typography>
                          </IconButton>
                        </CommunityListButtons>
                      </Box>
                    </Box>
                  </PodcastInnerBoxContent>
                </PodcastInnerBox>
              </PodcastCard>
              {
                this.state.nestedCommentId == comment.id && <Box>
                  {this.renderCommentInput("comment", comment.id, paddingLeft)}
                  {this.renderComments(this.state.nestedCommentsData, paddingLeft + 62, "nestedComment")}
                </Box>
              }
            </Box>
          );
        })}
      </>
    );
  };

  renderCommunityComment = () => {
    return (
      <Box>
        {this.renderSinglePost(this.state.singleHostCommunityPostData)}
          <TextCommentHead>Comments</TextCommentHead>
        {this.state.nestedCommentId == "" && this.renderCommentInput("post",this.state.singleHostCommunityPostData.id,0)}
        {this.renderComments(this.state.postCommentsData, 43, "mainComment")}
      </Box>
    )
  }

  renderEpisodeCommunityPost = () => {
    return (
      <Box>
          <TextCommentHead>Comments</TextCommentHead>
        {this.state.nestedCommentId == "" && this.renderCommentInput("episode",this.state.parentId,0)}
        {this.renderComments(this.state.postCommentsData, 43, "nestedComment")}
      </Box>
    )
  }

  renderSinglePost = (post: any) => {
    return (
      <Box
        key={post.id}
        sx={{
          width: "100%",
          padding: "33px 41px 18px 43px",
          borderRadius: "20px",
          marginTop: this.setMargin2(),
          marginBottom: this.setMargin3(),
          border: this.renderPinnedBorder(post),
          background: `${this.getSecondaryColor()}`,
        }}
      >
        <Box sx={{ display: "flex", marginBottom: this.setMargin1(post) }}>
          <Avatar
            src={post.avatar}
            sx={{ marginRight: "40px", width: "56px", height: "56px" }}
          />
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Box>
              <Typography variant="subtitle1" sx={{ ...webStyles.cardIcon, color: `${this.getTextColor()}` }}>
                {post.hostName}
              </Typography>
              <Typography
                variant="caption"
                style={{ fontSize: "10px", fontFamily: "Nexa-Regular", color: `${this.getTextColor()}` }}
              >
                {moment(post.date).format('DD/MM/YYYY')}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  lineHeight: "26px",
                  fontFamily: "Nexa-Regular",
                  color: `${this.getTextColor()}`
                }}
              >
                {this.renderTextBold(post.body)}
              </Typography>
            </Box>
            <Box display={"flex"} alignItems={"flex-start"}>
              {this.renderPinnedIcon(post)}
              <IconButton
                style={{ paddingTop: "0px", color: `${this.getTextColor()}` }}
                data-test-id="OpenMenuForHostChannel"
                onClick={(e) => this.handleMenuOpen(e, post.id, post.email)}
              >
                <MoreHoriz sx={{ fontSize: "36px" }} />
              </IconButton>
              {
                this.state.openDropdownForMyOrOthersPost === "My Post" ? (
                  <Menu
                    onClose={this.handleMenuClose}
                    anchorEl={this.state.anchorEl}
                    open={this.openMenu(post)}
                    sx={{
                      "& .MuiPaper-root": {
                        width: "233px",
                        color: "#FFFFFF",
                        backgroundColor: "#44403C",
                        border: "1px solid #ffffff",
                      },
                      "& .MuiList-root": {
                        padding: "0px"
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => this.handlePinPost(post.id)}
                      data-test-id="pinPostTestBtn"
                      sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", }, }}
                    >
                      <ListItemIcon style={{ transform: "rotate(40deg)" }}>
                        <PushPinOutlined style={{ color: "#FFFFFF", fontSize: "36px" }} />
                      </ListItemIcon>
                      {
                        this.renderPinAndUnpinPost(post)
                      }
                    </MenuItem>
                    <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
                    <MenuItem
                      sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", }, whiteSpace: "normal", overflowWrap: "break-word" }}
                      onClick={this.handleMenuClose}
                    >
                      <ListItemIcon>
                        <AttachMoneyOutlinedIcon style={{ color: "#FFFFFF", fontSize: "36px" }} />
                      </ListItemIcon>
                      <ListItemText style={{ marginLeft: "8px", fontSize: "14px", fontWeight: 400, fontFamily: "Nexa-Regular", }}> Tie to a Sponsored Post Offer </ListItemText>
                    </MenuItem>
                    <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
                    {
                      this.renderSaveUnsaveText(post)
                    }
                    <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
                    <MenuItem
                      sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                      data-test-id="notificationTurnOffAndOnOfMyPostTestBtn"
                      onClick={() => this.handleTurnOffNotificationOfMyPost(post.id)}
                    >
                      <ListItemIcon>
                        <NotificationsNoneOutlined sx={{ color: "#FFFFFF", fontSize: "36px" }} />
                      </ListItemIcon>
                      {
                        this.renderNotificationText(post)
                      }
                    </MenuItem>
                    <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
                    <MenuItem
                      sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", }, }}
                      onClick={this.handleMenuClose}
                    >
                      <ListItemIcon>
                        <BarChartRoundedIcon style={{ color: "#FFFFFF", fontSize: "36px" }} />
                      </ListItemIcon>
                      <ListItemText style={{ marginLeft: "8px", fontSize: "14px", fontWeight: 400, fontFamily: "Nexa-Regular", }}> Analytics </ListItemText>
                    </MenuItem>
                    <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
                    <MenuItem
                      data-test-id="deletePostTestBtn"
                      sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", }, }}
                      onClick={() => this.handleDeletePost(post.id)}
                    >
                      <ListItemIcon>
                        <DeleteIcon style={{ color: "#FFFFFF", fontSize: "36px" }} />
                      </ListItemIcon>
                      <ListItemText style={{ marginLeft: "8px", fontSize: "14px", fontWeight: 400, fontFamily: "Nexa-Regular", }}> Delete </ListItemText>
                    </MenuItem>
                    <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
                    <MenuItem
                      data-test-id="hidePostTestBtn"
                      onClick={() => this.handleHidePost(post.id)}
                      sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                    >
                      <ListItemIcon>
                        <VisibilityOff style={{ color: "#FFFFFF", fontSize: "36px" }} />
                      </ListItemIcon>
                      <ListItemText style={{ marginLeft: "8px", fontSize: "14px", fontWeight: 400, fontFamily: "Nexa-Regular", }}> Hide </ListItemText>
                    </MenuItem>
                    <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
                    <MenuItem
                      sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", }, }}
                      onClick={() => this.handleBlockUser(post.accountId)}
                      data-test-id="blockUserPostTestBtn"
                    >
                      <ListItemIcon>
                        <BlockIcon style={{ color: "#FFFFFF", fontSize: "36px" }} />
                      </ListItemIcon>
                      <ListItemText style={{ marginLeft: "8px", fontSize: "14px", fontWeight: 400, fontFamily: "Nexa-Regular", }}> Block User </ListItemText>
                    </MenuItem>
                  </Menu>
                ) : (
                  <Menu
                    data-test-id="closeMenuForOthersPostTestBtn"
                    anchorEl={this.state.openDropDownForOtherPost}
                    open={this.openMenuForOthersPost(post)}
                    onClose={this.handleMenuCloseForOtherUserInHostChannel}
                    sx={{
                      "& .MuiPaper-root": {
                        backgroundColor: "#44403C",
                        color: "#FFFFFF", width: "233px",
                        border: "1px solid #FFFFFF",
                      },
                      "& .MuiList-root": {
                        padding: "0px"
                      },
                    }}
                  >
                    {
                      this.renderSaveUnsaveText(post)
                    }
                    <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
                    <MenuItem
                      data-test-id="notificationTurnOffAndOnOfOthersPostTestBtn"
                      onClick={() => this.handleTurnOffNotificationOfMyPost(post.id)}
                      sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                    >
                      <ListItemIcon>
                        <NotificationsNoneOutlined sx={{ fontSize: "36px", color: "#FFFFFF" }} />
                      </ListItemIcon>
                      {
                        this.renderNotificationText(post)
                      }
                    </MenuItem>
                    <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
                    <MenuItem
                      data-test-id="hidePostTestBtn"
                      onClick={() => this.handleHidePost(post.id)}
                      sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                    >
                      <ListItemIcon>
                        <VisibilityOff sx={{ color: "#FFFFFF", fontSize: "36px" }} />
                      </ListItemIcon>
                      <ListItemText style={{ fontWeight: 400, fontFamily: "Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.hideText}</ListItemText>
                    </MenuItem>
                    <Divider sx={{ borderColor: "#ffffff", }} style={{ margin: "0px" }} />
                    <MenuItem
                      sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", }, }}
                      onClick={() => this.handleBlockUser(post.accountId)}
                      data-test-id="blockOtherUserPostTestBtn"
                    >
                      <ListItemIcon>
                        <BlockIcon sx={{ fontSize: "36px", color: "#FFFFFF", }} />
                      </ListItemIcon>
                      <ListItemText style={{ fontWeight: 400, fontFamily: "Nexa-Regular", marginLeft: "8px", fontSize: "14px", }}>{configJSON.blockUserText}</ListItemText>
                    </MenuItem>
                  </Menu>
                )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              padding: this.setPadding1(post),
              display: this.renderBlockOrFlex(post),
              justifyContent:"center"
            }}
          >
            <img
              src={post.image}
              style={{
                maxWidth: "734px", width:"100%", height:"343px", borderRadius: "43px", cursor: "pointer",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "22px",
              color: "#fff", justifyContent: "flex-start",
              marginLeft: "95px", paddingTop: this.setPadding2(post)
            }}
          >
            <Typography variant="caption" sx={{ ...webStyles.cardIcon, color: `${this.getTextColor()}` }}>
              {moment(post.updatedAt).fromNow()}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "11px",
              }}
            >
              {this.checkIfPostLiked(post)}
              <Typography variant="caption" sx={{ ...webStyles.cardIcon, color: `${this.getTextColor()}` }}>
                {post.likes}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "11px",
                cursor: "pointer"
              }}
              data-test-id="getSingleCommunityBtn"
              onClick={() => this.handleGetSingleCommunityPost(post.id)}
            >
              <ChatBubbleOutline fontSize="medium" sx={{ color: `${this.getTextColor()}` }} />
              <Typography variant="caption" sx={{ ...webStyles.cardIcon, color: `${this.getTextColor()}` }}>
                {post.comments}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "11px",
              }}
            >
              <IconButton
                aria-label="sharePost" data-test-id="shareHostPostDialogTestBtn"
                onClick={() => this.handleOpenShareHostPostModal(post.id)}
              >
                <Share sx={{ color: `${this.getTextColor()}`, height: "24px", width: "24px" }} />
              </IconButton>
              <Modal
                onClose={this.handleCloseShareHostPostModal}
                aria-describedby="modal-modal-description"
                aria-labelledby="modal-modal-title"
                open={this.openShareHostPost(post)}
                style={{ background: "rgba(0,0,0,0.5)", backdropFilter: 'blur(5px)', }}
              >
                <Box sx={SharePostModalstyle}>
                  <Box className="hostModalBox">
                    <IconButton data-test-id="closeShareHostPostDialog" onClick={this.handleCloseShareHostPostModal}>
                      <CloseIcon sx={{ color: "#FFF", position: "absolute", left: "920px" }} />
                    </IconButton>
                    <ShareChannelModal>
                      <ShareIcon aria-label="share">
                        <Share />
                      </ShareIcon>
                      <ShareChannelText mb={6} mt={4}>{"Share Post"}</ShareChannelText>
                    </ShareChannelModal>
                    <ShareChannelText gutterBottom>{"Post URL"}</ShareChannelText>
                    <UrlBox>
                      <ChannelTextFeild id="hostPostUrl">{post.postUrl}</ChannelTextFeild>
                      <CopyButton data-test-id="copyHostPostUrlTestBtn" onClick={() => this.handleCopyHostPostUrl()} ><ContentCopy />{configJSON.copyText}</CopyButton>
                    </UrlBox>
                  </Box>
                </Box>
              </Modal>
              <Typography variant="caption" sx={{ ...webStyles.cardIcon, color: `${this.getTextColor()}` }}>
                {post.shares}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  renderShareEpisodeInHost(episode: any){ 
    return(
      <Dialog
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: 'blur(5px)',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        }}
        sx={{
          "& .MuiDialog-paper": {
            background: "#44403C",
            maxWidth: "1149px",
            width: "1149px",
            boxSizing:"border-box",
            height:"466px",
            borderRadius: '8px 8px 32px 8px',
          },
        }}
        data-test-id="closeHostEpisodeDialogTestBtn"
        open={this.openShareHostEpisode(episode.id)}
        onClose={() => this.handleCloseShareHostEpisodeDialog()}
      >
        <IconButton sx={{ alignSelf:"flex-end", top:"15px", position:"relative", right:"15px" }} onClick={this.handleCloseShareHostEpisodeDialog}>
          <CloseIcon sx={{ color:"#FFFFFF" }} />
        </IconButton>
        <Box style={{
          display:"flex", alignItems:"center", gap:"8px",
          paddingTop:"20px", paddingLeft:"40px"
        }}>
          <Share sx={{ color: "#FFFFFF" }} />
          <Typography style={{
            fontWeight:900, fontSize:"24px", fontFamily:"Nexa-Heavy", color:"#FFFFFF"
          }}> 
            Share Episode
          </Typography>
        </Box>
        <Box style={{
          display:"flex", gap:"20px", flexDirection:"column", padding:"50px 30px 0px 30px"
        }}>
          <Typography style={{
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"14px", color:"#FFFFFF"
          }}>
            Episode URL
          </Typography>
          <UrlBox style={{ gap:"10px" }}>
            <EpisodeTextFeild id="hostEpisodeUrl">{episode.attributes.episode_url}</EpisodeTextFeild>
            <CopyButtonEpisode onClick={() => this.handleCopyHostEpisodeUrl()} data-test-id="copyHostEpisodeUrlTestBtn"><ContentCopy />{configJSON.copyText}</CopyButtonEpisode>
            <Box style={{ display:"flex", alignItems:"center", gap:"12px", marginLeft:"18px" }}>
              <Checkbox
                sx={{ 
                  width:"33px", height:"32px", background:"#44403C",
                  color: "#FFFFFF", '&.Mui-checked': { color: "#FFFFFF" },
                  '& .MuiSvgIcon-root': { width:"33px", height:"32px" },
                }}
              />
              <Typography style={{ fontWeight:900, fontSize:"14px", fontFamily:"Nexa-Heavy", color:"#FFFFFF" }}>
                Start At
              </Typography>
              <EpisodeStartAtTextFeild>{0}</EpisodeStartAtTextFeild>
            </Box>
          </UrlBox>
          <Typography style={{
            fontFamily:"Nexa-Heavy", fontSize:"14px", fontWeight:900, color:"#FFFFFF"
          }}>
            Embed
          </Typography>
          <LinkContainer style={{ gap:"10px" }}>
            <EpisodeEmbededTextFeild id="embedHostEpisodeUrl">{episode.attributes.embed_code}</EpisodeEmbededTextFeild>
            <CopyButtonEpisode onClick={() => this.handleCopyHostEmbedEpisodeUrl()} data-test-id="copyHostEpisodeEmbedUrlTestBtn"><ContentCopy />{configJSON.copyText}</CopyButtonEpisode>
          </LinkContainer>
        </Box>
      </Dialog>
    )
  }

  renderTabs() {
    const firstTabName = (this.state.templateType === "Audio + Community Focused" || this.state.templateType === "Video + Community Focused") ? "Episodes & Community" : "Episodes";
    return (
      !this.state.openReviewsScreen ? (
      <Box className="left-body-section-tabs">
        <Box sx={{ display: 'flex', gap: '22px' }}>
          <Button style={{backgroundColor:this.getSecondaryColor(), borderColor:this.getSecondaryColor(), color:this.getTextColor()}} variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges(firstTabName)} sx={{ borderRadius: this.setBorderRadius1(), height: this.checkHeight1() }}>{firstTabName}</Button>
          {(this.state.templateType === "Audio Focused" || this.state.templateType === "Video Focused") && 
            <Button style={{backgroundColor:this.getSecondaryColor(), borderColor:this.getSecondaryColor(), color:this.getTextColor()}} variant="outlined" data-test-id="tab-button" className="section-tabs" onClick={() => this.handleTabChanges('Community')} sx={{ borderRadius: this.setBorderRadius2(), height: this.checkHeight2() }}>Community</Button>
          }
          <Button style={{backgroundColor:this.getSecondaryColor(), borderColor:this.getSecondaryColor(), color:this.getTextColor()}} variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('ChannelInfo')} sx={{ borderRadius: this.setBorderRadius3(), height: this.checkHeight3() }}>Channel Info</Button>
        </Box>
      </Box>
      ) : (
        <Box id="left-body-section-rating-reviews" style={{ marginTop:"25px", height:"40px", display:"flex", gap:"20px", alignItems:"center" }}>
          <Box data-test-id="goBackFromRatingTestBtn" onClick={() => this.goBackfromReviewsScreen()} style={{ cursor:"pointer", borderRadius:"8px", display:"flex", justifyContent:"center", alignItems:"center", height:"40px", width:"40px", background: this.getButtonColor() }}>
            <ArrowBackIosIcon style={{ position:"relative", left:"5px", color: this.getTextColor() }}/>
          </Box>
          <Typography style={{ 
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"30px",
            color: this.getTextColor()
          }}>
            Ratings And Reviews
          </Typography>
        </Box>
      )
    )
  }

  renderTextWithHighlight(text: string) {
    if(!this.state.searchMentionName) return text;
    const regexData = new RegExp(`(${this.state.searchMentionName})`, "gi");
    const partsData = text.split(regexData);

    return partsData.map((itemPart, index) =>
      itemPart.toLowerCase() === this.state.searchMentionName.toLowerCase() ? (
        <span
          key={index}
          style={{ color: "white", backgroundColor: "rgba(255, 206, 0, 0.2)" }}
        >
          {itemPart}
        </span>
      ) : (
        itemPart
      )
    );
  }

  renderTextBold(textData: string) {
    return textData?.split(/(\s+)/).map((word, index) =>
      word.startsWith("@") ? (
        <strong
        key={index}
          style={{ fontFamily: 'Nexa-Hevay', fontWeight:900, cursor: "pointer" }} 
        > {word.slice(1)}
        </strong>
      ) : (
        word
      )
    );
  }

  renderMentionsPopup() {
    return (
      <>
        {this.state.openMentionsSearchResultsPopup &&
          <Box
            id="popup-container"
            onClick={this.handleHostMentionBoxClick}
            sx={{
              height:"326px", gap:"2px", maxWidth: "414px", width:"100%",
              position: this.setPosition(), top: this.setPosition1(), left: this.setPosition2(),
              display:"flex", flexDirection:"column", 
              background: "#312F2D", zIndex:99,
            }}
          > 
            {this.state.searchMentionsdata.accounts?.data.map((item: any, index: number) => (
              <Box style={{
                width:"100%", height:"80px", background: this.backgroundColor1(index),
                justifyContent:"flex-start", borderBottom:"1px solid #F5F5F5", 
                display:"flex", alignItems:"center", gap:"25px", cursor:"pointer"
              }}
                data-test-id="mentionUserAccNameTestBtnBox"
                onClick={() => this.handleMentionsName(item.attributes.first_name + " " + item.attributes.last_name)}
              >
                <Avatar
                  sx={{ marginLeft:"16px", width:"41px", height:"46px"  }}
                  alt="User Avatar"
                  src={item.attributes.profile_picture}
                />
                <Box style={{ display:"flex", flexDirection:"column", gap:"2px" }}>
                  <Typography style={{
                    textOverflow:"ellipsis", whiteSpace:"nowrap",
                    fontFamily:"Nexa-Heavy", fontWeight:400, fontSize:"14px",
                    width:"250px", color:"#FFFFFF", overflow:"hidden",
                  }}>
                    {this.renderTextWithHighlight(item.attributes.first_name + " " + item.attributes.last_name)}
                  </Typography>
                  <Typography style={{
                    color:"#FFFFFF", width:"250px",
                    fontFamily:"Nexa-Regular", fontWeight:400, fontSize:"14px",
                    whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis",
                  }}>
                    {this.renderHostResident(item.attributes.roles)}
                  </Typography>
                </Box>
              </Box>
            ))}
            {this.state.searchMentionsdata.podcast?.data.map((item: any) => (
              <Box style={{
                display:"flex", height:"80px", background: "#44403C", 
                width:"100%", borderBottom:"1px solid #F5F5F5", cursor:"pointer",
                justifyContent:"flex-start", alignItems:"center", gap:"25px",
              }}
                data-test-id="mentionUserPodcastNameTestBtnBox"
                onClick={() => this.handleMentionsName(item.attributes.name)}
              >
                <img
                  src={item.attributes.cover_image}
                  style={{ width:"69px", height:"76px" }}
                  alt="User Avatar"
                />
                <Box style={{ display:"flex", flexDirection:"column", gap:"2px" }}>
                  <Typography style={{
                    whiteSpace:"nowrap", width:"250px", textOverflow:"ellipsis",
                    color:"#FFFFFF", overflow:"hidden", 
                    fontFamily:"Nexa-Heavy", fontWeight:400, fontSize:"14px",
                  }}>
                    {this.renderTextWithHighlight(item.attributes.name)}
                  </Typography>
                  <Typography style={{
                    whiteSpace:"nowrap", width:"250px", overflow:"hidden",
                    color:"#FFFFFF", textOverflow:"ellipsis",
                    fontFamily:"Nexa-Regular", fontWeight:400, fontSize:"14px",
                  }}>
                    {item.attributes.subtitle}
                  </Typography>
                </Box>
              </Box>
            ))}
            {this.state.searchMentionsdata.episodes?.data.map((item: any) => (
              <Box style={{
                justifyContent:"flex-start", width:"100%", height:"80px",
                background: "292524", alignItems:"center", gap:"25px",
                borderBottom:"1px solid #F5F5F5", display:"flex", cursor:"pointer"
              }}
                data-test-id="mentionUserEpisodeNameTestBtnBox"
                onClick={() => this.handleMentionsName(item.attributes.name)}
              >
                <img
                  style={{ width:"69px", height:"76px"  }}
                  alt="User Avatar"
                  src={item.attributes.cover}
                />
                <Box style={{ display:"flex", flexDirection:"column", gap:"2px" }}>
                  <Typography style={{
                    fontFamily:"Nexa-Heavy", fontWeight:400, fontSize:"14px",
                    textOverflow:"ellipsis", whiteSpace:"nowrap",
                    width:"250px", color:"#FFFFFF", overflow:"hidden",
                  }}>
                    {this.renderTextWithHighlight(item.attributes.name)}
                  </Typography>
                  <Typography style={{
                    fontSize:"14px", whiteSpace:"nowrap", width:"250px",
                    color:"#FFFFFF", overflow:"hidden", textOverflow:"ellipsis",
                    fontFamily:"Nexa-Regular", fontWeight:400,
                  }}>
                    {item.attributes.podcast_name}
                  </Typography>
                </Box>
              </Box>
            ))} 
          </Box>
        }
      </>
    )
  }

  renderHostCommunityPost = (getHostCommunityPost:any[]) => {
    return (
      <>
        <Box>
         {!this.state.isShowSinglePost && <Box
            sx={{
              display: 'flex',
              backgroundColor: this.getSecondaryColor(),
              borderRadius: '0px 20px 20px 20px',
              padding: '25px 25px 25px 25px',
              width: '100%',
            }}
          >
            <Avatar
              alt="User Avatar"
              src={this.state.userProfileDataInHost.attributes?.profile_picture}
              sx={{ 
                width: "56px",
                height: "56px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
            <Box sx={{ flex: 1, position: "relative" }}>
              <Box
                sx={{
                  backgroundColor: "#030303",
                  borderRadius: "0px 0px 7px 7px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
              <TextField
                fullWidth
                multiline
                placeholder="Create A Post"
                rows={4}
                value={this.state.textareaValue}
                onChange={this.handleTextareaChange}
                data-test-id="createPostTextArea"
                variant="standard"
                InputProps={{disableUnderline: true}}
                sx={{
                  height:"90px",
                  backgroundColor: "#030303",
                  color: "white",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "black",
                    padding: "15px",
                  },                  
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  input: {
                    fontFamily:"Nexa-Heavy", fontWeight:100, fontSize:"14px",
                    color:"#FFFFFF"
                  }
                }}
              />
              {this.renderMentionsPopup()}
              {this.state.selectedPostFile && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "black",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                >
                  <img
                    src={URL.createObjectURL(this.state.selectedPostFile)}
                    alt="Uploaded Preview"
                    style={{
                      width: "238px",
                      height: "170px",
                      borderRadius: "8px",
                    }}
                  />
                  <IconButton style={{position:"absolute", left:"215px", top:"120px"}} 
                    onClick={this.handleRemoveFile}
                    data-test-id="removeFileTestBtn"
                   >
                    <CancelRoundedIcon style={{ color: "#312F2D" }} />
                  </IconButton>
                </Box>
              )}
              </Box>
                <Box
                  sx={{
                    display: "flex", gap: "10px",
                    position: "absolute", bottom: "10px", right: "10px",
                  }}
                >
                  <input
                    type="file"
                    onChange={this.handleFileUpload}
                    id="file-upload"
                    style={{ display: "none" }}
                    data-test-id="uploadFileInputTestBtn"
                  />
                  <IconButton
                    data-test-id="uploadPhotoTestBtn"
                    sx={{
                      backgroundColor: `${this.getButtonColor()}`,
                      height:"27px", width:"27px",
                      ":hover": {
                        backgroundColor: `${this.getButtonColor()}`,
                      },
                      padding: "10px",
                    }}
                    onClick={() => document.getElementById("file-upload")?.click()}
                  >
                    <AttachFileIcon style={{ padding:"5px", fill:`${this.getSecondaryColor()}`,}} />
                  </IconButton>
                  <IconButton
                    data-test-id="handleSend"
                    sx={{
                      padding: "10px",
                      height:"27px", width:"27px",
                      ":disabled": {
                        backgroundColor: this.checkIfDisabled1(),
                      },
                      ":hover": {
                        backgroundColor: this.checkIfDisabled1(),
                      },
                      backgroundColor: this.checkIfDisabled1(),
                    }}
                    disabled={this.checkIfDisabled1() === "#DADADA"}
                    onClick={() => this.handleSend()}
                  >
                    <SendRoundedIcon style={{ width:"13.5px", height:"12.6px", fill:`${this.getSecondaryColor()}`}} />
                  </IconButton>
                </Box>
              </Box>
          </Box>}
          { this.state.selectedTab === "Episodes & Community" &&
            <Typography style={{
              fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"30px",
              marginTop:"30px", color:`${this.getTextColor()}`
            }}>
              Community
            </Typography>
          }
          <div>
            {!this.state.isShowSinglePost && getHostCommunityPost.map((post, index) => this.renderSinglePost(post))}
          </div>
          {this.state.isShowSinglePost && this.renderEpisodeCommunityPost()}
        </Box>
      </>
    )
  }

  checkIfPostLiked(item: any) {
    return (
      item.likedPost?.liked_id ? (
        <IconButton 
          aria-label="like" sx={{ color: 'white' }} data-test-id="likePost"
          onClick={() => this.handleUnlikePost(item.likedPost?.liked_id)}
        >             
          <ThumbUp sx={{ color: `${this.getTextColor()}`, height:"24px", width:"24px" }} />
        </IconButton>
      ) : (
        <IconButton 
          aria-label="like" sx={{ color: 'white',}} data-test-id="UnlikePost" 
          onClick={() => this.handleLikePost(item.id)}
        >
          <ThumbUpOffAlt sx={{ height:"24px", width:"24px", color: `${this.getTextColor()}`, }} />
        </IconButton>
      )
    )
  }

  renderPinAndUnpinPost(item: any) {
    return (
      item.pinned ? (
        <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>Unpin</ListItemText>
      ) : (
        <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>Pin</ListItemText>
      )
    )
  }

  renderPinnedIcon(item: any) {
    return (
      item.pinned && (
        <PushPinOutlined style={{ color: `${this.getTextColor()}`, marginTop:"5px", transform: "rotate(40deg)", width: "24px", height:"24px" }} />
      )
    )
  }

  renderNotificationText(item: any) {
    return (
      item.post_notification ? (
        <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.TurnOffNotification}</ListItemText>
      ) : (
        <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.TurnOnNotification}</ListItemText>
      )
    )
  }

  renderSaveUnsaveText(item: any) {
    return (
      item.savePost?.saved_id ? (
        <MenuItem
          sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", } }}
          onClick={() => this.handleUnSavePostDropDownInHost(item.savePost.saved_id)}
          data-test-id="unsavePostTestBtn"   
        >
          <ListItemIcon>
            <SaveOutlinedIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
          </ListItemIcon>
          <ListItemText style={{ fontSize: "14px", marginLeft: "8px", fontWeight:400, fontFamily:"Nexa-Regular" }}>{configJSON.dropDownUnsave}</ListItemText>
        </MenuItem>
      ) : (
        <MenuItem
          sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", } }}  
          onClick={() => this.handleSavePostDropDownInHost(item.id)}
          data-test-id="savePostTestBtn"    
        >
          <ListItemIcon>
            <SaveOutlinedIcon sx={{ fontSize: "36px", color: "#FFFFFF" }} />
          </ListItemIcon>
          <ListItemText style={{ fontWeight:400, marginLeft: "8px", fontFamily:"Nexa-Regular", fontSize: "14px", }}>{configJSON.dropDownSave}</ListItemText>
        </MenuItem>
      )
    )
  }

  renderRatingStar(index: number) {
    return (
      this.state.ratingStars >= index + 1 ? (
        <Star style={{ color: "#FFFFFF", height:"45px", width:"46px" }} />
      ) : (
        <StarBorder style={{ color: "#FFFFFF", height:"45px", width:"46px" }} />
      )
    )
  }

  renderSeeMoreLink(description: string, index: number) {
    return (
      description.length > 140 && !this.state.expandSeeMore[index] &&
      <Link 
        onClick={() => this.handleSeeMore(index)} 
        style={{ marginLeft:"6px", cursor:"pointer", color: `${this.getTextColor()}`, textDecorationColor: `${this.getTextColor()}` }}
      >
        See More
      </Link>
    )
  }

  renderPodcastForAudio() {
    return (
      this.state.hostEpisodesData?.data?.map((episode: any, index: number) => {
        const attributes = episode?.attributes
        return (<><Card className="podcast-card" id="podcast-card-1"
          style={{
            backgroundColor:this.getSecondaryColor()
          }} 
        >
          <CardMedia
            component="img"
            sx={{ width: 151 }}
            image={attributes.cover}
            className="podcast-media"
          />
          <Box className="podcast-inner-box" id="podcast-inner-box-1">
            <CardContent className="podcast-inner-box-content" id="podcast-inner-box-content-1">
              <Typography style={{color: this.getTextColor(), fontSize:"18px"}} className="podcast-inner" id="podcast-inner-1">
                {attributes.name}
              </Typography>
              <Typography style={{color: this.getTextColor(), fontSize:"12px"}} id="ep-10" className="podcast-inner">
                {this.state.hostCardInfoData.name}
              </Typography>
              <Typography sx={{ color: this.getTextColor(), marginTop: '10px', fontSize:"14px" }} className="podcast-inner" id="podcast-inner-3">
                Episode {attributes.episode_number}
              </Typography>
              <Typography sx={{ color: this.getTextColor(), marginTop: '10px', fontWeight:100, fontSize:"14px", fontFamily: 'Nexa-Regular' }}>
                {this.renderSeeMoreText(attributes.description , index)}
                {this.renderSeeMoreLink(attributes.description, index)}
              </Typography>
            </CardContent>
            <Box className="podcast-action-buttons" id="podcast-action-buttons-1">
              <IconButton aria-label="like" id="ep-10-like" sx={{ color: 'white' }}>
                <ThumbUpOffAlt sx={{color: this.getTextColor()}} />
              </IconButton>
              <Typography sx={{ marginRight: '15px', color: this.getTextColor() }} className="podcast-inner">{attributes.liked_episode.count}</Typography>
              <IconButton sx={{ color: 'white' }} aria-label="comment" onClick={()=>{this.showEpisodeComments(!this.state.EpisodeComments,episode.id)}}  >
                <ChatBubbleOutline sx={{color: this.getTextColor()}}   />
              </IconButton>
              <Typography sx={{ color: this.getTextColor(), marginRight: '15px', }} className="podcast-inner">{attributes.comments.data.length}</Typography>
              <IconButton onClick={() => this.handleOpenShareHostEpisodeDialog(episode.id)} data-test-id="shareHostEpisodeTextBtn" sx={{ color: 'white' }} aria-label="share">
                <Share sx={{color: this.getTextColor()}} />
              </IconButton>
              {this.renderShareEpisodeInHost(episode)}
              <Typography className="podcast-inner" sx={{ color: this.getTextColor() }}>{attributes.share_episode_count}</Typography>
            </Box>
          </Box>
          <Box className="podcast-saving-options" id="ep-10-buttons" data-test-id="podcast-saving-options-1">
            <Button  sx={{ background:this.getButtonColor(), borderColor:this.getButtonColor(), "&:hover": {background: this.getButtonColor()}, }} variant="outlined" startIcon={<FavoriteBorder style={{ marginLeft: "20%" }} />} className="podcast-save-button">
              <Typography style={{ fontWeight: 900, lineHeight: "26px", fontSize: "10px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Save For Later</Typography>
            </Button>
            <Button sx={{ background:this.getButtonColor(), borderColor:this.getButtonColor(), "&:hover": {background: this.getButtonColor()}, }} variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd style={{ marginLeft: "20%" }} />}>
              <Typography style={{ fontSize: "10px", fontWeight: 900, whiteSpace: "nowrap", lineHeight: "26px", fontFamily: "Nexa-Heavy" }}>Add to Playlist</Typography>
            </Button>
          </Box>
          <Box className="play-icon" id="ep-10-play-button-host">
            <PlayCircleFilled style={{ color: this.getButtonColor(), width:"28px", height:"28px" }} />
          </Box>
        </Card>
        {this.state.EpisodeComments && episode.id == this.state.parentId && this.renderEpisodeCommunityPost()}
       </>)
      })
    )
  }


  

  renderPodcastCardForVideo() {
    return (
      <> 
      {this.state.hostEpisodesData.data?.map((episode: any, key: number) => (
        <Card className="podcast-card" style={{ padding:"40px 40px 20px 40px", backgroundColor:this.getSecondaryColor()}}>
          <Avatar
            src= {episode.attributes?.cover}
            alt="Host Avatar"
            sx={{ width: "56px", height: "56px" }}
          />
          <Box className="podcast-inner-box" id="podcast-inner-box-1" style={{ gap:"8px" }}>
            <Box style={{display:"flex", justifyContent:"space-between"}}>
              <Box style={{display:"flex", gap:"5px", flexDirection:"column"}}>
                <Typography style={{color: this.getTextColor(), width:"fit-content", fontSize:"14px",}} className="podcast-inner" id="podcast-inner-1">
                  {episode.attributes.name}
                </Typography>
                <Typography style={{color: this.getTextColor(), width:"fit-content", fontSize:"10px", fontWeight:100, fontFamily: 'Nexa-Regular' }} id="ep-10">
                  {episode.attributes.publish_date.publish_date}
                </Typography>
                <Typography sx={{ color: this.getTextColor(), width:"fit-content", fontSize:"20px", }} className="podcast-inner" id="podcast-inner-3">
                  {`- EP.${episode.attributes.episode_number}: ${episode.attributes.podcast_name}`}
                </Typography>
                <Typography sx={{ color: this.getTextColor(), width:"fit-content", fontSize:"14px", fontWeight:100, fontFamily: 'Nexa-Regular' }}>
                  {episode.attributes.description}
                </Typography>
              </Box>
              <Box className="podcast-saving-options" id="ep-10-buttons" data-test-id="podcast-saving-options-1">
                <Button  sx={{ background:this.getButtonColor(), borderColor:this.getButtonColor(), "&:hover": {background: this.getButtonColor()}, }} variant="outlined" startIcon={<FavoriteBorder style={{ marginLeft: "20%" }} />} className="podcast-save-button">
                  <Typography style={{ fontWeight: 900, lineHeight: "26px", fontSize: "10px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Save For Later</Typography>
                </Button>
                <Button sx={{ background:this.getButtonColor(), borderColor:this.getButtonColor(), "&:hover": {background: this.getButtonColor()}, }} variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd style={{ marginLeft: "20%" }} />}>
                  <Typography style={{ fontSize: "10px", fontWeight: 900, whiteSpace: "nowrap", lineHeight: "26px", fontFamily: "Nexa-Heavy" }}>Add to Playlist</Typography>
                </Button>
              </Box>
            </Box>
            <Box style={{ display:"flex", justifyContent:"center" }}>
              <video 
                style={{ borderRadius:"14px", width:"100%" }}  
                disablePictureInPicture
                ref={this.videoRefs[key]}
                src={episode.attributes.episode_content} 
              />
            </Box>
            <Box style={{ display:"flex", alignItems:"center" }}>
              <IconButton aria-label="like" id="ep-10-like" sx={{ color: 'white' }}>
                <ThumbUpOffAlt sx={{color: this.getTextColor()}} />
              </IconButton>
              <Typography sx={{ marginRight: '15px', color: this.getTextColor() }} className="podcast-inner">{episode.attributes.liked_episode.count}</Typography>
              <IconButton sx={{ color: 'white' }} aria-label="comment">
                <ChatBubbleOutline sx={{color: this.getTextColor()}} />
              </IconButton>
              <Typography sx={{ color: this.getTextColor(), marginRight: '15px', }} className="podcast-inner">{episode.attributes.comments.data.length}</Typography>
              <IconButton onClick={() => this.handleOpenShareHostEpisodeDialog(episode.id)} data-test-id="shareHostEpisodeFromVideoTextBtn" sx={{ color: 'white' }} aria-label="share">
                <Share sx={{color: this.getTextColor()}} />
              </IconButton>
              {this.renderShareEpisodeInHost(episode)}
              <Typography className="podcast-inner" sx={{ color: this.getTextColor() }}>{episode.attributes.share_episode_count}</Typography>
              <IconButton data-test-id="videoPlayPauseHostEpisodeTextBtn"  style={{ marginLeft:"20px" }} onClick={() => this.handleVideoPlayPause(key)}>
                  {
                    this.state.activeVideo[key] ? (
                      <PauseCircleFilledIcon
                        style={{ fontSize: '40px', color: `${this.getButtonColor()}` }}
                      />
                    ) : (
                      <PlayCircleFilled
                        style={{ fontSize: '40px', color: `${this.getButtonColor()}` }}
                      />
                    )
                  }
                </IconButton>
            </Box>
          </Box>
        </Card>
        ))
      }
      </>
    )
  }

  renderPostCardForSocialAudio() {
    return(
      <>
        {this.renderHostCommunityPost(this.state.hostCommunityPostData)}
      </>
    )
  }

  renderPostCardForSocialVideo() {
    return(
      <React.Fragment>
        {this.renderHostCommunityPost(this.state.hostCommunityPostData)}
      </React.Fragment>
    )
  }

  renderAllReviewsScreen() {
    return (
      <div className="scrollableWrapper" id="scrollableWrapper" style={{ height: "65rem" }}>
        <Box className="left-body-section" sx={{ marginTop:"20px", scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '64rem' }}>
          <Box style={{ display:"flex", flexDirection:"column", gap:"20px", }}>
          {this.state.allReviewsData.current_user_reviews?.data.map((item: any, index: number) => (
              <Box style={{
                display:"flex", height:"auto", maxHeight:"271px", width:"100%", padding:"30px 20px 20px 40px",
                border: this.setBorder1(index),
                background: this.getSecondaryColor(), borderRadius: "20px", gap:"18px",
              }}>
                <Avatar
                  alt="Avatar"
                  style={{ height:"56px", width:"56px" }}
                  src={item.attributes.profile_picture}
                />
                <Box style={{
                  display:"flex", flexDirection:"column", gap:"20px",
                }}>
                  <Box style={{
                    display:"flex", gap:"6px", marginTop:"5px", flexDirection:"column", 
                  }}>
                    <Typography style={{
                      fontFamily:"Nexa-Heavy", fontSize:"14px",
                      color: this.getTextColor(), fontWeight:900,
                    }}>
                      {item.attributes.user_name}
                    </Typography>
                    <Typography style={{
                      fontFamily:"Nexa-Regular", fontSize:"10px",
                      color: this.getTextColor(), fontWeight:100,
                    }}>
                      {moment(item.attributes.created_at).format('DD/MM/YYYY')}
                    </Typography>
                    <Rating 
                      sx={{ 
                        color: this.getTextColor(),
                        '& .MuiSvgIcon-root': {
                          fontSize: '31px', 
                        },
                      }}
                      precision={0.5} readOnly
                      emptyIcon={<StarBorder sx={{ color: this.getTextColor() }} fontSize="inherit" />}
                      value={item.attributes.rating} 
                      max={5}
                    />
                  </Box>
                  <Box style={{
                    display:"flex", flexDirection:"column", gap:"18px",
                  }}>
                    <Typography style={{
                      fontFamily:"Nexa-Regular", fontWeight:100, 
                      color: this.getTextColor(), fontSize:"14px",
                    }}>
                      {item.attributes.description}
                    </Typography>
                    <Box style={{
                      display:"flex", alignItems:"center", height:"30px", gap:"6px",
                    }}>
                      {
                        item.attributes.like.liked ? (
                          <IconButton data-test-id="unLikeReviewTestBtn" onClick={() => this.handleUnLikeReview(item.attributes.like.liked_id)}>
                            <ThumbUp style={{ color: this.getTextColor() }}/>
                          </IconButton>
                        ) : (
                          <IconButton data-test-id="likeReviewTestBtn" onClick={() => this.handleLikeReview(item.id)}>
                            <ThumbUpOffAlt style={{ color: this.getTextColor() }}/>
                          </IconButton>
                        )
                      }
                      <Typography style={{
                        color: this.getTextColor(), fontWeight:900,
                        fontFamily:"Nexa-Heavy",  fontSize:"14px",
                      }}>
                        {item.attributes.like.count}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
            {this.state.allReviewsData.other_reviews?.data.map((item: any, index: number) => (
              <Box style={{
                display:"flex", height:"auto", maxHeight:"271px", width:"100%", padding:"30px 20px 20px 40px",
                background: this.getSecondaryColor(), borderRadius: "20px", gap:"18px",
              }}>
                <Avatar
                  style={{ height:"56px", width:"56px" }}
                  src={item.attributes.profile_picture}
                  alt="Avatar"
                />
                <Box style={{
                  display:"flex", flexDirection:"column", gap:"20px",
                }}>
                  <Box style={{
                    display:"flex", flexDirection:"column", gap:"6px", marginTop:"5px"
                  }}>
                    <Typography style={{
                      fontWeight:900, fontSize:"14px",
                      color: this.getTextColor(), fontFamily:"Nexa-Heavy",
                    }}>
                      {item.attributes.user_name}
                    </Typography>
                    <Typography style={{
                      fontWeight:100, fontSize:"10px",
                      color: this.getTextColor(), fontFamily:"Nexa-Regular",
                    }}>
                      {moment(item.attributes.created_at).format('DD/MM/YYYY')}
                    </Typography>
                    <Rating 
                      sx={{ 
                        color: this.getTextColor(),
                        '& .MuiSvgIcon-root': {
                          fontSize: '31px', 
                        },
                      }}
                      max={5} readOnly
                      emptyIcon={<StarBorder fontSize="inherit" sx={{ color: this.getTextColor() }} />}
                      value={item.attributes.rating} 
                      precision={0.5}
                    />
                  </Box>
                  <Box style={{
                    display:"flex", flexDirection:"column", gap:"18px",
                  }}>
                    <Typography style={{
                      fontFamily:"Nexa-Regular", fontSize:"14px",color: this.getTextColor(),
                      fontWeight:100,
                    }}>
                      {item.attributes.description}
                    </Typography>
                    <Box style={{
                      gap:"6px", alignItems:"center", height:"30px", display:"flex",
                    }}>
                      {
                        item.attributes.like.liked ? (
                          <IconButton onClick={() => this.handleUnLikeReview(item.attributes.like.liked_id)} data-test-id="unLikeReviewTestBtn">
                            <ThumbUp style={{ color: this.getTextColor() }}/>
                          </IconButton>
                        ) : (
                          <IconButton data-test-id="likeReviewTestBtn" onClick={() => this.handleLikeReview(item.id)}>
                            <ThumbUpOffAlt style={{ color: this.getTextColor() }}/>
                          </IconButton>
                        )
                      }
                      <Typography style={{
                        fontFamily:"Nexa-Heavy", color: this.getTextColor(), fontSize:"14px",
                        fontWeight:900,
                      }}>
                        {item.attributes.like.count}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
    )
  }

  renderHostChannelInfo = (hostChannelInfoData:any[]) => {
    const { name, subtitle, description, location, cover_image, hosts } = this.state.hostCardInfoData;
    
    return (
      <>
        <Box
          sx={{
            backgroundColor: this.getSecondaryColor(),
            color: "#fff",
            padding: "2rem",
            borderRadius: "8px",
            margin: "0 10px 0 0",
          }}
        >
          <Grid gap="30px" container spacing={2} alignItems="center">
            <Grid item>
              <Card
                sx={{
                  width: "150px", height: "125px",
                  overflow: "hidden",
                  borderRadius: "3px",
                }}
              >
                <img
                  src={cover_image}
                  alt="Podcast Cover"
                  style={{ width: "150px", height: "125px", objectFit: "cover" }}
                />
              </Card>
            </Grid>
            <Grid style={{ display:"grid", gap:"5px"}} item xs>
              <Typography sx={{ ...webStyles.mainCardHeader, color: this.getTextColor() }}>
                {name}
              </Typography>
              <Typography sx={{ fontFamily: "Nexa-Heavy", fontSize: "30px", lineHeight:"30px", textTransform: "uppercase", fontWeight: 900, color: this.getTextColor() }}>
              {subtitle}
              </Typography>
              <Box display="flex" alignItems="center" mt={1}>
                <LocationOn sx={{color: this.getTextColor()}} />
                <Typography ml={1} sx={{ fontFamily: "Nexa-Heavy", fontSize: "18px", fontWeight: 900, color: this.getTextColor() }}>
                  {location}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box mt={4}>
            <Typography gutterBottom sx={{ ...webStyles.descriptionTypo, color: this.getTextColor() }}>
              About
            </Typography>
            <Typography paragraph sx={{ fontFamily: "Nexa-Regular", fontSize: "14px", fontWeight: 100, color: this.getTextColor() }}>
              {description}
            </Typography>
          </Box>
          <Box style={{display:"flex", flexDirection:"column", gap:"25px", marginTop:"30px"}}>
            {hosts?.map((item: any) => (
              <Box style={{display:"flex", flexDirection:"column", gap:"8px"}}>
                <Typography sx={{ width:"fit-content", position:"relative", left:"85px", ...webStyles.descriptionTypo, color: this.getTextColor() }}>
                  Main Host:
                </Typography>
                <Box style={{display:"flex",gap:"20px",}}>
                  <Avatar
                    src={item.profile_picture}
                    alt="Host Avatar"
                    sx={{ width: "67px", height: "67px", }}
                  />
                  <Box style={{display:"flex",gap:"8px", flexDirection:"column", alignItems:"flex-start"}}>
                    <Typography sx={{ ...webStyles.descriptionTypo, color: this.getTextColor() }}>
                      {item.first_name + " " + item.last_name}
                    </Typography>
                    <Typography sx={{ fontFamily: "Nexa-Regular", color: this.getTextColor(), fontSize: "14px", fontWeight: 100 }}>
                      {item.bio}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Typography sx={{ fontFamily: "Nexa-Heavy", fontSize: "30px", fontWeight: 900, color: `${this.getTextColor()}`, marginTop: "45px", marginBottom: "20px" }}>Other shows from this Host</Typography>

        <Box
          sx={{
            backgroundColor: this.getSecondaryColor(),
            color: "#fff",
            padding: this.setPadding3(),
            borderRadius: "8px",
            margin: "0 10px 0 0",
            display: "flex",
            gap: "35px",
            justifyContent: "center",
            flexWrap: "wrap"
          }}
        >
          {hostChannelInfoData?.length > 0 && hostChannelInfoData.map((card) => (
            <Card
              key={card.id}
              sx={{
                maxWidth: "250px",
                width: "100%",
                height: "300px",
                backgroundColor: "#000",
                borderRadius:"0px 0px 16px 16px",
                position: "relative",
              }}
            >
              <Box
                position={"absolute"}
                top={15}
                zIndex={9}
                right={15}
              >
                {
                  card.attributes?.follower.following ? (
                    <Button sx={{ 
                      width:"100%", color: this.getTextColor(), background: "#000000",
                      ...webStyles.followBtn, '&:hover': { background: "#000000"}
                    }}
                      onClick={() => this.handleUnfollowPodcast(card.attributes.follower.follow_id)}
                      data-test-id="unFollowOtherShowsTestBtn"
                    >
                      Followed
                    </Button>
                  ) : (
                    <Button sx={{ 
                      width:"100%", color: this.getTextColor(), background:this.getButtonColor(),
                      ...webStyles.followBtn, '&:hover': { background:this.getButtonColor() }
                    }}
                      onClick={() => this.handleFollowPodcast(card.id)}
                      data-test-id="followOtherShowsTestBtn"
                    >
                      Follow
                    </Button>
                  ) 
                }
              </Box>
              <Box style={{ width:"100%", height:"100%", display:"flex", flexDirection:"column", gap:"15px" }}>
                <CardMedia
                  component="img"
                  style={{ width:"100%", maxWidth:"250px", height:"223px" }}
                  image={card.attributes?.cover_image}
                  alt={card.subtitle}
                />
                <CardContent style={{ display:"flex", flexDirection:"column", gap:"6px", padding:"0px 16px" }}>
                  <Typography
                    sx={{ 
                      color: `${this.getTextColor()}`, whiteSpace:"nowrap", overflow:"hidden",
                      ...webStyles.headerTypography, textOverflow:"ellipsis"
                    }}
                  >
                    {card.attributes?.subtitle}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Nexa-Heavy",
                      fontSize: "14px",
                      fontWeight: 900, whiteSpace:"nowrap", overflow:"hidden",
                      color: `${this.getTextColor()}`, textOverflow:"ellipsis",
                      textTransform: "capitalize",
                    }}
                  >
                    {card.attributes?.name}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          ))}
        </Box>
      </>
    )
  }

  BackButton = () => {
    return <>
      <BackButtonFrame >
        <BackIcon
          data-test-id="backButton"
          src={leftArrow}
          style={{
            width: "100%",
            height: "100%"
          }}
          alt="Arrow"
          onClick={() => { this.showEpisodeComments(false, '') }}
        />
        <TextBackButton>Podcast Episode</TextBackButton>
      </BackButtonFrame>
    </>
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        <div style={{...webStyles.container1, backgroundColor: this.getBackgroundColor()}} className="container1" ref={this.scrollRef}>
          <StyledWrapperSetting
            style={{ backgroundColor: this.getBackgroundColor()}} 
          >
             {this.state.EpisodeComments && <Box>
                          {this.BackButton()}
                        </Box>}
            <Box className="header-section-host" id="header-section-host-1" 
              style={{ backgroundColor: this.getSecondaryColor() }} 
            >
              <Box display="flex" flexDirection="row" gap="20px" justifyContent="space-between">
                <Box display="flex" gap="20px" flexDirection="row">
                  <img src={this.state.hostCardInfoData.cover_image} alt="Podcast" className="podacst-image-host" />
                  <Box className="podcast-header-content-host">
                    <Typography style={{ fontWeight: 900, fontSize: "40px", width:"fit-content", textTransform: "uppercase", fontFamily: "Nexa-Heavy",
                      color: this.getTextColor()
                    }}>{this.state.hostCardInfoData.name}</Typography>
                    <Typography style={{color: this.getTextColor()}} className="podcast-name-host">{this.state.hostCardInfoData.subtitle}</Typography>
                    <Box 
                      alignItems="center" display="flex" id="stars" 
                      style={{ width:"fit-content", cursor:"pointer" }}
                      onClick={() => this.openAllReviewsScreen()}
                      data-test-id="showRatingScreenTestBtn"
                    >
                      <Rating 
                        sx={{ 
                          color: this.getTextColor(),
                          '& .MuiSvgIcon-root': {
                            fontSize: '31px', 
                          },
                        }}
                        emptyIcon={<StarBorder sx={{ color: this.getTextColor() }} fontSize="inherit" />}
                        max={5}
                        value={this.getRatingsData()} 
                        precision={0.5} readOnly
                      />
                    </Box>
                    <Box display="flex" gap="8px">
                      <Button data-test-id="rateAndReviewTestBtn" onClick={() => this.handleOpenRateAndReviewDialog()} className="left-section-buttons" variant="outlined">Rate or Review</Button>
                        <Dialog
                          slotProps={{
                            backdrop: {
                              sx: {
                                backdropFilter: 'blur(5px)',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              },
                            },
                          }}
                          sx={{
                            "& .MuiDialog-paper": {
                              maxWidth: "600px",
                              width: "600px",
                              background: "#44403C",
                              borderRadius: '8px 8px 32px 8px',
                            },
                          }}
                          data-test-id="closeDialogTestBtn"
                          open={this.state.openRateOrReviewDialog}
                          onClose={() => this.handleCloseRateOrReviewDialog()}
                        >
                          <Box data-test-id="closeRateAndReviewTestBtn" onClick={() => this.handleCloseRateOrReviewDialog()} display={"flex"} justifyContent={"flex-end"} paddingTop="20px" paddingRight="30px">
                            <CloseIcon sx={{ color: "#FFFFFF" , cursor:"pointer"}} />
                          </Box>
                          <Box sx={{ padding:"0px 40px" }}>
                            <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"20px"}}>
                              {configJSON.rateThisPodcast}
                            </Typography>
                            <Box display="flex" alignItems="center">
                              {[...Array(this.state.totalStars)].map((_, index) => (
                                <IconButton 
                                  key={`empty-${index}`} 
                                  style={{ padding: 0 }} 
                                  data-test-id="ratingStarsTestBtn" 
                                  onClick={() => this.handleProvideStars(index)}
                                >
                                  {this.renderRatingStar(index)}
                                </IconButton>
                              ))}
                          </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                              <Typography sx={{ ...webStyles.title, fontSize: "24px" , marginTop:"16px"}} component="label">
                              {configJSON.leaveAReview}
                              </Typography>
                              <TextField
                                data-test-id="reviewTextField"
                                name="review"
                                value={this.state.review}
                                onChange={this.handleReviewInputChange}
                                variant="outlined"
                                multiline
                                rows={4} 
                                sx={textFieldStyles}
                              />
                            </Box>
                          </Box>
                          <Divider sx={{ marginTop:"74px", borderColor: '#FFFFFF' }} />
                          <Box display={"flex"} margin="30px 24px 30px 24px" gap={2} justifyContent={"flex-end"}>
                          <Button  onClick={() => this.handleCloseRateOrReviewDialog()} sx={{
                            ...buttonStyles,
                            border: "none",
                            color: "#fff",
                            background: "black",
                            height:"56px",
                            width:'120px',
                            "&:hover": {
                                background: "#000", 
                            }
                            }}
                            variant="contained"
                            data-test-id="cancelRateAndReviewTestBtn"
                            >
                              {configJSON.cancelText}
                            </Button>
                            <Button
                              disabled = {this.ratingDisbaled()}
                              onClick={() => this.postRateOrReviewData()}
                              data-test-id="rateOrReviewPublishTestBtn"
                              sx={{
                                ...buttonStyles,
                                border: "none",
                                color: "#FFFFFF",
                                background: "#FF0807" ,
                                height:"56px",
                                width:'120px',
                                "&:hover": {
                                    background:  "#FF0807" ,
                                },
                                "&.Mui-disabled": {
                                    backgroundColor: "#B2B3B3",
                                    color: "#fff",
                                },
                                }}
                              variant="contained"
                            >
                              {configJSON.publishText}
                            </Button>
                          </Box>
                        </Dialog>
                      <Button data-test-id="shareChannelTestId" onClick={() => this.handleOpenShareChannelDialog()} className="left-section-buttons" variant="outlined" >Share Channel</Button>
                      <Modal
                        open={this.state.openShareChannelDialog}
                        onClose={() => this.handleCloseShareChannelDialog()}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        data-test-id="modalDialog"
                        style={{background:"rgba(0,0,0,0.5)" ,  backdropFilter: 'blur(5px)',}}
                      >
                        <Box sx={ShareChannelModalstyle}>
                          <Box className="modalBox" >
                            <IconButton data-test-id="shareCloseTestBtn" onClick={() => this.handleCloseShareChannelDialog()}>
                              <CloseIconModal />
                            </IconButton>
                            <ShareChannelModal>
                              <ShareIcon aria-label="share">
                                <Share />
                              </ShareIcon>
                              <ShareChannelText variant="h6" mb={6} mt={4}>{configJSON.shareChannelModal}</ShareChannelText>
                            </ShareChannelModal>
                            <ShareChannelText variant="subtitle1" gutterBottom>{configJSON.channelUrlText}</ShareChannelText>
                            <UrlBox>
                              <ChannelTextFeild id="podcastUrl">{this.state.hostCardInfoData.public_url}</ChannelTextFeild>
                              <CopyButton data-test-id="copyChannelUrlTestBtn" onClick={() => this.handleCopyChannelUrl()} ><ContentCopy />{configJSON.copyText}</CopyButton>
                            </UrlBox>
                            <ShareChannelText variant="subtitle1" mt={4} gutterBottom>{configJSON.embedText}</ShareChannelText>
                            <LinkContainer>
                              <EmbededTextFeild id="embedUrl">{this.state.hostCardInfoData.embed_code}</EmbededTextFeild>
                              <CopyButton2 data-test-id="copyEmbedUrlTestBtn" onClick={() => this.handleCopyEmbedUrl()} ><ContentCopy />{configJSON.copyText}</CopyButton2>
                            </LinkContainer >
                          </Box>
                        </Box>
                      </Modal>
                    </Box>
                    <Typography style={{ color: `${this.getTextColor()}` }} className="total-reviews" >{this.state.hostCardInfoData.ratings?.reviews} Reviews</Typography>
                    <Box display="flex" gap="10px">
                      <Button variant="contained" data-test-id="follow-button" className="left-section-bottom-buttons" sx={{ backgroundColor: (this.state.followButtonText === "Follow" ? `${this.getButtonColor()}` : "#030303"), "&:hover": { backgroundColor: (this.state.followButtonText === "Follow" ? `${this.getButtonColor()}` : "#030303"), borderColor: `${this.getButtonColor()}`} }} onClick={() => this.handleFollowButton()}>{this.state.followButtonText}</Button>
                      <Button sx={{ backgroundColor: `${this.getButtonColor()}`, "&:hover": {backgroundColor: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`} }}  className="left-section-bottom-buttons" variant="contained" ><AttachMoney style={{ width: "24px", height: "24px" }} />Subscribe</Button>
                      <IconButton sx={{ backgroundColor: `${this.getButtonColor()}`, "&:hover": {backgroundColor: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`} }} className="podcast-header-info-icon" ><InfoOutlined /></IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" flexDirection="row" alignItems="flex-start" style={{ marginTop: '40px' }}>
                  <Box display="flex" gap="20px" flexDirection="column">
                    <Box flexDirection="row" display="flex"  gap="20px">
                      <Box display="flex" gap="10px" flexDirection="column">
                        <Button variant="outlined" style={{ backgroundColor: this.getButtonColor(), color: '#fff', borderColor: this.getButtonColor(), width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><Laptop style={{ width: "24px", height: "20px" }} />Webinar</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', height: "44px", width: '164px', borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><img src={tshirtIcon} />Buy Swag</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontFamily: "Nexa-Heavy", fontWeight: "bold" }}><AttachMoney style={{ width: "24px", height: "24px" }} />Support Host</Button>
                      </Box>

                      <Box display="flex" gap="10px" flexDirection="column">
                        <Button variant="outlined" style={{ borderColor: this.getButtonColor(), backgroundColor: this.getButtonColor(), color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><ShoppingCartOutlined style={{ width: "24px", height: "20px" }} />Buy Now</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', width: '164px', height: "44px", color: '#fff', borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><MenuBook style={{ width: "24px", height: "20px" }} />Learn More</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", textTransform: 'capitalize', borderRadius: "8px", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><LocalOfferOutlined style={{ width: "24px", height: "20px" }} />Get Offer</Button>
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" flexDirection="column">
                      <Button variant="outlined" style={{ color: this.getTextColor(), height: "44px", width: '345px', borderRadius: "8px", border: `1px solid ${this.getTextColor()}`, fontWeight: 900, textTransform: "capitalize", fontFamily: "Nexa-Heavy" }} startIcon={<Phone />}>
                        Contact Host
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            
            {this.state.commentType !== "nestedComment" && this.renderTabs()}
            <Box sx={{ display: 'grid', gridTemplateColumns: '65% 35%', gap: '24px', marginBottom: "6%" }}>
              { !this.state.openReviewsScreen ? (
              <div className="scrollableWrapper" id="scrollableWrapper" style={{ height: this.state.selectedTab === "Episodes & Community" ? "98rem" : "65rem" }}>
                {this.state.commentType == "nestedComment" &&
                  <Box display="flex" alignItems="center" mt={3}>
                    <img data-test-id="backCommentBtn" src={goBack} onClick={this.handleBackComment} alt="Go Back" style={{ width: "25px" }} />
                    <Typography variant="h4" sx={{ color: '#fff', marginLeft: "15px", fontWeight: "bold" }}>
                      Back
                    </Typography>
                  </Box>}
              {this.state.commentType == "nestedComment" ? this.renderComments(this.state.postCommentsData, 43, "mainComment"):
              <>
                <Box className="left-body-section" sx={{ scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '64rem' }} id="left-body-section-1"
                display={this.state.selectedTab === "Episodes" ? "block" : "none"}
                >
                  {(this.state.selectedTab == "Episodes" && this.state.templateType === "Audio Focused") && this.renderPodcastForAudio()}
                  {(this.state.selectedTab == "Episodes" && this.state.templateType === "Video Focused") && this.renderPodcastCardForVideo()}
                </Box>
                <Box className="left-body-section" sx={{  scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '64rem', display: this.setDisplay1() }}>
                  {(this.state.selectedTab == "Episodes & Community" && this.state.templateType === "Audio + Community Focused") && this.renderPostCardForSocialAudio()}
                </Box>
                <Box className="left-body-section" sx={{  scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '64rem', display: this.setDisplay2() }}>
                  {(this.state.selectedTab == "Episodes & Community" && this.state.templateType === "Video + Community Focused") && this.renderPostCardForSocialVideo()}
                </Box>
                <Box className="left-body-section" sx={{ scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '64rem', display: this.setDisplay3() }}>
                  {this.renderHostCommunityPost(this.state.hostCommunityPostData)}
                </Box>
                <Box className="left-body-section" sx={{ scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '64rem', display: this.setDisplay4() }}>
                  {this.renderHostChannelInfo(this.state.hostChannelInfoData)}
                </Box>
              </>}
                {(this.state.selectedTab == "Episodes & Community" && this.state.templateType === "Audio + Community Focused") &&
                  <>
                    <Typography style={{
                      fontFamily:"Nexa-Heavy", fontSize:"30px", fontWeight:900,
                      color:`${this.getTextColor()}`, marginTop:"20px"
                    }}>
                      Episodes
                    </Typography>
                    <Box className="left-body-section" sx={{  scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '28rem' }}>
                      {this.renderPodcastForAudio()}
                    </Box>
                  </>
                }
                {(this.state.selectedTab == "Episodes & Community" && this.state.templateType === "Video + Community Focused") &&
                  <>
                    <Typography style={{
                      color:`${this.getTextColor()}`, marginTop:"20px",
                      fontFamily:"Nexa-Heavy", fontSize:"30px", fontWeight:900,
                    }}>
                      Episodes
                    </Typography>
                    <Box className="left-body-section" sx={{  scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '28rem' }}>
                      {this.renderPodcastCardForVideo()}
                    </Box>
                  </>
                }
              </div>) : (this.renderAllReviewsScreen())}
              <Box sx={{ minHeight: '100vh'  }}>
                <Grid container  spacing={2} direction="column" className="right-body-section">
                  <Typography style={{color: this.getTextColor()}} className="sponsored-title" id= "sponsored">Sponsored Recommendations</Typography>
                  <Grid item>
                    <Card className="recommended-cards" id="recommended-cards-1"
                      style={{
                        backgroundColor: this.getSecondaryColor(),
                        borderLeft: `4px solid ${this.getButtonColor()}`
                      }} 
                    >
                      <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                        <CardContent>
                          <Typography sx={{ fontSize:"16px", fontWeight:700, fontFamily: "Nexa-Heavy", color: this.getTextColor()}}>
                            The True
                          </Typography>
                          <Typography sx={{ color: this.getTextColor(), fontFamily: "Nexa-Regular",fontSize:"14px", fontWeight:400, }}>
                            Cost of Success Ep. 9
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_1}
                        sx={{
                          width: "20%",
                          height: "100%",
                          objectFit: 'none',
                          marginLeft: 'auto',
                          marginRight: "-11px"
                        }}
                        alt="The True Episode"
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards" id= "recommended-cards-2"
                      style={{
                        backgroundColor:this.getSecondaryColor(),
                        borderLeft: `4px solid ${this.getButtonColor()}`
                      }} 
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography sx={{ fontSize:"16px", fontWeight:700, fontFamily: "Nexa-Heavy", color: this.getTextColor()}}>
                            All Consuming
                          </Typography>
                          <Typography sx={{ color: this.getTextColor(), fontFamily: "Nexa-Regular",fontSize:"14px", fontWeight:400, }}>
                            Fantomy Ep. 12
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_2}
                        alt="The True Episode"
                        sx={{
                          height: "100%",
                          width: "20%",
                          marginLeft: 'auto',
                          objectFit: 'none',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards" id= "recommended-cards-3"
                      style={{
                        backgroundColor:this.getSecondaryColor(),
                        borderLeft: `4px solid ${this.getButtonColor()}`
                      }} 
                    >
                      <Box sx={{ flexDirection: 'column',display: 'flex' }}>
                        <CardContent>
                          <Typography sx={{ fontSize:"16px", fontWeight:700, fontFamily: "Nexa-Heavy", color: this.getTextColor()}}>
                            This American Life
                          </Typography>
                          <Typography sx={{ color: this.getTextColor(), fontFamily: "Nexa-Regular",fontSize:"14px", fontWeight:400, }}>
                            Love your era Ep. 8
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        sx={{
                          width: "20%",
                          height: "100%",
                          objectFit: 'none',
                          marginLeft: 'auto',
                          marginRight: "-11px",
                        }}
                        image={recommended_episode_3}
                        alt="The True Episode"
                      />
                    </Card>
                  </Grid>
                </Grid>
                <Box flexDirection="column" display="flex"  gap="25px">
                  <Typography sx={{ color: this.getTextColor(), marginTop: 4, fontSize:"18px", fontWeight:900, fontFamily: 'Nexa-Heavy' }}>
                    Advertising
                  </Typography>
                  <Box
                    component="img"
                    src={ad_1}
                    sx={{ height: '20rem', borderRadius: "13px" }}
                    alt="Burger Ad"
                  />
                  <Box
                    component="img"
                    alt="Heinz Ad"
                    sx={{ height: '20rem', borderRadius: "13px" }}
                    src={ad_2}
                  />
                </Box>
              </Box>
            </Box>
          </StyledWrapperSetting>
          <div style={{
            backgroundColor: "#030303"
          }}>
            <Footer navigation={this.props.navigation} id={"1"} />
          </div>
        </div>
        <Dialog open={this.props.isShowEditHostLogoModal} onClose={this.handleCloseEditLogoDialog} PaperProps={{
          style: {
            width: '45rem',
            margin: '0 auto',
            borderRadius: "8px 8px 30px 8px",
            height: '52%',
          },
        }}
          componentsProps={{
            backdrop: {
              style: {
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                backdropFilter: 'blur(4px)',
              },
            },
          }}
          maxWidth={false}
        >
          <IconButton
            onClick={this.props.closeEditHostLogoModal} 
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff'
            }}
            data-test-id="second-dialog-close-icon"
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent sx={{ textAlign: "center", backgroundColor: "#44403C", padding: "30px" }}>
            <Typography variant="h6" align="center" style={{ color: "#fff", marginBottom: "2%", marginLeft: "45%", marginTop: "4%", fontFamily: 'Nexa-Heavy' }}>
              New Logo Image:
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: "space-between"
              }}
            >
              <div style={{ width: '30%', marginLeft: "7%" }}>
                <img
                  src={this.getCurrentImageRender()}
                  alt="Logo"
                  style={{ width: '108%', height: '12.7rem', borderRadius: '10px', border: "1px solid white", padding: "0% 4% 0% 4%" }}
                />
              </div>
              <div
                style={{
                  padding: '20px',
                  border: '2px dashed #fff',
                  borderRadius: '10px',
                  width: '42%',
                  height: '10rem',
                  backgroundColor: '#3D3D3D',
                  marginRight: "5%"
                }}
              >
                {this.state.tempLogoUrl ? (
                  <Box sx={{ position: 'relative', width: '100%' }}>
                    <img src={this.state.tempLogoUrl} alt="Preview" style={{ width: '95%', height: '10rem', borderRadius: '8px' }} />
                    <IconButton
                      onClick={() => this.setState({ imagePreviewUrl: "", selectedFile: null, tempLogoUrl: "" })}
                      sx={{ position: 'absolute', top: 2, right: 10, color: '#000000' }}
                      data-test-id="logo-url"
                    >
                      <Close />
                    </IconButton>
                  </Box>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <Box>
                      <FileUploadOutlined style={{ color: '#FF0807' }} />
                    </Box>
                    <Typography variant="body2" style={{ color: "#fff", fontFamily: 'Nexa-Heavy', width: "75%", marginLeft: "13%" }}>
                      Drag & Drop Your File Here OR
                    </Typography>
                    <Button
                      variant="contained"
                      component="label"
                      style={{ marginTop: '10px', backgroundColor: '#FF0807', fontFamily: 'Nexa-Heavy', width: "101px", fontSize: "10px" ,height: "26px", borderRadius: "8px", textTransform: "none" }}
                    >
                      Browse file
                      <input
                        hidden
                        accept="image/*"
                        data-test-id="upload_file"
                        type="file"
                        onChange={this.handleFileChange}
                      />
                    </Button>
                    <Typography variant="body2" style={{ color: "#fff", marginTop: '10px', fontFamily: 'Nexa-Heavy', width: "75%", marginLeft: "13%" }}>
                      Max File Size: 200mb Supports JPG and PNG files
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'center', backgroundColor: "#44403C", height: '18%', display: "flex", gap: "16px" }}>
            <Button onClick={this.props.closeEditHostLogoModal}  variant="contained" style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', width: "25%", height: "65%", borderRadius: "5px" }} data-test-id="cancel">
              Cancel
            </Button>
            <Button
              onClick={this.handleDoneClick}
              variant="contained"
              disabled={!this.state.selectedFile}
              style={{
                backgroundColor: ResolveCondition((this.state.selectedFile !== null), "#FF0807", "#A9A9A9"),
                textTransform: 'capitalize',
                fontFamily: 'Nexa-Heavy',
                width: "20%",
                height: "65%",
                borderRadius: "5px",
                color:"#FFFFFF"
              }}
              data-test-id="done"
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const BackButtonFrame = styled('div')({
  display: 'flex', gap: '30px', maxHeight: '40px', alignItems: 'center'
})

const TextBackButton = styled('strong')({
  fontFamily: 'Nexa-Heavy',
  weight: '900',
  fontSize: '30px'
})

const BackIcon = styled('img')({
  backgroundColor: '#44403C',
  maxWidth: '40px',
  maxHeight: '40px',
  borderRadius: '8px'
})

const webStyles = {
  container1: {
    padding: "20px 50px",
  } as const,
  cardIcon: {
    fontSize: '14px', lineHeight: '26px', fontWeight: 900, fontFamily: 'Nexa-Heavy'
  },
  headerTypography: {
    fontFamily: "Nexa-Heavy", fontSize: "14px", fontWeight: 900, textTransform :"uppercase"
  },
  followBtn: {
    minWidth: "72px", height:"32px", borderRadius: "8px", fontFamily: "Nexa-Heavy", 
    fontSize:"16px", fontWeight: 900, padding: "6px 10px", textTransform:"none"
  },
  mainCardHeader: {
    fontFamily: "Nexa-Heavy", fontSize: "26px", fontWeight: 900,
  },
  descriptionTypo: {
    fontFamily: "Nexa-Heavy", fontSize: "18px", fontWeight: 900,
  },
  title : {
    fontWeight: 900,
    letter: "-0.5%",
    color: "#fff",
    fontFamily: "Nexa-Heavy"
  },
}

const CloseIconModal = styled(CloseIcon)({ color: "#fff", position: "absolute", left: "1120px" })
const ShareChannelModal = styled(Box)({
  display: "flex",
  alignItems: "center"
});
const ChannelTextFeild = styled(Typography)(
  {
    backgroundColor: "#000",
    border: "2px solid #fff",
    borderRadius: "5px",
    width: "61%",
    color: "#fff",
    display: "flex",
    justifyContent: "start",
    padding: "20px"
  }
);

const UrlBox = styled(Box)(
  {
    display: "flex",
    width: "100%",
    alignItems:"center"
  }
)

const ShareIcon = styled(IconButton)({
  color: '#fff',
  marginBottom: "11px"
});

const ContentCopy = styled(ContentCopyIcon)(
  {
    color: "#fff",
    marginRight: "12px"
  }
)

const EmbededTextFeild = styled(Typography)(
  {
    backgroundColor: "#000",
    border: "2px solid #fff",
    borderRadius: "5px",
    width: "61%",
    color: "#fff",
    display: "flex",
    justifyContent: "start",
    padding: "20px"
  }
)

const EpisodeTextFeild = styled(Typography)(
  {
    backgroundColor: "#000000",
    border: "1px solid #FFF",
    borderRadius: "8px",
    width: "707px", height:"56px",
    color: "#FFFFFF",
    display: "flex", boxSizing:"border-box",
    justifyContent: "start", alignItems:"center",
    padding: "10px 8px"
  }
)

const EpisodeStartAtTextFeild = styled(Typography)(
  {
    backgroundColor: "#000000",
    border: "1px solid #FFF",
    borderRadius: "8px",
    width: "58px", height:"56px",
    color: "#FFFFFF",
    display: "flex", boxSizing:"border-box",
    justifyContent: "start", alignItems:"center",
    padding: "10px 8px"
  }
)

const EpisodeEmbededTextFeild = styled(Typography)(
  {
    backgroundColor: "#000000",
    border: "1px solid #FFF",
    borderRadius: "8px",
    width: "707px", height:"56px",
    color: "#FFFFFF",
    display: "flex", boxSizing:"border-box",
    justifyContent: "start", alignItems:"center",
    padding: "10px 8px"
  }
)

const CopyButtonEpisode = styled(Button)(
  {
    width: "136px",
    height:"56px",
    borderRadius: "8px", color:"#FFF",
    fontSize:"16px", fontWeight:900, fontFamily:"Nexa-Heavy",
    background:"#FF0807", textTransform:"none",
    "&:hover": {
      backgroundColor: "#FF0807"
    }
  }
)

const LinkContainer = styled(Box)({
  display: "flex",
  width: "100%",
  alignItems: "center"
})

const CopyButton2 = styled(Button)({
  width: "136px",
  height:"56px",
  marginLeft: "15px",
  borderRadius: "8px", color:"#FFF",
  fontSize:"16px", fontWeight:900, fontFamily:"Nexa-Heavy",
  background:"#FF0807", textTransform:"none",
  "&:hover": {
    backgroundColor: "#FF0807"
  }
})

const ShareChannelText = styled(Typography)({
  fontFamily: "Nexa-Heavy",
  color: "#fff"
});
const CopyButton = styled(Button)(
  {
    width: "136px",
    height:"56px",
    marginLeft: "15px",
    borderRadius: "8px", color:"#FFF",
    fontSize:"16px", fontWeight:900, fontFamily:"Nexa-Heavy",
    background:"#FF0807", textTransform:"none",
    "&:hover": {
      backgroundColor: "#FF0807"
    }
  }
)
const SharePostModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 999,
  height : 343,
  bgcolor: '#44403C',
  borderRadius: "8px 8px 32px 8px",
  p: 4, 
  boxSizing:"border-box"
};
const ShareChannelModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height : 466,
  bgcolor: '#44403C',
  borderRadius:2,
  p: 4,
  boxSizing:"border-box"
};
const buttonStyles = {
  borderRadius: "8px",
  fontFamily: "Nexa-Heavy",
  fontSize: "16px",
  textTransform: "capitalize",
  height: "34px",
};
const textFieldStyles = {
  '& .MuiInputBase-root': {
    backgroundColor: '#030303',
    borderRadius: '8px',
    color: 'white',
    fontFamily: 'Nexa-Heavy',
    fontWeight: 900,
    fontSize: '16px',
    border: '1px solid #FFFFFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};
const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  height: "auto",
  // width: "100%",
  paddingRight: "2%",
  paddingBottom: "0.05%",
  background: "#1C1917",
  "& *": {
    boxSizing: "border-box",
  },
  "& .header-section-host": {
    width: "102%",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    padding: "1rem",
    // marginLeft: "90px",
    marginTop: "18px"
  },
  "& .podacst-image-host": {
    width: "269px",
    height: "259px",
    borderRadius: "5px",
    marginTop: "13px",
    marginLeft: "8px"
  },
  "& .podcast-name-host": {
    fontWeight: "900",
    fontSize: "16px",
    fontFamily: "Nexa-Heavy"
  },
  "& .podcast-header-content-host": {
    color: "white",
    fontWeight: "bold",
    display:"flex",
    flexDirection:"column",
    gap:"8px"
  },
  "& .podcast-header-info-icon": {
    width: "44px",
    height: '44px',
    borderRadius: "8px",
    color: "white",
  },
  "& .left-section-buttons": {
    color: "white",
    border: "1px solid white",
    borderRadius: "8px",
    fontWeight: "bold",
    textTransform: 'capitalize',
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      color: "white",
      border: "1px solid white",
    }
  },
  "& .total-reviews": {
    fontSize: "18px",
    fontWeight: "900",
    fontFamily: "Nexa-Heavy",
  },
  "& .left-section-bottom-buttons": {
    color: "white",
    borderRadius: "8px",
    height: '44px',
    width: "164px",
    fontWeight: "bold",
    textTransform: 'capitalize',
    fontFamily: "Nexa-Heavy",
  },
  "& .left-body-section-tabs": {
    height: "52px",
    // width: "536px",
    // marginLeft: "90px",
    marginTop: "22px"
  },
  "& .scrollableWrapper": {
    position: "relative",
    paddingRight: "20px",
    overflow: "hidden",
  },
  "& .section-tabs": {
    color: 'white',
    textTransform: "capitalize",
    fontFamily: "Nexa-Heavy",
    marginBottom: "0px"
  },
  "& .left-body-section": {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    paddingRight: "10px",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#4D4D4D",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FF4500",
      borderRadius: "10px",
    },
  },
  "& .podcast-card": {
    display: 'flex',
    gap: "24px",
    padding:"20px",
    // width: "1005px",
    height: "auto",
    "&:first-of-type": {
      marginTop: "0px",
    },
    "&:not(:first-of-type)": {
      marginTop: "20px",
    }
  },
  "& .podcast-inner-box-content": {
    flex: '1 0 auto',
    padding: 0
  },
  "& .podcast-inner-box": {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  "& .podcast-saving-options": {
    display: 'flex',
    flexDirection: 'column',
    gap: "9px",
    position:"sticky",
    right:"0px"
  },
  "& .podcast-action-buttons": {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10
  },
  "& .podcast-save-button": {
    color: 'white',
    borderRadius: "8px",
    height: "26px",
    width: "118px",
    textTransform: "capitalize",
    gap: "8px",
  },
  "& .play-icon": {
    display: 'flex',
    alignItems: 'flex-end',
    position:"sticky",
    right:"0px"
  },
  "& .podcast-media": {
    width: "123px",
    height: "118px",
    borderRadius: "3px"
  },
  "& .right-body-section": {
    marginTop: "-35px",
    // width: "47.8%", 
    // height: "33%"
  },
  "& .sponsored-title": {
    marginLeft: "15px",
    fontFamily: 'Nexa-Heavy',
    color: "#fff"
  },
  "& .podcast-inner": {
    fontWeight: 900,
    fontFamily: 'Nexa-Heavy'
  },
  "& .recommended-cards": {
    display: 'flex',
    color: '#fff',
    // width: '478px', 
    height: '80px',
    borderRadius: '6px',
  }
});

const PodcastCard = styled(Card)({
  display: 'flex',
  backgroundColor: '#44403C',
  gap: '24px',
  height: 'auto',
  "&:first-of-type": {
    marginTop: "0px",
  }
});

const PodcastInnerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const PodcastInnerBoxContent = styled(CardContent)({
  flex: '1 0 auto',
  paddingBottom: 0,
  borderBottom: '1px solid #E0E0E0',
});

const PodcastContentBox = styled(CardContent)({
  padding: '22px 33px 22px 43px',
});

const ProfileImages = styled('img')({
  width: '56px',
  height: '56px',
  borderRadius: '50%',
  marginRight: '10px',
});

const TextFieldWrapper = styled(Box)({
  backgroundColor: 'black',
  borderRadius: '20px 20px 0px 0px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const StyledText = styled(TextField)({
  color: '#FFFFFF',
  backgroundColor: '#000000',
  borderRadius: '8px',
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: 'black',
  },

})

const ReplyButton = styled(Box)({
  bottom: '10px',
  position: 'absolute',
  display: 'flex',
  right: '10px',
  gap: '10px',
});

const StyledIcon = styled(IconButton)({
  backgroundColor: '#DADADA',
  color: 'white',
});

const TextCommentHead = styled(Typography)({
  fontSize: "30px", 
  color: "#FFFFFF",
  marginTop: "25px", 
  lineHeight: "40px"
});

const CommunityList = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '32px',
});

const CommunityListButtons = styled(Box)({
  alignItems: 'center',
  display: 'flex',
});

// Customizable Area End
