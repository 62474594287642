import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { ad_1 , Image_dots, podcastImage} from "./assets";
import React, { createRef } from "react";

export interface ApiCallInterface {
  contentType: string,
  method: string,
  endPoint: string,
  body?: {},
  type?:string
}

export interface PostCommentObject  {
  attributes: {
    account_id: string,
    commentable_id: string,
    commentable_type: string,
    comment: string,
    created_at: string,
    account_details: {
      account_id: string,
      first_name: string,
      last_name: string,
      profile: string
    },
    likes:{
      liked: boolean,
      liked_id: string,
      count: string
    },
    reply_count:string
  },
  id: string,
  type: string
}

export interface PostCommentsSuccessResponse {
  data: Array<PostCommentObject>
}

export interface NumberStringInterface {
  [key: string] : string
}

export interface NumberFileInterface {
  [key: string]: File;
}

export interface Post {
  data: [];
  id: string;
  type: string;
  attributes: PostAttributes;
}

interface PostAttributes {
  name: string | null;
  body: string;
  pinned: boolean;
  podcast_id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
  post_notification: boolean;
  share_post_count: number;
  post_url: string;
  photos: Photo[];
  account: Account;
  tags: string[];
  liked_post: LikedPost;
  save_post: SavePost;
  comments: Comments;
}

interface Photo {
  photot_link: string;
  photot_name: string;
  upload_date: string;
  photot_id: number;
}

interface Account {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface AccountAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  bio: string | null;
  profile_picture: string | null;
  user_location: string;
  roles: Role[];
  podcast_categories_ids: number[];
}

interface Role {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface LikedPost {
  liked: boolean;
  liked_id: string ;
  count: number;
}

interface SavePost {
  saved: boolean;
  saved_id: string ;
}

interface Comments {
  data: any[];
}

export interface HostsData{
  email: string;
  id : string;
  first_name : string ; 
  last_name : string;
  host_location : string;
  profile_picture : string;
  bio : string
}
export interface SubCategoryData{
  id : string;
  name : string ; 
}

export interface EpisodeData {
  id: string;
  type: string;
  attributes: {
    name: string;
    status: string;
    cover: string;
    description: string;
    episode_number: number;
    episode_content: string;
    adult: boolean;
    podcast_season: number;
    premium: boolean;
    episode_type: string;
    expiry_date: string;
    explicit_content: boolean;
    publish_date: { 
      publish_date : string; 
    }
    episode_time: string;
    account: {
      id: number;
      first_name: string;
      last_name: string;
      full_phone_number: string;
      country_code: number;
      phone_number: number;
      email: string;
      activated: boolean;
      device_id: string | null;
      unique_auth_id: string;
      password_digest: string;
      created_at: string;
      updated_at: string;
      user_name: string;
      platform: string | null;
      user_type: string | null;
      app_language_id: string | null;
      last_visit_at: string | null;
      is_blacklisted: boolean;
      suspend_until: string | null;
      status: string;
      role_id: number | null;
      gender: string | null;
      date_of_birth: string;
      age: number | null;
      user_location: string;
      race: string | null;
      social_media_links: Record<string, string>;
      favorite_podcast_categories: any[];
      social_media_url: {
        x: string;
        facebook: string;
        instagram: string;
      };
      podcast_categories_ids: (number | null)[];
      bio: string | null;
      last_logged_in_at: string | null;
      stripe_id: string | null;
      main_playlist_id: string | null;
    };
    share_episode_count: number;
    episode_url: string;
    embed_code: string;
    chapters: {
      data: {
        id: string;
        type: string;
        attributes: {
          start_time: string;
          end_time: string;
          photo: string;
        };
      }[];
    };
    save_episode: {
      saved: boolean;
      saved_id: string ;
    };
    liked_episode: {
      liked: boolean;
      liked_id: string ;
      count: number;
    };
    comments: {
      data: any[];
    };
    added: {
      song_added: boolean;
    };
  };
}
interface PodcastData {
  podcast: {
    data: {
      id: string;
      type: string;
      attributes: {
        ratings: { ratings: number, reviews: number };
        name: string;
        description: string;
        location: string;
        explicit_lang: boolean;
        adult: boolean;
        timezone: string;
        subtitle: string | null;
        podcast_time: string;
        public_url : string;
        host_type: string;
        rss_url: string | null;
        share_channel_count: number;
        channel_url: string;
        embed_code: string;
        cover_image: string 
        categories: {
          data: Category[];
        };
        sub_categories: Array<SubCategoryData>;
        hosts: Array<HostsData>;
        episodes: {
          data: Array<EpisodeData>;
        };
        color_scheme: {
          data: ColorScheme;
        };
        follower: {
          following: boolean;
          follow_id: number;
        };
      };
    };
  };
  message: string;
}

interface Category {
  id: string;
  type: string;
  attributes: {
    name: string;
    image: {
      url: string;
    };
    created_at: string;
    updated_at: string;
  };
}

interface ColorScheme {
  id: string;
  type: string;
  attributes: {
    bg_color: string;
    secondry_color: string;
    text_color: string;
    button_color: string;
    template_type: string;
    logo: string | null;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleAddAudio: (type: string, id: string) => void;
  handleCommonPath: (path:string) => void;
  reRenderTheLogo: () => void
  // Customizable Area End
}

interface S {
  EpisodeComments:boolean,
  parentId:string;
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  totalStars: number;
  reviewCount: number;
  ratingStars: number,
  fullStars: number;
  hasHalfStar: boolean;
  emptyStars: number;
  totalReviews: number;
  podcastName: string;
  followButtonText: string;
  selectedTab: string,
  selectedFile:any,
  textareaValue:string,
  textareaFortags: string[];
  communityData:any,
  isContactHost:boolean;
  name:string;
  title:string;
  description:string;
  review:string;
  phone_num:string;
  contactSuccess:boolean;
  errorMsg:string;
  open :boolean;
  openRateOrReview : boolean;
  showComments:boolean;
  comments: any;
  activeCommentId: any;
  openDropDown: null;
  openDropdownForMyOrOthersPost: string;
  openDropDownForOtherPost: null;
  currPostId: string;
  currEpisodeId: string;
  openPopup:boolean;
  anchorEl:any;
  communityListData : Array<Post>
  originalCommunityListData : Array<Post>
  saveEpisodeListData: PodcastData;
  saveVideoEpisodeListData: PodcastData;
  colorSchemeEpisodeData: any;
  templateType: string;
  userProfileData: any;
  hostData: any;
  openSharePostDialog: boolean;
  currentCommentId: string;
  postComments: Array<PostCommentObject>;
  nestedComments: Array<PostCommentObject>;
  nestedCommentId: string;
  showSingleComment: boolean;
  communityItem: Post;
  communityItemIndex: number;
  commentType: string;
  currActiveVideo: NumberStringInterface;
  openReviewsScreen: boolean;
  allReviewsData: any;
  residentChannelInfoData: any;
  userReview: any;
  openChannelInfoPlansDialog: boolean;
  openSubscribePlansDialog: boolean;
  expandSeeMore: NumberStringInterface;
  openNewTierSuccessPopup: boolean;
  isCardDetailsNeededForSubscription: boolean;
  openPaymentsDetailsPopup: boolean;
  openEditPost: NumberStringInterface;
  selectedFileForEdit: NumberFileInterface;
  selectedFileUrlForEdit: NumberStringInterface;
  textareaValueForEdit: NumberStringInterface;
  openMentionsSearchResultsPopup: boolean;
  currAtIndex: number;
  searchMentionsdata: any;
  searchMentionName: string;
  openPopupEditText: string;
  openSupportHostDialog: boolean;
  openShareEpisodePopup: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
    // Customizable Area Start
    communityPostCallId:string="";
    communityPatchAPICallId:string="";
    userDetailsApiCallId: string = "";
    contactHostCallId:string="";
    getCommunityDataApiCallId:string = "";
    getSaveEpisodesListApiCallId: string= "";
    saveEpisodeCatalogueCallId : string = "";
    addToPlaylistCallId : string = "";
    removePlaylistCallId : string = "";
    likeEpisodeCatalogueCallId : string = "";
    unlikeEpisodeCatalogueCallId : string = "";
    unsaveEpisodeCatalogueCallId: string = "";
    postReviewApiCallId: string = "";
    followPodcastApiCallId : string = "";
    unfollowPodacastApiCallId : string = "";
    likePostAPICallId: string = "";
    unlikePostAPICallId: string = "";
    getHostChannelDataApiCallId: string = "";
    savePostAPICallId: string = "";
    unSavePostAPICallId: string = "";
    turnOffNotificationOfMyPostAPICallId: string = "";
    deletePostAPICallId: string = "";
    hidePostAPICallId: string = "";
    blockUserAPICallId: string = "";
    commentOnPostApiCallId: string = "";
    getPostCommentsApiCallId: string = "";
    getNestedCommentsApiCallId: string = "";
    videoRefs: any = [];
    addCommentLikeForHostApiCallId: string = "";
    removeCommentLikeFromCatalogApiCallId: string = "";
    getAllReviewsInResidentApiCallId: string = "";
    likeReviewAPICallId: string = "";
    unLikeReviewAPICallId: string = "";
    otherShowsInResidentApiCallId: string = "";
    followOtherPodcastApiCallId: string = "";
    unFollowOtherPodcastApiCallId: string = "";
    podcastSubCategoryApiCallId: string = "";
    residentSearchMentionsResultsApiCallId: string = "";
    mentionPopupRef: React.RefObject<HTMLDivElement> = createRef();
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      EpisodeComments:false,
      parentId:'',
      arrayHolder: [],
      token: "",
    // Customizable Area Start
      totalStars: 5,
      reviewCount: 3.5,
      fullStars: 0,
      ratingStars: 0,
      hasHalfStar: false,
      emptyStars: 0,
      totalReviews: 0,
      podcastName: "Cost of Winning",
      followButtonText: "Follow",
      selectedTab: "Episodes",
      selectedFile:null,
      textareaValue:"",
      textareaFortags: [],
      communityData:[]  ,
      isContactHost:false, 
      name:"",
      title:"",
      description:"",
      review : "",
      phone_num:"",
      contactSuccess:false,
      errorMsg:"",
      open : false,
      openRateOrReview: false,
      showComments:false,
      comments: [],
      activeCommentId: null,
      openDropDown : null,
      openDropdownForMyOrOthersPost: "",
      openDropDownForOtherPost: null,
      currPostId: "",
      currEpisodeId: "",
      openPopup:false,
      anchorEl:null,
      communityListData : [],
      originalCommunityListData: [],
      saveEpisodeListData: {
        podcast: {
          data: {
            id: "",
            type: "",
            attributes: {
              name: "",
              description: "",
              ratings: { ratings: 0, reviews: 0 },
              location: "",
              explicit_lang: true,
              adult: true,
              timezone: "",
              subtitle: null,
              public_url : "",
              podcast_time: "",
              host_type: "",
              rss_url: null,
              share_channel_count: 0,
              channel_url: "",
              embed_code: "",
              cover_image: "",
              categories: {
                data: [
                  {
                    id: "",
                    type: "",
                    attributes: {
                      name: "",
                      image: {
                        url: ""
                      },
                      created_at: "",
                      updated_at: ""
                    }
                  }
                ]
              },
              sub_categories: [],
              hosts: [],
              episodes: {
                data: []
              },
              color_scheme: {
                data: {
                  id: "",
                  type: "",
                  attributes: {
                    bg_color: "",
                    secondry_color: "",
                    text_color: "",
                    button_color: "",
                    template_type: "",
                    logo: null
                  }
                }
              },
              follower: {
                following: false,
                follow_id: 0
              }
            }
          }
        },
        message: ""
      },
      saveVideoEpisodeListData: {
        podcast: {
          data: {
            id: "",
            type: "",
            attributes: {
              name: "",
              description: "",
              location: "",
              ratings: { ratings: 0, reviews: 0 },
              adult: true,
              explicit_lang: true,
              timezone: "",
              public_url : "",
              subtitle: null,
              host_type: "",
              podcast_time: "",
              share_channel_count: 0,
              rss_url: null,
              channel_url: "",
              cover_image: "",
              embed_code: "",
              categories: {
                data: [
                  {
                    id: "",
                    type: "",
                    attributes: {
                      image: {
                        url: ""
                      },
                      name: "",
                      updated_at: "",
                      created_at: "",
                    }
                  }
                ]
              },
              color_scheme: {
                data: {
                  type: "",
                  id: "",
                  attributes: {
                    bg_color: "",
                    button_color: "",
                    secondry_color: "",
                    text_color: "",
                    logo: null,
                    template_type: ""
                  }
                }
              },
              sub_categories: [],
              hosts: [],
              episodes: {
                data: []
              },
              follower: {
                following: false,
                follow_id: 0
              }
            }
          }
        },
        message: ""
      },
      colorSchemeEpisodeData: [],
      templateType: "",
      userProfileData: [],
      hostData: [],
      openSharePostDialog: false,
      currentCommentId: "",
      postComments: [],
      nestedCommentId: "",
      nestedComments: [],
      showSingleComment: true,
      communityItem: {} as Post,
      communityItemIndex: 0,
      commentType: "",
      currActiveVideo: {},
      openReviewsScreen: false,
      allReviewsData: [],
      residentChannelInfoData: [],
      userReview: [],
      openChannelInfoPlansDialog: false,
      openSubscribePlansDialog: false,
      expandSeeMore: {},
      openNewTierSuccessPopup: false,
      isCardDetailsNeededForSubscription: true,
      openPaymentsDetailsPopup: false,
      openEditPost: {},
      selectedFileForEdit: {},
      selectedFileUrlForEdit: {},
      textareaValueForEdit: {},
      openMentionsSearchResultsPopup: false,
      currAtIndex: -1,
      searchMentionsdata: [],
      searchMentionName: "",
      openPopupEditText: "",
      openSupportHostDialog: false,
      openShareEpisodePopup: false
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getSaveEpisodeList()
    this.getCommunityPost();
    this.userDetails();
    this.getChannelData();
    this.getOtherShowsAPICall()
    // Customizable Area Start  
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.calculateStars(this.state.reviewCount);
    this.subCategoryApiTwo();
    this.subCategoryApiThree();
    this.categoryApiTwo();
    this.categoryApiThree();
    document.addEventListener('mousedown', this.handleClickOutside);
     // Customizable Area End
  }

  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForCatalogueSaveEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.data.id) {
      this.getSaveEpisodeList()
    }
  }

  handleResForCatalogueUnSaveEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.message == "Successfully Unsaved") {
      this.getSaveEpisodeList()
    }
  }

  handleCatalogueLikeEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if(responseJson.data.id){
      this.getSaveEpisodeList()
    }
  }

  handleCatalogueunlikeEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
     if(responseJson.message === "Successfully destroy"){
      this.getSaveEpisodeList()
     }
  }

  handleAddToPlayListEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Song added successfully") {
      this.getSaveEpisodeList();                  
    }
  }

  handleRemovePlayListEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Song removed successfully") {
      this.getSaveEpisodeList();                  
    }
  }

  handleFollowPodacast = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if(responseJson.data.id){
      this.getSaveEpisodeList(); 
    }
  }
  
  handleUnfollowPodacast = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
      if(responseJson.message === "Successfully Unfollowed"){
        this.getSaveEpisodeList(); 
      }
  }
  
  handleResForRateAndReview = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
     if(responseJson.message === "Review successfully submitted") {
      this.setState({ openRateOrReview : false });
      this.getSaveEpisodeList();
     }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const ApiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.communityPostCallId
    ) {
    
     this.setState({selectedFile:null,textareaValue:"",communityData:ApiResponse?.data, textareaFortags: [], openMentionsSearchResultsPopup: false })
     this.getCommunityPost();
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.contactHostCallId != null &&
      this.contactHostCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        this.setState({errorMsg:"Internal Server Error"})
      }
     
      if (responseJson.errors) {
        const errorMsg = responseJson.errors[0].phone_number || responseJson.errors[0].name || responseJson.errors[0]  ;
        this.setState({ errorMsg });
      }    
      if (responseJson && !responseJson.errors) {
        this.setState({ isContactHost: false, contactSuccess:true , errorMsg:"", title:"", description:"", name:"", phone_num:""});
      } 
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCommunityDataApiCallId != null &&
      this.getCommunityDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
         this.communitySuccessResponse(responseJson)
      }
    }

    if(this.saveEpisodeCatalogueCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForCatalogueSaveEpisode(message);
    }

    if(this.unsaveEpisodeCatalogueCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForCatalogueUnSaveEpisode(message);
    }

    this.validResponse(apiRequestCallId,ApiResponse)

    this.handleResForCommunityPatchAPI(from, message);
    this.handleResForuserSettingsApi(from, message);
    this.handleLikePostRes(from, message);
    this.handleunLikePostRes(from, message);
    this.handleResForChannelData(from, message);
    this.handleResForSavePost(from, message);
    this.handleResForUnSavePost(from, message);
    this.handleResForTurnOffNotificationOfMyPost(from, message);
    this.handleResForDeletePost(from, message);
    this.handleResForHidePost(from, message);
    this.handleResForBlockUserPost(from, message);
    this.handleGetAllReviewsRes(from, message);
    this.handleLikeReviewsRes(from, message);
    this.handleUnLikeReviewsRes(from, message);
    this.handleOtherShowsRes(from, message);
    this.handleFollowOtherShowsRes(from, message);
    this.handleUnFollowOtherShowsRes(from, message)
    this.handleResForSearchMentions(from, message);

    if(this.likeEpisodeCatalogueCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleCatalogueLikeEpisode(message)
    }

    if(this.unlikeEpisodeCatalogueCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleCatalogueunlikeEpisode(message)
    }
    if(this.postReviewApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForRateAndReview(message)
    }

    if(this.addToPlaylistCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleAddToPlayListEpisode(message)
    }
    if(this.removePlaylistCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleRemovePlayListEpisode(message)
    }
    if(this.followPodcastApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleFollowPodacast(message)
    }
    if(this.unfollowPodacastApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleUnfollowPodacast(message)
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSaveEpisodesListApiCallId != null &&
      this.getSaveEpisodesListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.getSaveEpisodeSuccessResponse(responseJson)
        this.getColorSchemeData(responseJson);
        this.getUserReview(responseJson);;
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  onCancelHandler = () => {
    this.setState({
      isContactHost: false,
    });
  }

  onCloseHandler = () => {
    this.setState({
      contactSuccess: false,
    });
  }

  onSendHandler = () => {
    this.setState({
      isContactHost: true,
    });
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  handleImageRender() {
    return this.state.saveEpisodeListData.podcast.data.attributes?.cover_image || podcastImage;
  }

  saveContactHostData = async () => {
    let token = await getStorageData("token",true);
    let podcastId = await getStorageData("podcastId");
    const header = {
      token: token,
      "Content-Type": configJSON.productApiContentType,
    };

    const data = {
      message: {
        podcast_id: podcastId,
        name: this.state.name,
        title: this.state.title,
        description: this.state.description,
        phone_number: this.state.phone_num,
      },
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.contactHostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postContactHost
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 
  calculateStars = (reviewCount: number) => {
    const fullStars = Math.floor(reviewCount);
    const hasHalfStar = reviewCount % 1 !==0 ? true : false;
    const emptyStars  = this.state.totalStars - fullStars - (hasHalfStar ? 1 : 0)
    this.setState({fullStars, hasHalfStar, emptyStars});
  }

  handleFollowButton = async(podcastId : string) => {
      let token = await getStorageData("token");
      const header = {
        "Content-Type": "application/json",
        token: JSON.parse(token),
      };
      const body = {
        data: {
          followable_type: "BxBlockLogin::Podcast", 
          followable_id: podcastId
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followPodcastApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.followPodcastEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.followApiMethodPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnFollowButton = async(itemId : number ) => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unfollowPodacastApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unfollowPodcastEndPoint}/${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.unfollowApiMethodPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  handleLikePost = async (itemId: string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        likeable_type: "BxBlockCommunityforum::Post", 
        likeable_id: Number(itemId)
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.likePostAPICallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.likePostAPIEndPoint}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleUnlikePost(itemId: number) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unlikePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unLikePostAPIEndPoint}/${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleTabChanges = (tab: string) => {
    const newTab = tab;
    this.setState({ 
      textareaValue: "", textareaValueForEdit: {}, 
      textareaFortags: [], openMentionsSearchResultsPopup: false 
    });
    this.setState({selectedTab: newTab, showComments:false}, () => {
      if(this.state.selectedTab === "Community"){
       this.getCommunityPost()
       this.setState({showSingleComment : true, currentCommentId: "",})
      } else if(this.state.selectedTab === "Episodes & Community") {
        this.getCommunityPost()
        this.getSaveEpisodeList();
        this.setState({showSingleComment : true, currentCommentId: "",})
      }
    });
  }
   handleFileUpload = (event:any) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        selectedFile:file
      })
    }
  };

  handleFileUploadForEdit = (event: any, key: number) => {
    let dataObject: NumberFileInterface = { };
    dataObject[key.toString()] = event.target.files[0];

    this.setState({ selectedFileForEdit: {...dataObject} });
  };

  handleRemoveFile = () => {
  this.setState({
    selectedFile:null
  })  };

  handleRemoveFileForEdit = () => {
    this.setState({
      selectedFileForEdit: {},
      selectedFileUrlForEdit: {}
    })
  }

  handleTextareaChange = (event:any) => {
    this.setState({ textareaValue: event.target.value });
    this.fetchMentions(event.target.value);
  };

  handleTextareaChangeForEdit = (key: number, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let dataObject: NumberStringInterface = { };
    dataObject[key.toString()] = event.target.value

    this.setState({ textareaValueForEdit: {...dataObject} });
    this.fetchMentions(event.target.value);
  };
  
  fetchMentions(textName: string) {
    if(textName[textName.length - 1] === '@') {
      this.setState({ currAtIndex: textName.length });
    } else if(this.state.currAtIndex !== -1) {
      if(textName.includes('@')) {
        const getUserName = textName.substring(this.state.currAtIndex, textName.length+1);
        this.setState({ searchMentionName: getUserName });
        this.callMentionsSearchAPI(getUserName);
      } else {
        this.setState({ currAtIndex: -1, openMentionsSearchResultsPopup: false });
      }
    } else {
      this.setState({ currAtIndex: -1, openMentionsSearchResultsPopup: false });
    }
  }

  handleMentionsName(name: string) {
    const isEmpty = Object.keys(this.state.textareaValueForEdit).length === 0;
    this.handleMentionLogic(isEmpty, name);
  }

  handleMentionLogic(isEmpty: boolean, name: string) {
    if(!isEmpty) {
      const key = Object.keys(this.state.textareaValueForEdit)[0];
      let index = this.state.textareaValueForEdit[key]?.indexOf('@');
      let getSubstring = this.state.textareaValueForEdit[key]?.substring(0, index);

      let dataObject: NumberStringInterface = { };
      dataObject[key?.toString()] = `${getSubstring} ${name}`;

      this.setState({
        textareaValueForEdit: {...dataObject},
        textareaFortags: [...this.state.textareaFortags, name]
      }, () => {
        this.setState({ openMentionsSearchResultsPopup: false });
      });
    } else {
      let index = this.state.textareaValue.indexOf('@');
      let getSubstring = this.state.textareaValue.substring(0, index);
      
      this.setState({
        textareaValue: `${getSubstring} ${name}`,
        textareaFortags: [...this.state.textareaFortags, name]
      }, () => {
        this.setState({ openMentionsSearchResultsPopup: false });
      });
    }
  }

  handleCopyChannelUrl() {
    let copyChannelText = document.getElementById("podcastUrl");
    if(copyChannelText) {
      navigator.clipboard.writeText(copyChannelText.innerText);
    }
  }

  handleCopyEmbedUrl() {
    let copyEmbedText = document.getElementById("embedUrl");
    if(copyEmbedText) {
      navigator.clipboard.writeText(copyEmbedText.innerText);
    }
  }

  handleCopyEpisodeUrl() {
    let copyChannelText = document.getElementById("episodeUrl");
    if(copyChannelText) {
      navigator.clipboard.writeText(copyChannelText.innerText);
    }
  }

  handleCopyEpisodeEmbedUrl() {
    let copyEmbedText = document.getElementById("embedEpisodeUrl");
    if(copyEmbedText) {
      navigator.clipboard.writeText(copyEmbedText.innerText);
    }
  }

  handleCopyPostUrl() {
    let copyChannelText = document.getElementById("postUrl");
    if(copyChannelText) {
      navigator.clipboard.writeText(copyChannelText.innerText);
    }
  }

  handleProvideStars(key: number) {
    if(this.state.ratingStars === key+1) {
      this.setState({ ratingStars: key });
    } else {
      this.setState({ ratingStars: key+1 });
    }
  }

  handleVideoPlayPause(key: number) {
    this.videoRefs.forEach((ref: any, idx: any) => {
      if (idx !== key) {
        ref.current?.pause();
      }
    });

    const videoElement = this.videoRefs[key].current;
    let dataObject: any = { };
    dataObject[key.toString()] = key.toString();

    if (videoElement?.paused) {
      videoElement.play();
      this.setState({ currActiveVideo: {...dataObject} });
    } else {
      videoElement?.pause();
      this.setState({ currActiveVideo: {} });
    }

  }

  async callMentionsSearchAPI(userName: string) {
    let tokenData = await getStorageData("token");
    let searchApiUrl = `${configJSON.searchMentionsAPIEndPoint}?query=${userName || ""}`;
    const header = {
      token: JSON.parse(tokenData),
    };

    const searchrequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.residentSearchMentionsResultsApiCallId = searchrequest.messageId;

    searchrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      searchApiUrl
    );
    searchrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    searchrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(searchrequest.id, searchrequest);
  }
  

  getSaveEpisodeList = async () => {
    let token = await getStorageData("token",true);
    let podcastId = await getStorageData("podcastId")
    let myPodcastId = await getStorageData("podcast_id")
    let myChannel = await getStorageData("isShowInMeddile");
    let id = myChannel == "MyChannel" ? myPodcastId : podcastId
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSaveEpisodesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSavedEpisodesEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnLikeEpisode = async(itemId : string) => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unlikeEpisodeCatalogueCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postUnlikeEpisodeEndPoint}/${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeApiMethodDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLikeEpisode = async(itemId : string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        likeable_type: "BxBlockLogin::Episode", 
        likeable_id: itemId
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.likeEpisodeCatalogueCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.postLikeEpisodeEndPoint}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.likeApiMethodPostType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAddtoPlaylist = async(song_id : string) => {
    let token = await getStorageData("token")
    let playListId = await getStorageData("main_playlist_id");
    const header = {
      token: JSON.parse(token),
    };
   
    let formdata = new FormData();
    formdata.append("id", playListId);
    formdata.append("song_id", `${song_id}`);

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.addToPlaylistCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.addToPlayEpisodeEndPoint}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.addToPlayistMethodPostType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRemovetoPlaylist = async(song_id : string) => {
    let token = await getStorageData("token")
    let playListId = await getStorageData("main_playlist_id");
    const header = {
      token: JSON.parse(token),
    };
   
    let formdata = new FormData();
    formdata.append("id", playListId);
    formdata.append("song_id", `${song_id}`);

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.removePlaylistCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.removePlaylistEndPoint}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.removePlaylistMethodDeleteType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSaveForLater = async(id : string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        saveable_type: "BxBlockLogin::Episode", 
        saveable_id: id
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.saveEpisodeCatalogueCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.catalogueSaveEpisode}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.catalogueApiMethodPostType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnSaveForLater = async(id : string) => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unsaveEpisodeCatalogueCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.catalogueUnSaveEpisode}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.catalogueApiMethodDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCommunityPost = async () => {
    let token = await getStorageData("token",true);
    let prodcastId = await getStorageData("podcastId");
    let myPodcastId = await getStorageData("podcast_id")
    let myChannel = await getStorageData("isShowInMeddile");
    let communityId = myChannel == "MyChannel" ? myPodcastId : prodcastId
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommunityDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCommunityEndPoint + communityId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.mentionPopupRef.current && !this.mentionPopupRef.current.contains(event.target as Node)) {
      this.setState({ openMentionsSearchResultsPopup: false });
    }
  }

  setPosition() {
    return (this.state.showComments || this.state.EpisodeComments) ? "relative" : "absolute";
  }

  setPosition1() {
    return (this.state.showComments || this.state.EpisodeComments) ? "-40px" : "75px";
  }

  setPosition2() {
    return (this.state.showComments || this.state.EpisodeComments) ? "120px" : "30px";
  }

  checkIfDisabled1() {
    if(this.state.textareaValue || this.state.selectedFile) {
      return this.getButtonColor();
    } else {
      return "#DADADA"
    }
  }

  checkIfDisabled2() {
    return !this.state.name || !this.state.title || !this.state.description;
  }

  checkIfDisabled3() {
    return this.state.ratingStars === 0 || this.state.userReview?.length !== 0;
  }

  checkIfDisabled4() {
    return this.state.userReview?.length !== 0;
  }

  checkIfDisabled5(key: number) {
    if(this.state.textareaValueForEdit[key] || this.state.selectedFileForEdit[key]) {
      return this.getButtonColor();
    } else {
      return "#DADADA"
    }
  }

  setBorder1(idx: number) {
    return idx === 0 ? "3px solid #FF0807" : "none";
  }

  getPadding1() {
    return this.state.residentChannelInfoData?.length > 0 ? "2rem" : "0rem";
  }

  renderPostImage(item: any) {
    return item.attributes.photos === null ? "" : item.attributes.photos[0]?.photot_link;
  }

  renderBlockOrFlex(item: any) {
    if(item.attributes.photos === null) {
      return "none";
    } else {
      return "flex"
    }
  }

  renderSeeMoreText(description: string, index: number) {
    return this.state.expandSeeMore[index] ? description : description?.substring(0, 120)
  }

  handleSeeMore(key: number) {
    let dataObject: NumberStringInterface = { };
    dataObject[key.toString()] = key.toString();

    this.setState({ expandSeeMore: {...this.state.expandSeeMore, ...dataObject} });
  }

  async getAllReviews() {
    let token = await getStorageData("token", true);
    let podcastId = await getStorageData("podcastId");

    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllReviewsInResidentApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllReviewsEndPoint}/${podcastId}/podcast_reviews`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postRateOrReviewData = async () => {
    let token = await getStorageData("token",true);
    let podcastId = await getStorageData("podcastId");

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    let body = {
      podcast_review: {
        rating: this.state.ratingStars === 0 ? 1 : this.state.ratingStars,
        description: this.state.review
      }
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.postReviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postReviewAPIEndpoint}${podcastId}/podcast_reviews`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForCommunityPatchAPI = async (from: string, message: Message) => {
    if (this.communityPatchAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({ textareaFortags: [], openMentionsSearchResultsPopup: false });
        this.getCommunityPost();
      }
    }
  };

  handleResForuserSettingsApi = async (from: string, message: Message) => {
    if (this.userDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({ userProfileData: responseJson.data });
        this.props.reRenderTheLogo();
      }
    }
  };

  handleResForChannelData(from: string, message: Message) {
    if (this.getHostChannelDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Channel found!") {
        this.setState({ hostData: responseJson.podcast.data });
      }
    }
  }

  getTemplateType(template_type: string) {
    if(template_type === "Audio + Community Focused") {
      this.setState({ selectedTab: "Episodes & Community" });
    } else if(template_type === "Audio Focused") {
      this.setState({ selectedTab: "Episodes" });
    } else if(template_type === "Video Focused") {
      this.setState({ selectedTab: "Episodes" });
    } else if(template_type === "Video + Community Focused") {
      this.setState({ selectedTab: "Episodes & Community" });
    }
  }

  handleResForSavePost(from: string, message: Message) {
    if (this.savePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.meta.message === "Successfully Saved") {
        this.handleMenuCloseForHostUser();
        this.handleMenuCloseForOtherUser();
        this.getCommunityPost();
      }
    }
  }

  handleResForUnSavePost(from: string, message: Message) {
    if (this.unSavePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Successfully Unsaved") {
        this.handleMenuCloseForHostUser();
        this.handleMenuCloseForOtherUser();
        this.getCommunityPost();
      }
    }
  }

  handleResForTurnOffNotificationOfMyPost(from: string, message: Message) {
    if (this.turnOffNotificationOfMyPostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Post notification is turned on" || responseJson.message === "Post notification is turned off") {
        this.handleMenuCloseForHostUser();
        this.handleMenuCloseForOtherUser();
        this.getCommunityPost();
      }
    }
  }

  handleResForDeletePost(from: string, message: Message) {
    if (this.deletePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Post deleted successfully") {
        this.handleMenuCloseForHostUser();
        this.getCommunityPost();
      }
    }
  }

  handleResForHidePost(from: string, message: Message) {
    if (this.hidePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Post is hidden") {
        this.handleMenuCloseForOtherUser();
        this.getCommunityPost();
      }
    }
  }

  handleResForBlockUserPost(from: string, message: Message) {
    if (this.blockUserAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "User has been succesfully blocked") {
        this.handleMenuCloseForOtherUser();
        this.getCommunityPost();
      }
    }
  }

  handleGetAllReviewsRes(from: string, message: Message) {
    if (this.getAllReviewsInResidentApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        this.setState({ allReviewsData: responseJson });
      }
    }
  }

  handleLikeReviewsRes(from: string, message: Message) {
    if (this.likeReviewAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.getAllReviews();
      }
    }
  }

  handleUnLikeReviewsRes(from: string, message: Message) {
    if (this.unLikeReviewAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Successfully destroy") {
        this.getAllReviews();
      }
    }
  }

  handleOtherShowsRes(from: string, message: Message) {
    if (this.otherShowsInResidentApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.data) {
        this.setState({ residentChannelInfoData: responseJson.data })
      }
    }
  } 

  handleFollowOtherShowsRes(from: string, message: Message) {
    if (this.followOtherPodcastApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.meta.message === "Successfully Followed") {
        this.getOtherShowsAPICall();
      }
    }
  }

  handleUnFollowOtherShowsRes(from: string, message: Message) {
    if (this.unFollowOtherPodcastApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Successfully Unfollowed") {
        this.getOtherShowsAPICall();
      }
    }
  }

  handleResForSearchMentions = async (from: string, message: Message) => {
    if (this.residentSearchMentionsResultsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(response) {
        this.setState({ openMentionsSearchResultsPopup: true, searchMentionsdata: response });
      }
    }
  };

  handleLikePostRes(from: string, message: Message) {
    if (this.likePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.getCommunityPost();
      }
    }
  }

  handleunLikePostRes(from: string, message: Message) {
    if (this.unlikePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Successfully destroy") {
        this.getCommunityPost();
      }
    }
  }

  communitySuccessResponse =(communityDataList: Post)=>{
    this.setState({communityListData : communityDataList.data, originalCommunityListData: communityDataList.data})
    this.setState({ 
      openEditPost: {}, selectedFileForEdit: {},
      selectedFileUrlForEdit: {}, textareaValueForEdit: {}
    });
    this.handleSetCommunityItem();
  }

  getSaveEpisodeSuccessResponse = (episodedata : PodcastData)=>{
   this.setState({saveEpisodeListData : episodedata});
   this.setState({ saveVideoEpisodeListData: episodedata },
    () => {
      this.videoRefs = this.state.saveVideoEpisodeListData.podcast.data.attributes.episodes.data.map(() => createRef());
    }
   );
  }

  getColorSchemeData(response: any) {
    this.setState({colorSchemeEpisodeData: response.podcast.data.attributes.color_scheme.data})
    this.getTemplateData();
    this.getTemplateType(response.podcast.data.attributes.color_scheme.data.attributes.template_type)
  }

  getUserReview(response: any) {
    this.setState({ userReview: response.podcast.data.attributes.user_review });
    if(response.podcast.data.attributes.user_review.length !== 0) {
      this.setState({
        review: response.podcast.data.attributes.user_review[0].description, 
        ratingStars: response.podcast.data.attributes.user_review[0].rating
      });
    }
  }

  getTemplateData() {
    this.setState({ templateType: this.state.colorSchemeEpisodeData.attributes?.template_type })
  }

  getBackgroundColor() {
    return this.state.colorSchemeEpisodeData?.attributes?.bg_color;
  }

  getSecondaryColor() {
    return this.state.colorSchemeEpisodeData?.attributes?.secondry_color;
  }

  getTextColor() {
    return this.state.colorSchemeEpisodeData?.attributes?.text_color;
  }

  getButtonColor() {
    return this.state.colorSchemeEpisodeData?.attributes?.button_color;
  }

  async getOtherShowsAPICall() {
    let podcastId = await getStorageData("podcastId");
    let token = await getStorageData("token");
    const headerData = {
      token: JSON.parse(token)
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.otherShowsInResidentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOtherShowsAPIEndPoint}/${podcastId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  userDetails = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async getChannelData() {
    const token = await getStorageData('token');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHostChannelDataApiCallId = requestMessage?.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEpisodeDataEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: JSON.parse(token)
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePostName() {
    let name = this.state.userProfileData.attributes.first_name +" "+ this.state.userProfileData.attributes.last_name; 
    let loggedInUserEmail = this.state.userProfileData.attributes.email;
    let currPodCastUsersEmail = this.state.saveEpisodeListData.podcast.data.attributes.hosts[0].email

    if(loggedInUserEmail === currPodCastUsersEmail) {
      name = this.state.saveEpisodeListData.podcast.data.attributes.name;
    }
    return name;
  }

  refactorPostText(text: string, tags: string[]) {
    let words = text.split(/\b/);

    tags.forEach(tag => {
      tag.split(" ").forEach(nameWord => {
        words = words.map(word => (word === nameWord ? `@${word}` : word));
      });
    });

    return words.join("");
  }

  addAtToText(key: number) {
    const dataObject = this.state.originalCommunityListData[key];
    let body = dataObject?.attributes.body;
    let text = this.state.textareaValueForEdit[key];

    let bodyWords = body?.split(" ");
    let textWords = text?.split(" ");

    bodyWords?.forEach(word => {
    if (word?.startsWith('@')) {
      let wordWithoutAt = word?.substring(1);
      let indexInText = textWords?.indexOf(wordWithoutAt);
      
      if (indexInText !== -1) {
        textWords[indexInText] = "@" + wordWithoutAt;
      }
    }});
    
    text = textWords?.join(" ");
    return text;
  }

  handleSend = async () => {
    let token = await getStorageData("token",true);
    let prodcastId = await getStorageData("podcastId");

    let name = this.handlePostName();
    name = this.state.userProfileData.attributes.first_name +" "+ this.state.userProfileData.attributes.last_name; 

    let text = this.refactorPostText(this.state.textareaValue, this.state.textareaFortags);

    const header = {
      token: token,
    };

    let formdata = new FormData();
    formdata.append("data[podcast_id]",prodcastId)
    formdata.append("data[body]", text);
    formdata.append("data[name]", name);
    if(this.state.selectedFile) {
      formdata.append("data[photos][]", this.state.selectedFile);
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.communityPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCommunityEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async handleSendForEditPost(key: number) {
    let token = await getStorageData("token",true);
    let podcastId = await getStorageData("podcastId");
    let postId = await getStorageData("currEditPostId");

    let textWithAt = this.addAtToText(key);

    let name = this.state.userProfileData.attributes.first_name +" "+ this.state.userProfileData.attributes.last_name;
    let text = this.refactorPostText(textWithAt, this.state.textareaFortags);

    const header = {
      token: token,
    };

    let formdata = new FormData();
    formdata.append("data[podcast_id]",podcastId);
    formdata.append("data[name]", name);
    formdata.append("data[body]", text);
    if(this.state.selectedFileForEdit[key]) {
      formdata.append("data[photos][]", this.state.selectedFileForEdit[key]);
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.communityPatchAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.patchCommunityAPIEndPoint}/${postId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  async handleSavePostDropDown(itemId: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        saveable_type: "BxBlockCommunityforum::Post", 
        saveable_id: Number(itemId)
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.savePostAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleUnSavePostDropDown(itemId: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unSavePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unSavePostAPIEndPoint}/${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleEditMyPost(item: Post, key: number) {
    let dataObject: NumberStringInterface = { };
    dataObject[key.toString()] = key.toString();

    this.setState({ openEditPost: { ...dataObject} });
    
    let body = item.attributes.body;
    let dataObjectText: NumberStringInterface = { };
    let dataObjectFile: NumberStringInterface = { };
    dataObjectText[key.toString()] = body.replace(/@/g, '');
    dataObjectFile[key.toString()] = this.renderPostImage(item);

    this.setState({
      textareaValueForEdit: {...this.state.textareaValueForEdit, ...dataObjectText},
      selectedFileUrlForEdit: {...this.state.selectedFileUrlForEdit, ...dataObjectFile},
    });
    
    await setStorageData("currEditPostId", item.id);

    const remaningPost = this.state.originalCommunityListData.filter((post: Post) => post.id !== item.id)
    this.setState({ 
      communityListData: remaningPost, openMentionsSearchResultsPopup: false, 
      textareaValue: "", textareaFortags: [] 
    });

    this.handleMenuCloseForHostUser();
  }

  async handleTurnOffNotificationOfMyPost(itemId: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.turnOffNotificationOfMyPostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.turnOffNotificationOfMyPostAPIEndPoint}/${itemId}/toggle_notification`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleDeletePost(id: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deletePostAPIEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleHidePost(id: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hidePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hidePostAPIEndPoint}/${id}/hide_post`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleBlockUser(id: string) {
    let token = await getStorageData("token");
    const headerData = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      "blocked_id": Number(id)
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockUserAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blockUserAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleLikeReview(id: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        likeable_id: Number(id),
        likeable_type: "BxBlockReviews::PodcastReview"
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likeReviewAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.likeReviewAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }  
  

  async handleUnLikeReview(liked_id: number) {
    let token = await getStorageData("token");
    const headerData = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unLikeReviewAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unLikeReviewAPIEndPoint}/${liked_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleFollowOtherPodcast(id: string) {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
      "Content-Type": "application/json",
    };
    const bodyData = {
      data: {
        followable_type: "BxBlockLogin::Podcast", 
        followable_id: id
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followOtherPodcastApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.followOtherPodcastAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleUnfollowOtherPodcast(id: number) {
    let token = await getStorageData("token");
    const headerData = {
      token: JSON.parse(token),
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.unFollowOtherPodcastApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unfollowOtherPodcastAPIEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  renderHostResident(roles: any) {
    const allRoles = roles?.map((role: any) => role.name.charAt(0).toUpperCase() + role.name.slice(1).toLowerCase());

    let combinedRoles = "";
    if(allRoles) {
      if(allRoles.length === 1) {
        combinedRoles += allRoles[0];
      } else if(allRoles.length === 2) {
        combinedRoles += allRoles[0] + " + " + allRoles[1];
      }
    }

    return combinedRoles;
  }

  renderPinnedBorder(post: any) {
    return post.pinned ? "5px solid #EC2025" : "none";
  }

  checkBorderRadius1() {
    return (this.state.selectedTab === 'Episodes' || this.state.selectedTab === 'Episodes & Community') ? '9px 9px 0px 0px' : '9px';
  }

  checkBorderRadius2() {
    return (this.state.selectedTab === 'Community' ? '9px 9px 0px 0px' : '9px')
  }

  checkBorderRadius3() {
    return (this.state.selectedTab === 'ChannelInfo' ? '9px 9px 0px 0px' : '9px')
  }

  checkHeight1() {
    return (this.state.selectedTab === 'Episodes' || this.state.selectedTab === 'Episodes & Community') ? '53px' : '40px';
  }

  checkHeight2() {
    let calculatedHeight;
    if (this.state.selectedTab === 'Community') {
      calculatedHeight = "53px";
    } else {
      calculatedHeight = "40px";
    }
    return calculatedHeight;
  }

  checkHeight3() {
    return (this.state.selectedTab === 'ChannelInfo' ? '53px' : '40px')
  }

  checkHeight4() {
    return this.state.selectedTab === "Episodes & Community" ? "98rem" : "65rem";
  }

  backgroundColor1(index: number) {
    return index%2 !== 0 ? "#292524" : "#44403C";
  }

  handleOpenShareChannel = () => {
   this.setState({open : true})
  }

  handleOpenSharePostModal(id: string) {
    this.setState({ openSharePostDialog: true, currPostId: id });
  }

  handleOpenChannelPlansInfoDialog() {
    this.setState({ openChannelInfoPlansDialog: true });
  }

  handleOpenSupportHostDialog() {
    this.setState({ openSupportHostDialog: true });
  }

  handleOpenEpisodeShareDialog = (id: string) => {
    this.setState({ openShareEpisodePopup: true, currEpisodeId: id });
  }

  handleCloseSharePostModal = () => {
    this.setState({ openSharePostDialog: false });
  }

  handleCloseShareChannel = () => {
    this.setState({open : false})
  }

  handleCloseSupportHostDialog = () => {
    this.setState({ openSupportHostDialog: false });
  }

  handleCloseEpisodeShareDialog = () => {
    this.setState({ openShareEpisodePopup: false });
  }
  
  handleOpenRateOrReview = () => {
    this.setState({openRateOrReview : true, })
    this.getSaveEpisodeList();
  }
 
  handleCloseRateOrReview = () => {
    this.setState({openRateOrReview : false, ratingStars: 0, review: ""})
  }

  handleCloseChannelPlansInfoDialog = () => {
    this.setState({ openChannelInfoPlansDialog: false,});
  }
  
  showCommentBox = (event:React.MouseEvent<HTMLDivElement> | number) => {
    if(typeof event !== "number"){
      const commentId = Number(event.currentTarget?.getAttribute('data-id'));
      this.setState({ activeCommentId: commentId });
    }   
  };

  handleMentionBoxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  }
  
  handleClick = (event:any, id:string) => {
    this.setState({ anchorEl: event.currentTarget, openPopup: !this.state.openPopup, activeCommentId: id });
  };
  
  handleClose = () => {
    this.setState({ anchorEl: null, openPopup: !this.state.openPopup });
  };

  handleOpenDropDownForHostUser = (event: any, id: string, key: number) => {
    this.setState({openDropDown: event?.currentTarget, currPostId: id, openDropDownForOtherPost: event?.currentTarget});
    if(this.state.userProfileData.attributes?.email === this.state.communityListData[key]?.attributes.account.attributes.email) {
      this.setState({ openDropdownForMyOrOthersPost: "My Post" });
    } else {
      this.setState({ openDropdownForMyOrOthersPost: "Others Post" });
    }
  }

  async openAllReviewsScreen() {
    this.setState({ openReviewsScreen: true });
    await this.getAllReviews();
  }

  goBackfromReviewsScreen() {
    this.setState({ openReviewsScreen: false });
  }

  openMenu(item: any) {
    return Boolean(this.state.openDropDown) && item.id === this.state.currPostId
  }

  openMenuForOthersPost(item: any) {
    return Boolean(this.state.openDropDownForOtherPost) && item.id === this.state.currPostId
  }

  openSharePost(item: any) {
    return this.state.openSharePostDialog && item.id === this.state.currPostId
  }

  openShareEpisode(episodeId: string) {
    return this.state.openShareEpisodePopup && this.state.currEpisodeId === episodeId;
  }

  handleMenuCloseForHostUser = () => {
    this.setState({ openDropDown: null});
  };

  handleMenuCloseForOtherUser = () => {
    this.setState({ openDropDownForOtherPost: null});
  };

  handleOpenSubscribePlansDialog() {
    this.setState({ openSubscribePlansDialog: true });
  }

  handleCloseSubscribePlansInfoDialog = () => {
    this.setState({ openSubscribePlansDialog: false,});
  }

  handleNewTierSuccessPopup() {
    if(this.state.isCardDetailsNeededForSubscription) {
      this.setState({ openPaymentsDetailsPopup: true });
    } else {
      this.setState({ openNewTierSuccessPopup: true });
    }
  }

  handleCloseNewTierSuccessPopup = () => {
    this.setState({ openSubscribePlansDialog: false, openNewTierSuccessPopup: false, openPaymentsDetailsPopup: false });
  }

  handleCardSetUp() {
    this.setState({ isCardDetailsNeededForSubscription: true }, () => {
      this.setState({ openNewTierSuccessPopup: true });
    });
  }

  handleClosePaymentsDetailsPopup = () => {
    this.setState({ openPaymentsDetailsPopup: false, openSubscribePlansDialog: false, });
  }

  goToDashBoard() {
    this.props.handleCommonPath("Dashboard");
  }

  goToChannel = () => {
    if(this.state.isCardDetailsNeededForSubscription) {
      this.setState({
        openSubscribePlansDialog: false, openPaymentsDetailsPopup: false, 
        openNewTierSuccessPopup: false, isCardDetailsNeededForSubscription: false
      });
    } else {
      this.setState({ openSubscribePlansDialog: false, openNewTierSuccessPopup: false });
    }
  }

  apiCall = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body, type } = data;

    let token = await getStorageData("token");

    let header = {
      token: JSON.parse(token),
      'Content-Type': contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type == 'formData' ? body : JSON.stringify(body),

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  validResponse = async (apiRequestCallId: string, responseJson: PostCommentsSuccessResponse & {message:string}) => {
    if (this.commentOnPostApiCallId === apiRequestCallId) {
      this.handleReplySuccessCallBack();
    }

    if (this.getPostCommentsApiCallId == apiRequestCallId) {
      this.handleGetPostCommentSuccessCallBack(responseJson);
    }

    if(this.getNestedCommentsApiCallId == apiRequestCallId){
      this.handleGetNestedCommentSuccessCallBack(responseJson)
    }

    if (this.addCommentLikeForHostApiCallId == apiRequestCallId) {
      this.handleAddCommentLikeForCatalogSuccessCallBack(responseJson)
    }

    if (this.removeCommentLikeFromCatalogApiCallId == apiRequestCallId) {
      this.handleRemoveCommentForCatalogLikeSuccessCallBack(responseJson)
    }
  };

  handleSetCommunityItem = () => {
    let communityItem = this.state.communityListData[this.state.communityItemIndex];
    this.setState({communityItem});    
  }

  showComments = (id: string, index: number) => {
    this.setState({ textareaValue: "", textareaValueForEdit: {}, textareaFortags: [] });
    let communityItem = this.state.communityListData[index];
    if (this.state.currentCommentId == "" || this.state.currentCommentId !== id) {
      this.setState({
        currentCommentId: id,
        postComments: [],
        showComments: !this.state.showComments,
        showSingleComment: !this.state.showSingleComment,
        communityItemIndex: index,
        communityItem
      });
      this.handleGetPostComment(id);
    } if (this.state.currentCommentId == id) {
      this.setState({
        currentCommentId: "",
        postComments: [],
        showComments: !this.state.showComments,
        showSingleComment: !this.state.showSingleComment,
        communityItem: {} as Post,
        communityItemIndex: 0,
        nestedCommentId: ""
      });
    }
  };

  handleReply = async (parentId: string, commentType: string) => {
    let text = this.refactorPostText(this.state.textareaValue, this.state.textareaFortags);
    let comment = {
      comment: {
        commentable_id: parentId,
        commentable_type: commentType == "post" ? "BxBlockCommunityforum::Post" : commentType == "episode" ? "BxBlockLogin::Episode" : "BxBlockComments::Comment",
        comment: text
      }
    };
    this.commentOnPostApiCallId = await this.apiCall({ 
      contentType: configJSON.productApiContentType, 
      method: configJSON.likeApiMethodPostType, 
      endPoint: configJSON.postComments, 
      body: comment
     });
  };

  handleReplySuccessCallBack = async () => {
    this.setState({ textareaValue: "" },()=>{
      this.state.commentType !== "nestedComment" && this.handleGetPostComment(this.state.currentCommentId);
      this.handleGetNestedComment(this.state.nestedCommentId);
      this.showEpisodeComments(true,this.state.parentId);
      this.getSaveEpisodeList();
    });
  };

  handleGetPostComment = async (id: string) => {
    this.getPostCommentsApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getPostComments}${id}`,
    });
  };

  handleGetPostCommentSuccessCallBack = async (responseJson: PostCommentsSuccessResponse) => {
    if (responseJson.data) {
      const reversedComments = [...responseJson.data].reverse();
      this.setState({ postComments: reversedComments });
    };
  };

  showNestedComments = (id: string, comment: PostCommentObject, commentType: string) => {
    this.setState({ textareaValue: "", textareaValueForEdit: {}, textareaFortags: [] });
    if (this.state.nestedCommentId == "" || this.state.nestedCommentId !== id) {
      this.setState({ nestedCommentId: id, nestedComments: [] });
      this.handleGetNestedComment(id);
    };
    if (this.state.nestedCommentId == id) {
      this.setState({ nestedCommentId: "", nestedComments: [] });
    };
    this.nestedComment(id, comment, commentType)
  };

  nestedComment = (id: string, comment: PostCommentObject, commentType: string) => {
    if (commentType == "nestedComment") {
      this.setState({ nestedCommentId: id, postComments: [comment], commentType });
      this.handleGetNestedComment(id);
    }
  };

  handleGetNestedComment = async (id: string) => {
    this.getNestedCommentsApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getNestedComments}${id}`,
    });
  };

  handleGetNestedCommentSuccessCallBack = async (responseJson: PostCommentsSuccessResponse) => {
    if (responseJson.data) {
      const reversedComments = [...responseJson.data].reverse();
      this.setState({ nestedComments: reversedComments });
    };
  };

  handleGoBackComment = () => {
    this.setState({ nestedComments: [], commentType: "", nestedCommentId: "" })
    this.handleGetPostComment(this.state.currentCommentId);
  };

  handleLike = (id: string, isLike: boolean,likedId:string) => {
    isLike ? this.handleRemoveCommentForCatalogLike(likedId) : this.handleAddCommentForCatalogLike(id)
  }

  handleAddCommentForCatalogLike = async (id: string) => {
    let comment = {
      data: {
        likeable_type: "BxBlockComments::Comment",
        likeable_id: id
      }
    };
    this.addCommentLikeForHostApiCallId = await this.apiCall({
      method: configJSON.likeApiMethodPostType,
      endPoint: configJSON.addLikeOnComment,
      contentType: configJSON.productApiContentType,
      body: comment
    });
  };

  handleAddCommentLikeForCatalogSuccessCallBack = async (responseJson: PostCommentsSuccessResponse) => {
    if (responseJson.data) {
      this.handleGetPostComment(this.state.currentCommentId);
      this.handleGetNestedComment(this.state.nestedCommentId);
      this.showEpisodeComments(true,this.state.parentId);
    };
  };

  handleRemoveCommentForCatalogLike = async (id: string) => {
    this.removeCommentLikeFromCatalogApiCallId = await this.apiCall({
      method: configJSON.catalogueApiMethodDeleteType,
      contentType: configJSON.productApiContentType,
      endPoint: `${configJSON.removeLike}${id}`
    });
  };

  handleRemoveCommentForCatalogLikeSuccessCallBack = async (responseJson: { message: string }) => {
    if (responseJson.message) {
      this.handleGetPostComment(this.state.currentCommentId);
      this.handleGetNestedComment(this.state.nestedCommentId);
      this.showEpisodeComments(true,this.state.parentId);
    };
  };

  showEpisodeComments = async(value: boolean, id: string) => {
    this.setState({ EpisodeComments: value, parentId: id });
    this.setState({ textareaValue: "", textareaValueForEdit: {}, textareaFortags: [] });
    
    this.getPostCommentsApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getPostCommentsEpisode}${id}`,
    });
  };

  subCategoryApiTwo = async () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessageID = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastSubCategoryApiCallId = requestMessageID.messageId;
    requestMessageID.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageID.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageID.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    runEngine.sendMessage(requestMessageID.id, requestMessageID);
  };

  subCategoryApiThree = async () => {
    const mesagerequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastSubCategoryApiCallId = mesagerequest.messageId;
    mesagerequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    mesagerequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.loginApiContentType,
      })
    );
    mesagerequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    runEngine.sendMessage(mesagerequest.id, mesagerequest);
  };

  categoryApiTwo = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const podcastSubCategoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.podcastSubCategoryApiCallId = podcastSubCategoryMessage.messageId;
    podcastSubCategoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    podcastSubCategoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    podcastSubCategoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(podcastSubCategoryMessage.id, podcastSubCategoryMessage);
  };

  categoryApiThree = () => {
    const subCategoryApiThreeMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.podcastSubCategoryApiCallId = subCategoryApiThreeMessage.messageId;
    subCategoryApiThreeMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.loginApiContentType,
      })
    );
    subCategoryApiThreeMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    subCategoryApiThreeMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(subCategoryApiThreeMessage.id, subCategoryApiThreeMessage);
  };
  // Customizable Area End
}
