import React from "react";

// Customizable Area Start
import { Box, Button, Typography, styled } from "@mui/material";
import { engageBackImg, engageItem1, engageItem2, engageItem3 } from "./assets";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";

import EngageController, {
    Props,
} from "./EngageController";

export default class Engage extends EngageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box style={{ overflowY: "auto", maxHeight: "100%" }}>
                <StyledWrapper>
                    <img
                        src={engageBackImg} alt="backgroundImg"
                        width={"100%"} style={{ objectFit: "contain", borderRadius: "8px" }}
                    />
                    <Box bgcolor={"#44403C"} pb={"3rem"}>
                        <Typography style={{
                            fontSize: "30px", fontWeight: 900, fontFamily: "Nexa-Heavy",
                            padding: "32px 0px", color: "#FFFFFF", textAlign: "center"
                        }}>
                            Engage
                        </Typography>
                        <Box style={{
                            marginBottom: "16px", display: "flex", gap: "20px",
                            alignItems: "center", justifyContent: "center",
                        }}>
                            <Box style={{
                                width: "300px", height: "299px", flexDirection: "column", display: "flex",
                                alignItems: "center", background: "#312F2D", borderRadius: "16px",
                                justifyContent: "space-between", paddingBottom: "15px",
                            }}>
                                <img
                                    style={{
                                        width: "300px", maxWidth: "100%", height: "223px",
                                        borderRadius: "16px 16px 0px 0px",
                                    }}
                                    src={engageItem1}
                                />
                                <Button style={{
                                    width: "268px", height: "36px", textTransform: "none",
                                    background: "#FF0807", color: "#FFFFFF", borderRadius: "8px",
                                    fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "16px"
                                }}
                                    data-test-id="navigateToHostTestBtn"
                                    onClick={this.navigateToHostChannel}
                                >
                                    Community Management
                                </Button>
                            </Box>
                            <Box style={{
                                width: "300px", height: "299px", borderRadius: "16px", display: "flex",
                                justifyContent: "space-between", paddingBottom: "15px",
                                alignItems: "center", flexDirection: "column", background: "#312F2D"
                            }}>
                                <img
                                    src={engageItem2}
                                    style={{
                                        maxWidth: "100%", height: "223px", width: "300px",
                                        borderRadius: "16px 16px 0px 0px",
                                    }}
                                />
                                <Button style={{
                                    fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "16px",
                                    width: "268px", height: "36px", textTransform: "none",
                                    background: "#FF0807", color: "#FFFFFF", borderRadius: "8px",
                                }}
                                    data-test-id="navigateToAnalyticsTestBtn"
                                    onClick={this.navigateToAnalytics}
                                >
                                    Analytics
                                </Button>
                            </Box>
                            <Box style={{
                                justifyContent: "space-between", paddingBottom: "15px",
                                width: "300px", height: "299px", display: "flex", background: "#312F2D",
                                alignItems: "center", flexDirection: "column", borderRadius: "16px",
                            }}>
                                <img
                                    style={{
                                        borderRadius: "16px 16px 0px 0px",
                                        width: "300px", maxWidth: "100%", height: "223px",
                                    }}
                                    src={engageItem3}
                                />
                                <Button style={{
                                    background: "#FF0807", color: "#FFFFFF", borderRadius: "8px",
                                    width: "268px", height: "36px", textTransform: "none",
                                    fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "16px"
                                }}
                                    data-test-id="navigateToContestTestBtn"
                                >
                                    Contest Management
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </StyledWrapper>
                <Footer navigation={this.props.navigation} id={"1"} />
            </Box>
        )
    }
}


const StyledWrapper = styled(Box)({
    padding: "20px 50px",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
})

// Customizable Area End