import React from "react";
// Customizable Area Start
import { styled, Box, Typography, Button, IconButton, Card, CardContent, CardMedia, Link, Grid,TextField , Avatar, Dialog, Modal, ListItemIcon, ListItemText, List, ListItem, InputAdornment, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Star, StarBorder, Phone, InfoOutlined, AttachMoney, Laptop, ShoppingCartOutlined, LocalOfferOutlined, MenuBook, ThumbUpOffAlt, ThumbUp, PushPinOutlined,ChatBubbleOutline, Share, FavoriteBorder, PlaylistAdd, PlayCircleFilled, Favorite ,PlaylistAddCheck } from '@mui/icons-material';
import { podcastImage, tshirtIcon, recommended_episode_1, recommended_episode_2, recommended_episode_3, ad_1, ad_2 , messageLogo,imageEyeoff,imageBlock,imageEdit,imageDelete, goBack} from "./assets";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CatalogueController , { Props, PostCommentObject ,configJSON, EpisodeData, HostsData, Post , SubCategoryData } from "./CatalogueController";
import Divider from '@mui/material/Divider'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import MenuItem from '@mui/material/MenuItem';
import moment from "moment";
import Rating from '@mui/material/Rating';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { leftArrow } from "../../../blocks/dashboard/src/assets";

// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderPodcastCard = () => {
   const { saveEpisodeListData: { podcast: { data: { attributes } } } } = this.state;
   return (
    <>
    {attributes.episodes.data.length > 0 && attributes.episodes.data.map((episode : EpisodeData, index:number) => (
      <>
      <Card style={{backgroundColor: `${this.getSecondaryColor()}`}} className="podcast-card">
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={episode?.attributes?.cover}
        alt="Podcast episode"
        className="podcast-media"
      />
      <Box className="podcast-inner-box">
         <CardContent className="podcast-inner-box-content" >
          <Typography style={{ color: `${this.getTextColor()}` }} className="podcast-inner">
            {episode?.attributes?.name}
          </Typography>
          <Typography style={{ color: `${this.getTextColor()}` }} className="podcast-inner">
            {attributes?.name}
          </Typography>
          <Typography sx={{ marginTop: '10px', color: `${this.getTextColor()}` }} className="podcast-inner">
           {episode?.attributes?.episode_number}
          </Typography>
          <Typography variant="body2" sx={{ color: `${this.getTextColor()}`, marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
            {this.renderSeeMoreText(episode.attributes.description, index)}
            {this.renderSeeMoreLink(episode.attributes.description, index)}
          </Typography>
        </CardContent>
        <Box className="podcast-action-buttons">
          {episode?.attributes.liked_episode?.liked ? (
            <IconButton data-test-id = {`likeTestId${index}`} onClick={() => this.handleUnLikeEpisode(episode?.attributes?.liked_episode?.liked_id)} aria-label="like" sx={{ color: 'white' }}>
             <ThumbUp sx={{color: `${this.getTextColor()}`}} /> 
          </IconButton>
          ) : (
            <IconButton data-test-id = {`unlikeTestId${index}`} onClick={() => this.handleLikeEpisode(episode?.id)} aria-label="like" sx={{ color: 'white' }}>
             <ThumbUpOffAlt sx={{color: `${this.getTextColor()}`}} /> 
          </IconButton>
          )}
          <Typography sx={{ color: `${this.getTextColor()}`, marginRight: '15px' }} className="podcast-inner">
            {episode?.attributes?.liked_episode?.count}
          </Typography>
          <IconButton aria-label="comment" data-test-id = {`episodeCommentVideo`}  sx={{ color: 'white' }} onClick={() => this.showEpisodeComments(!this.state.EpisodeComments,episode?.id)} >
            <ChatBubbleOutline sx={{color: `${this.getTextColor()}`}} />
          </IconButton>
          <Typography sx={{ color: `${this.getTextColor()}`, marginRight: '15px' }} className="podcast-inner">
            {episode?.attributes?.comments?.data?.length}
          </Typography>
          <IconButton onClick={() => this.handleOpenEpisodeShareDialog(episode.id)} data-test-id="shareEpisodeFromAudioTestBtn" aria-label="share" sx={{ color: '#FFFFFF' }}>
            <Share sx={{color: `${this.getTextColor()}`}} />
          </IconButton>
          {this.renderShareEpisode(episode)}
          <Typography sx={{ color: `${this.getTextColor()}` }} className="podcast-inner">
            {episode?.attributes?.share_episode_count}
          </Typography>
        </Box>
      </Box>
      <Box className="podcast-saving-options">
        {!episode.attributes.save_episode.saved ? (
         <Button data-test-id = {`saveLaterTestId${index}`} style={{background: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`}} onClick={() => this.handleSaveForLater(episode?.id)} variant="outlined" className="podcast-save-button" startIcon={<FavoriteBorder/>}>
         <Typography
           style={{
             fontWeight: 900,
             fontSize: '10px',
             lineHeight: '26px',
             whiteSpace: 'nowrap',
             fontFamily: 'Nexa-Heavy',
           }}
         >
           Save For Later
         </Typography>
       </Button>
        ) : (
          <Button style={{backgroundColor: "#030303", borderColor:"#000000"}} data-test-id = {`unsaveLaterTestId${index}`} onClick={() => this.handleUnSaveForLater(episode?.attributes.save_episode.saved_id)} variant="outlined" className="podcast-save-button" startIcon={<Favorite sx={{fill : "#FF0807"}}/>}>
         <Typography
           style={{
             fontWeight: 900,
             fontSize: '10px',
             lineHeight: '26px',
             whiteSpace: 'nowrap',
             fontFamily: 'Nexa-Heavy',
           }}
         >
           Saved
         </Typography>
       </Button>
        )}
        {!episode.attributes.added.song_added ? (
          <Button data-test-id = {`addToPlaylistTestId${index}`}  onClick = {() => this.handleAddtoPlaylist(episode?.id)} variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd />} style={{background: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`}}>
          <Typography
            style={{
              fontWeight: 900,
              fontSize: '10px',
              lineHeight: '26px',
              whiteSpace: 'nowrap',
              fontFamily: 'Nexa-Heavy',
            }}
          >
            Add to Playlist
          </Typography>
        </Button>
        ) : (
          <Button data-test-id = {`removeToPlaylistTestId${index}`} onClick = {() => this.handleRemovetoPlaylist(episode?.id)}  variant="outlined" className="podcast-save-button" startIcon={<PlaylistAddCheck />} style={{background: "#030303", borderColor: "#030303"}}>
          <Typography
            style={{
              fontWeight: 900,
              fontSize: '10px',
              lineHeight: '26px',
              whiteSpace: 'nowrap',
              fontFamily: 'Nexa-Heavy',
            }}
          >
            Added
          </Typography>
        </Button>
        )}
        
      </Box>
      <Box className="play-icon">
        <PlayCircleFilled data-test-id = {`play-episode-testID${index}`}  style={{ marginBottom: '10px', fontSize: '40px', color: `${this.getButtonColor()}` }} onClick = {() => this.props.handleAddAudio("BxBlockLogin::Episode", episode.id)}  />
      </Box>
      </Card>
      {this.state.EpisodeComments && episode.id == this.state.parentId && this.renderEpisodeCommunityPost()}
      </>
    ))} 
    </>
  );
  } 

  renderPodcastCardForVideo() {
    const { saveVideoEpisodeListData: { podcast: { data: { attributes } } } } = this.state;
    return (
      <>
        {attributes.episodes.data.length > 0 && attributes.episodes.data.map((episode : EpisodeData, key: number) => (
          <>
          <Card style={{ padding:"40px 40px 20px 40px", backgroundColor: `${this.getSecondaryColor()}`}} className="podcast-card">
            <Avatar
              src= {episode.attributes?.cover}
              alt="Host Avatar"
              sx={{ width: "56px", height: "56px" }}
            />
            <Box className="podcast-inner-box" style={{ gap:"8px" }}>
              <Box style={{display:"flex", justifyContent:"space-between"}}>
                <Box style={{display:"flex", gap:"5px", flexDirection:"column"}}>
                  <Typography style={{ color: `${this.getTextColor()}`, width:"fit-content", fontSize:"14px", fontWeight:900 }} className="podcast-inner">
                    {episode.attributes.name}
                  </Typography>
                  <Typography style={{ color: `${this.getTextColor()}`, width:"fit-content", fontSize:"10px", fontWeight:100, fontFamily: 'Nexa-Regular' }}>
                    {episode.attributes.publish_date.publish_date}
                  </Typography>
                  <Typography sx={{ color: `${this.getTextColor()}`, width:"fit-content", fontSize:"20px", fontWeight:900 }} className="podcast-inner">
                    {`- EP.${episode.attributes.episode_number}: ${attributes.name}`}
                  </Typography>
                  <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: 'Nexa-Regular', width:"fit-content", fontSize:"14px", fontWeight:100 }}>
                    {episode.attributes.description}
                  </Typography>
                </Box>
                <Box style={{display:"flex", flexDirection:"column", gap:"10px", marginRight:"20px" }}>
                  {this.renderSavedVideoEpisodes(episode)}
                  {!episode.attributes.added.song_added ? (
                    <Button 
                      data-test-id="addToPlaylistVideoTempTestBtn" 
                      onClick = {() => this.handleAddtoPlaylist(episode.id)} 
                      startIcon={<PlaylistAdd />} 
                      style={{
                        width:"130px", height:"26px", borderRadius:"8px",
                        background: `${this.getButtonColor()}`, textTransform:"none",
                        borderColor: `${this.getButtonColor()}`, color:"#FFFFFF",
                        fontWeight: 900, fontSize: '10px', fontFamily: 'Nexa-Heavy',  
                      }}
                    >
                      Add to Playlist
                    </Button>
                  ) : (
                    <Button 
                      data-test-id="removeToPlaylistVideoTempTestBtn" 
                      onClick = {() => this.handleRemovetoPlaylist(episode.id)}
                      startIcon={<PlaylistAddCheck />} 
                      style={{
                        width:"130px", height:"26px", borderRadius:"8px",
                        backgroundColor: "#030303", borderColor:"#000000", 
                        textTransform:"none", color:"#FFFFFF",
                        fontWeight: 900, fontSize: '10px', fontFamily: 'Nexa-Heavy',  
                      }}
                    >
                      Added
                    </Button>
                  )}
                </Box>
              </Box>  
              <Box style={{ display:"flex", justifyContent:"center" }}>
                <video 
                  style={{ borderRadius:"14px", width:"100%" }}  
                  ref={this.videoRefs[key]}
                  disablePictureInPicture
                  src={episode.attributes.episode_content} 
                />
              </Box>
              <Box style={{ display:"flex", alignItems:"center",}}>
                {episode.attributes.liked_episode.liked ? (
                  <IconButton data-test-id="likeVideoTempTestBtn" onClick={() => this.handleUnLikeEpisode(episode.attributes.liked_episode.liked_id)}>
                    <ThumbUp sx={{color: `${this.getTextColor()}`}} /> 
                  </IconButton>
                ) : (
                  <IconButton data-test-id="`unlikeVideoTempTestBtn" onClick={() => this.handleLikeEpisode(episode.id)}>
                    <ThumbUpOffAlt sx={{color: `${this.getTextColor()}`}} /> 
                  </IconButton>
                )}
                <Typography sx={{ color: `${this.getTextColor()}`, marginRight: '15px' }} className="podcast-inner">
                  {episode.attributes.liked_episode.count}
                </Typography>
                <IconButton aria-label="comment">
                  <ChatBubbleOutline data-test-id="episodeComment" sx={{color: `${this.getTextColor()}`}} onClick={() => this.showEpisodeComments(!this.state.EpisodeComments, episode.id)}/>
                </IconButton>
                <Typography sx={{ color: `${this.getTextColor()}`, marginRight: '15px' }} className="podcast-inner">
                  {episode.attributes.comments?.data?.length}
                </Typography>
                <IconButton data-test-id="shareEpisodeFromVideoTestBtn" onClick={() => this.handleOpenEpisodeShareDialog(episode.id)}>
                  <Share sx={{color: `${this.getTextColor()}`}} />
                </IconButton>
                {this.renderShareEpisode(episode)}
                <Typography sx={{ color: `${this.getTextColor()}` }} className="podcast-inner">
                  {episode.attributes.share_episode_count}
                </Typography>
                <IconButton data-test-id="videoPlayPauseEpisodeTextBtn"  style={{ marginLeft:"20px" }} onClick={() => this.handleVideoPlayPause(key)}>
                  {
                    this.state.currActiveVideo[key] ? (
                      <PauseCircleFilledIcon
                        style={{ fontSize: '40px', color: `${this.getButtonColor()}` }}
                      />
                    ) : (
                      <PlayCircleFilled
                        style={{ fontSize: '40px', color: `${this.getButtonColor()}` }}
                      />
                    )
                  }
                </IconButton>
              </Box>
            </Box>
          </Card>
           {this.state.EpisodeComments && episode.id == this.state.parentId && this.renderEpisodeCommunityPost()}
           </>
        ))}
      </>
    );
  }

  renderPostCardForSocialAudio() {
    return (
      <>
        {this.renderCreatePost()}
        <Typography style={{
          fontFamily:"Nexa-Heavy", fontSize:"30px", fontWeight:900,
          color:`${this.getTextColor()}`, marginTop:"30px"
        }}>
          Community
        </Typography>
        {this.state.showSingleComment && this.state.communityListData.map((item: Post, key: number) => (
          <>
            {this.state.openEditPost[key] && this.renderEditedPost(key)}
            {this.renderCommunitySingleComment(item, key)}
          </>
        ))}
        {!this.state.showSingleComment && this.renderSingleComment(this.state.communityItem, 2)}
      </>
    )
  }

  renderPostCardForSocialVideo() {
    return (
      <>
        {this.renderCreatePost()}
        <Typography style={{
          color:`${this.getTextColor()}`, marginTop:"30px",
          fontFamily:"Nexa-Heavy", fontSize:"30px", fontWeight:900,
        }}>
          Community
        </Typography>
        {this.state.showSingleComment && this.state.communityListData.map((item: Post, key: number) => (
          <>
            {this.state.openEditPost[key] && this.renderEditedPost(key)}
            {this.renderCommunitySingleComment(item, key)}
          </>
        ))}
        {!this.state.showSingleComment && this.renderSingleComment(this.state.communityItem, 2)}
      </>
    )
  }

  renderSavedVideoEpisodes(episode: any) {
    return (
      !episode.attributes.save_episode.saved ? (
        <Button
          data-test-id="saveLaterVideoTempTestBtn"
          style={{
            width:"130px", height:"26px", borderRadius:"8px",
            background: `${this.getButtonColor()}`, textTransform:"none",
            fontWeight: 900, fontSize: '10px', fontFamily: 'Nexa-Heavy',                    
            borderColor: `${this.getButtonColor()}`, color:"#FFFFFF",
          }}
          onClick={() => this.handleSaveForLater(episode.id)} 
          startIcon={<FavoriteBorder/>}
        >
          Save For Later
        </Button>
      ) : (
        <Button style={{
          backgroundColor: "#030303", borderColor:"#000000",
          width:"130px", height:"26px", borderRadius:"8px",
          textTransform:"none", color:"#FFFFFF",
          fontWeight: 900, fontSize: '10px', fontFamily: 'Nexa-Heavy',  
        }} 
          data-test-id="unsaveLaterVideoTempTestBtn"
          startIcon={<Favorite sx={{fill : "#FF0807"}}/>}
          onClick={() => this.handleUnSaveForLater(episode.attributes.save_episode.saved_id)}
        >
          Saved
        </Button>
      )
    )
  }

  renderRatingStar(index: number) {
    return (
      this.state.ratingStars >= index + 1 ? (
        <Star style={{ color: "#FFFFFF", height:"45px", width:"46px" }} />
      ) : (
        <StarBorder style={{ color: "#FFFFFF", height:"45px", width:"46px" }} />
      )
    )
  }

  renderSaveUnsaveText(item: any) {
    return (
      item.attributes.save_post.saved_id ? (
        <MenuItem
          sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", } }}
          data-test-id="unsavePostTestBtn"   
          onClick={() => this.handleUnSavePostDropDown(item.attributes.save_post.saved_id)}
        >
          <ListItemIcon>
            <SaveOutlinedIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
          </ListItemIcon>
          <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.dropDownUnsave}</ListItemText>
        </MenuItem>
      ) : (
        <MenuItem
          sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", } }}  
          data-test-id="savePostTestBtn"    
          onClick={() => this.handleSavePostDropDown(item.id)}
        >
          <ListItemIcon>
            <SaveOutlinedIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
          </ListItemIcon>
          <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.dropDownSave}</ListItemText>
        </MenuItem>
      )
    )
  }

  renderPinnedIcon(item: any) {
    return (
      item.attributes.pinned && (
        <PushPinOutlined style={{ color: `${this.getTextColor()}`, transform: "rotate(40deg)", width: "24px", height:"24px" }} />
      )
    )
  }

  renderNotificationText(item: any) {
    return (
      item.attributes.post_notification ? (
        <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.TurnOffNotification}</ListItemText>
      ) : (
        <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.TurnOnNotification}</ListItemText>
      )
      
    )
  }

  renderSeeMoreLink(description: string, index: number) {
    return (
      description.length > 140 && !this.state.expandSeeMore[index] &&
      <Link 
        onClick={() => this.handleSeeMore(index)} 
        data-test-id="seeMoreTestBtn"
        style={{ marginLeft:"6px", cursor:"pointer", color: `${this.getTextColor()}`, textDecorationColor: `${this.getTextColor()}` }}
      >
        See More
      </Link>
    )
  }

  checkIfPostLiked(item: any) {
    return (
      item.attributes.liked_post.liked_id ? (
        <IconButton aria-label="like" sx={{ color: 'white', marginLeft:"15px" }} data-test-id="likePost" onClick={() => this.handleUnlikePost(item.attributes.liked_post?.liked_id)}>             
          <ThumbUp sx={{ color: `${this.getTextColor()}`, height:"24px", width:"24px" }} />
        </IconButton>
      ) : (
        <IconButton aria-label="like" sx={{ color: 'white', marginLeft:"15px" }} data-test-id="UnlikePost" onClick={() => this.handleLikePost(item.id)}>
          <ThumbUpOffAlt sx={{ color: `${this.getTextColor()}`, height:"24px", width:"24px" }} />
        </IconButton>
      )
    )
  }

  renderTextWithHighlight(text: string) {
    if(!this.state.searchMentionName) return text;
    const regex = new RegExp(`(${this.state.searchMentionName})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === this.state.searchMentionName.toLowerCase() ? (
        <span
          key={index}
          style={{ backgroundColor: "rgba(255, 206, 0, 0.2)", color: "white",}}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  }

  renderTextBold(text: string) {
    return text?.split(/(\s+)/).map((word, index) =>
      word.startsWith("@") ? (
        <strong
          style={{ cursor: "pointer", fontFamily: 'Nexa-Hevay', fontWeight:900 }} 
          key={index}
        > {word.slice(1)}
        </strong>
      ) : (
        word
      )
    );
  }

  renderChannelInfo = () => {
    const { podcast: { data: { attributes } }} = this.state.saveEpisodeListData
    return(
    <>
      <Card className="podcast-card" style={{backgroundColor: `${this.getSecondaryColor()}`}} >
         <Box className="podcast-inner-box">
           <Box sx={{
               display: "flex",
               gap: "30px",
               padding : "25px",
             }}>
              <img src = {attributes?.cover_image} className="channelInfoImg" />
             <Box className = "channelInfoHeader">
               <Typography variant="h5" fontWeight="bold" sx = {{color: this.getTextColor(), fontFamily: "Nexa-Heavy"}}>
                 {attributes.name}
               </Typography>
               {attributes.sub_categories && attributes.sub_categories.map((category : SubCategoryData) => (
                <Typography variant="h6"  fontWeight="bold" sx = {{color: this.getTextColor(), fontFamily: "Nexa-Heavy"}}>
                 {category.name}
               </Typography>
               ))}
               <Box sx= {{display : "flex" , alignItems : "center" , gap : "5px"}}>
               <LocationOnIcon fontSize="small"  style = {{ color: this.getTextColor()}}/>
               <Typography variant="h6" fontWeight="bold" sx = {{color: this.getTextColor(), fontFamily: "Nexa-Heavy"}}>
               {attributes.location}
               </Typography>
               </Box>
             </Box>
           </Box>
           <CardContent style = {{padding : "25px"}}>
             <Typography variant="h6" fontWeight="bold" sx={{paddingBottom: 1, mb: 1, color: this.getTextColor(), fontFamily: "Nexa-Heavy"}}>
               {configJSON.aboutTitle}
             </Typography>
             <Typography variant="body2" sx = {{fontFamily : "Nexa-Regular" , color: this.getTextColor()}} paragraph>
               {attributes.description}
             </Typography>
           </CardContent>
            <Typography sx={{ width:"fit-content", position:"relative", left:"110px", color: this.getTextColor() , fontFamily : "Nexa-Heavy"}}>
               {configJSON.host}
            </Typography>
           {attributes.hosts.map((item: HostsData) => (
            <Box sx={{padding: 2,borderRadius: 2}}>
             <Box className = "channelInfoHost">
               <Avatar
                 src= {item.profile_picture}
                 alt="Host Avatar"
                 sx={{ width: 67, height: 67 }}
               />
              <Box>
             <Typography variant="body1" fontWeight="bold" sx = {{fontSize : "18px" , color: this.getTextColor()}} >
               {item.first_name + " " + item.last_name}
             </Typography>
             <Typography variant="body2" sx = {{marginTop : "10px" , fontFamily : "Nexa-Regular" , color: this.getTextColor()}}>
              {item.bio}
             </Typography>
           </Box>
             </Box>
           </Box>
           ))}
         </Box>
       </Card>
        <Box className="otherSectionContainer">
          <Typography sx={{ color: this.getTextColor(), fontSize:"30px", fontFamily: "Nexa-Heavy", fontWeight:900, mb:1}} >
            {configJSON.otherShows}
          </Typography>
          <Card className="otherSectionCardContainer" sx={{ backgroundColor: `${this.getSecondaryColor()}`, padding: this.getPadding1() }}>
            {this.state.residentChannelInfoData.map((item: any) => (
              <Box className="otherSectionCard">
                <img src={item.attributes?.cover_image} className="otherSectionCard-img" />
                {
                  item.attributes?.follower.following ? (
                    <Button 
                      className = "follow-btn" 
                      data-test-id="unFollowOtherShowsTestBtn"
                      sx={{ 
                        color: this.getTextColor(), background: "#000000",
                        '&:hover': { background: "#000000"}
                      }}
                      onClick={() => this.handleUnfollowOtherPodcast(item.attributes?.follower.follow_id)}
                    >
                      Followed
                    </Button>
                  ) : (
                    <Button
                      data-test-id="followOtherShowsTestBtn"
                      className = "follow-btn" 
                      sx={{ 
                        color: this.getTextColor(), background:this.getButtonColor(),
                        '&:hover': { background: this.getButtonColor()}
                      }}
                      onClick={() => this.handleFollowOtherPodcast(item.id)}
                    >
                      Follow
                    </Button>
                  )
                }
                <Box className="otherSectionCard-textContainer">
                  <Typography 
                    className="otherSectionCard-text1" 
                    sx={{
                      textOverflow:"ellipsis", whiteSpace:"nowrap",
                      color: `${this.getTextColor()}`, overflow:"hidden",
                    }}
                  >
                    {item.attributes?.subtitle}
                  </Typography>
                  <Typography sx={{
                    fontFamily: "Nexa-Heavy", fontSize: "14px", textTransform: "capitalize",
                    fontWeight: 900, whiteSpace:"nowrap", overflow:"hidden",
                    color: `${this.getTextColor()}`, textOverflow:"ellipsis",
                  }}>
                    {item.attributes?.name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Card>
        </Box>
      </>
    )
  }

  menuItemsData = [
    {
      activeState: 2,
      trueState: { img: imageEdit, text: "Edit", bgColor: "#FF0807" },
      falseState: { img: imageEyeoff, text: "Hide", bgColor: "#FF0807" },
    },
    {
      activeState: 2,
      trueState: { img: imageDelete, text: "Delete", bgColor: "#44403C" },
      falseState: { img: imageBlock, text: "Block User", bgColor: "#44403C" },
    },
  ];

  
  renderComments = (comments: Array<PostCommentObject>, paddingLeft: number, openPopup: boolean, commentType: string) => {
    return (
      <>
      {
        comments.map((comment: PostCommentObject, index: number) => {
          return(
            <Box key={index} sx={{background: `${this.getSecondaryColor()}`}}>
              <Card
                className="podcast-card-comment"
                style={{
                  borderRadius: "0px",
                  backgroundColor: `${this.getSecondaryColor()}`
                }}
              >
                <Box className="podcast-inner-box">
                  <CardContent
                    className="podcast-inner-box-content"
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #E0E0E0",
                      padding: `22px 33px 22px ${paddingLeft}px`,
                    }}
                    >
                      <Box sx={{display:"flex"}} data-test-id="comment-box">
                      <AvatarImage src={comment.attributes.account_details.profile} alt="Avatar" />
                    <Box>
                      <Box className="community-list">
                        <Typography
                          component="div"
                          variant="h6"
                          style={{ color:`${this.getTextColor()}` , fontSize: "14px", fontWeight: 900 }}
                        >
                          {`${comment.attributes.account_details.first_name} ${comment.attributes.account_details.last_name}`}
                        </Typography>
                       
                      </Box>
                      
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 100, fontSize: "10px",color:`${this.getTextColor()}` }}

                      >
                        {moment(comment.attributes.created_at).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 100, fontSize: "14px",color:`${this.getTextColor()}`  }}
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                         {this.renderTextBold(comment.attributes.comment)}
                        </Typography>
                        <Box className="community-list-buttons">
                          <IconButton data-test-id="handleLike" onClick={() => this.handleLike(comment.id, comment.attributes.likes.liked, comment.attributes.likes.liked_id)} aria-label="like" sx={{ color: 'white' }}>
                            {comment.attributes.likes.liked ? <ThumbUp sx={{ color: `${this.getTextColor()}` }} /> : <ThumbUpOffAlt sx={{ color: `${this.getTextColor()}` }} />}
                          </IconButton>
                          
                        <Typography
                          sx={{ color: `${this.getTextColor()}`, marginRight: "15px", fontSize: "14px" }} 
                        >
                          {comment.attributes.likes.count}
                        </Typography>
                        <IconButton sx={{ color: `${this.getTextColor()}`}} onClick={()=>{this.showNestedComments(comment.id, comment, commentType)}}>
                          <ChatBubbleOutline />   
                            <Typography
                              sx={{ color: `${this.getTextColor()}`, marginLeft: "10px", fontSize: "14px" }}
                            >
                              {comment.attributes.reply_count}
                            </Typography>
                        </IconButton>
                      </Box>
                    </Box>
                    </Box>

                    <Button
                      id="basic-button"
                      aria-controls={openPopup ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      data-test-id="comment-box"
                      style={{display:"flex", alignItems:"flex-start", height:"fit-content"}}
                      aria-expanded={openPopup ? 'true' : undefined}
                      onClick={(e)=>this.handleClick(e, comment.id)}
                    >
                      <img
                        src={comment.id}
                        style={{
                          width: "25px",
                          height: "4px",
                        }}
                      />
                      <StyledMenu
                        id="basic-menu"
                        open={openPopup}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {this.menuItemsData.map((item, index) => {
                          const state = this.state.activeCommentId === item.activeState ? item.trueState : item.falseState;
                          return (
                            <StyledMenuItem key={index} style={{background:state.bgColor}}>
                              <img src={state.img} alt={state.text} />
                              <div>{state.text}</div>
                            </StyledMenuItem>
                          );
                        })}
                      </StyledMenu>
                    </Button>
                  </CardContent>
                  {
                    comment.id == this.state.nestedCommentId &&
                    <Box>
                      {this.renderCommentInputBox(comment.id, "nested", paddingLeft)}
                      {this.renderComments(this.state.nestedComments, paddingLeft + 62, this.state.openPopup, "nestedComment")}
                    </Box>
                  }
                </Box>
              </Card>
            </Box>
          )
        })
      }
      </>
    )
  };

  renderCommentInputBox = (id: string, commentType: string, padding?: number) => {
    return (
      <Card className="podcast-card-add-comment" style={{ paddingLeft: `${padding}px`, borderRadius: `${padding ? "0px" : "20px 20px 0px 0px"}`, background: `${this.getSecondaryColor()}` }}>
        <Box className="podcast-inner-box">
          <PodcastContentBox className="podcast-inner-box-content">
            <Box sx={{ display: 'flex' }}>
              <AvatarImage src={this.state.userProfileData.attributes.profile_picture} alt="Avatar" />
              <Box sx={{ flex: 1, position: 'relative' }}>
                <TextFieldContainer style={{borderRadius: `${ padding ? "0px" : "20px 20px 0px 0px" }`}}>
                  <StyledTextField
                    value={this.state.textareaValue}
                    onChange={this.handleTextareaChange}
                    fullWidth
                    multiline
                    placeholder="Leave a comment"
                    rows={4}
                    variant="outlined"
                    InputProps={{
                      readOnly: !!this.state.selectedFile,
                    }}
                  />
                </TextFieldContainer>
                <ReplyButtonBox>
                  <StyledIconButton
                    data-test-id="handleSend2"
                    onClick={() =>  this.handleReply(id, commentType) }
                  >
                    <SendRoundedIcon style={{ fill:`${this.getSecondaryColor()}`, width: '13.5px', height: '12.6px' }} />
                  </StyledIconButton>
                </ReplyButtonBox>
              </Box>
            </Box>
          </PodcastContentBox>
          {this.renderMentionsPopup()}
        </Box>
      </Card>
    );
  };

  renderShareEpisode(episode: EpisodeData) {
    return(
      <Dialog
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(5px)',
            },
          },
        }}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "1149px",
            width: "1149px",
            background: "#44403C",
            height:"466px",
            borderRadius: '8px 8px 32px 8px',
            boxSizing:"border-box"
          },
        }}
        data-test-id="closeEpisodeDialogTestBtn"
        open={this.openShareEpisode(episode.id)}
        onClose={() => this.handleCloseEpisodeShareDialog()}
      >
        <IconButton sx={{ position:"relative", alignSelf:"flex-end", top:"15px", right:"15px" }} onClick={this.handleCloseEpisodeShareDialog}>
          <CloseIcon sx={{ color:"#FFFFFF" }} />
        </IconButton>
        <Box style={{ 
          display:"flex", gap:"8px", alignItems:"center", 
          paddingLeft:"40px", paddingTop:"20px"
        }}>
          <Share sx={{ color:"#FFFFFF" }} />
          <Typography style={{ 
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"24px", color:"#FFFFFF"
          }}>
            Share Episode
          </Typography>
        </Box>
        <Box style={{
          display:"flex", padding:"50px 30px 0px 30px", flexDirection:"column", gap:"20px"
        }}>
          <Typography style={{
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"14px", color:"#FFFFFF"
          }}>
            Episode URL
          </Typography>
          <UrlBox style={{ gap:"10px" }}>
            <EpisodeTextFeild id="episodeUrl">{episode.attributes.episode_url}</EpisodeTextFeild>
            <CopyButtonEpisode data-test-id="copyEpisodeUrlTestBtn" onClick={() => this.handleCopyEpisodeUrl()} ><ContentCopy />{configJSON.copyText}</CopyButtonEpisode>
            <Box style={{ display:"flex", gap:"12px", alignItems:"center", marginLeft:"18px" }}>
              <Checkbox
                sx={{ 
                  width:"33px", height:"32px", background:"#44403C",
                  '& .MuiSvgIcon-root': { width:"33px", height:"32px" },
                  color: "#FFFFFF", '&.Mui-checked': { color: "#FFFFFF" },
                }}
              />
              <Typography style={{ fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"14px", color:"#FFFFFF" }}>
                Start At
              </Typography>
              <EpisodeStartAtTextFeild>{0}</EpisodeStartAtTextFeild>
            </Box>
          </UrlBox>
          <Typography style={{
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"14px", color:"#FFFFFF"
          }}>
            Embed
          </Typography>
          <LinkContainer style={{ gap:"10px" }}>
            <EpisodeEmbededTextFeild id="embedEpisodeUrl">{episode.attributes.embed_code}</EpisodeEmbededTextFeild>
            <CopyButtonEpisode data-test-id="copyEpisodeEmbedUrlTestBtn" onClick={() => this.handleCopyEpisodeEmbedUrl()} ><ContentCopy />{configJSON.copyText}</CopyButtonEpisode>
          </LinkContainer>
        </Box>
      </Dialog>
    )
  }

  renderTabs = () => {
    const firstTabName = (this.state.templateType === "Audio + Community Focused" || this.state.templateType === "Video + Community Focused") ? "Episodes & Community" : "Episodes";
    return(
      !this.state.openReviewsScreen ? (
      <Box className="left-body-section-tabs">
        <Box sx={{ display: 'flex', gap: '22px' }}>
        <Button style={{backgroundColor:this.getSecondaryColor(), borderColor:this.getSecondaryColor(), color:this.getTextColor()}} variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges(firstTabName)} sx={{ borderRadius: this.checkBorderRadius1(), height: this.checkHeight1() }}>{firstTabName}</Button>
          {(this.state.templateType === "Audio Focused" || this.state.templateType === "Video Focused" ) && 
            <Button style={{backgroundColor:this.getSecondaryColor(), borderColor:this.getSecondaryColor(), color:this.getTextColor()}} variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('Community')} sx={{ borderRadius: this.checkBorderRadius2(), height: this.checkHeight2() }}>Community</Button>
          }
         <Button style={{backgroundColor:this.getSecondaryColor(), borderColor:this.getSecondaryColor(), color:this.getTextColor()}} variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('ChannelInfo')} sx={{ borderRadius: this.checkBorderRadius3(), height: this.checkHeight3() }}>Channel Info</Button>
        </Box>
      </Box>
      ) : (
        <Box id="left-body-section-rating-reviews" style={{ height:"40px", display:"flex", marginTop:"25px", gap:"20px", alignItems:"center" }}>
          <Box data-test-id="goBackFromRatingTestBtn" onClick={() => this.goBackfromReviewsScreen()} style={{ cursor:"pointer", borderRadius:"8px", display:"flex", justifyContent:"center", alignItems:"center", height:"40px", width:"40px", background: this.getButtonColor() }}>
            <ArrowBackIosIcon style={{ color: this.getTextColor(), position:"relative", left:"5px", }}/>
          </Box>
          <Typography style={{ 
            fontFamily:"Nexa-Heavy", fontSize:"30px", fontWeight:900,
            color: this.getTextColor()
          }}>
            Ratings And Reviews
          </Typography>
        </Box>
      )
    )
  };

  renderMentionsPopup() {
    return (
      <>
        {this.state.openMentionsSearchResultsPopup &&
          <Box
            ref={this.mentionPopupRef}
            onClick={this.handleMentionBoxClick}
            sx={{
              height:"326px", background: "#312F2D",
              position: this.setPosition(), top: this.setPosition1(), left:this.setPosition2(),
              display:"flex", flexDirection:"column", gap:"2px",
              maxWidth: "414px", width:"100%", zIndex:99,
            }}
          > 
            {this.state.searchMentionsdata.accounts?.data.map((item: any, index: number) => (
              <Box style={{
                width:"100%", height:"80px", background: this.backgroundColor1(index),
                justifyContent:"flex-start", alignItems:"center", gap:"25px",
                borderBottom:"1px solid #F5F5F5", display:"flex", cursor:"pointer"
              }}
                onClick={() => this.handleMentionsName(item.attributes.first_name + " " + item.attributes.last_name)}
                data-test-id="mentionUserAccNameTestBtnBox"
              >
                <Avatar
                  sx={{ width:"41px", height:"46px", marginLeft:"16px" }}
                  alt="User Avatar"
                  src={item.attributes.profile_picture}
                />
                <Box style={{ display:"flex", flexDirection:"column", gap:"2px" }}>
                  <Typography style={{
                    color:"#FFFFFF", overflow:"hidden", textOverflow:"ellipsis",
                    fontFamily:"Nexa-Heavy", fontWeight:400, fontSize:"14px",
                    whiteSpace:"nowrap", maxWidth:"250px", width:"fit-content"
                  }}>
                    {this.renderTextWithHighlight(item.attributes.first_name + " " + item.attributes.last_name)}
                  </Typography>
                  <Typography style={{
                    color:"#FFFFFF", whiteSpace:"nowrap", textOverflow:"ellipsis",
                    fontFamily:"Nexa-Regular", fontWeight:400, fontSize:"14px",
                    overflow:"hidden", maxWidth:"250px", width:"fit-content"
                  }}>
                    {this.renderHostResident(item.attributes.roles)}
                  </Typography>
                </Box>
              </Box>
            ))}
            {this.state.searchMentionsdata.podcast?.data.map((item: any) => (
              <Box style={{
                borderBottom:"1px solid #F5F5F5", display:"flex", cursor:"pointer",
                width:"100%", height:"80px", background: "#44403C",
                justifyContent:"flex-start", alignItems:"center", gap:"25px",
              }}
                onClick={() => this.handleMentionsName(item.attributes.name)}
                data-test-id="mentionUserPodcastNameTestBtnBox"
              >
                <img
                  src={item.attributes.cover_image}
                  style={{ width:"69px", height:"76px" }}
                  alt="User Avatar"
                />
                <Box style={{ display:"flex", flexDirection:"column", gap:"2px" }}>
                  <Typography style={{
                    whiteSpace:"nowrap", width:"250px",
                    color:"#FFFFFF", overflow:"hidden", textOverflow:"ellipsis",
                    fontFamily:"Nexa-Heavy", fontWeight:400, fontSize:"14px",
                  }}>
                    {this.renderTextWithHighlight(item.attributes.name)}
                  </Typography>
                  <Typography style={{
                    whiteSpace:"nowrap", width:"250px",
                    color:"#FFFFFF", overflow:"hidden", textOverflow:"ellipsis",
                    fontFamily:"Nexa-Regular", fontWeight:400, fontSize:"14px",
                  }}>
                    {item.attributes.subtitle}
                  </Typography>
                </Box>
              </Box>
            ))}
            {this.state.searchMentionsdata.episodes?.data.map((item: any) => (
              <Box style={{
                justifyContent:"flex-start", alignItems:"center", gap:"25px",
                width:"100%", height:"80px", background: "292524",
                borderBottom:"1px solid #F5F5F5", display:"flex", cursor:"pointer"
              }}
                onClick={() => this.handleMentionsName(item.attributes.name)}
                data-test-id="mentionUserEpisodeNameTestBtnBox"
              >
                <img
                  alt="User Avatar"
                  style={{ width:"69px", height:"76px" }}
                  src={item.attributes.cover}
                />
                <Box style={{ display:"flex", flexDirection:"column", gap:"2px" }}>
                  <Typography style={{
                    fontFamily:"Nexa-Heavy", fontWeight:400, fontSize:"14px",
                    color:"#FFFFFF", overflow:"hidden", textOverflow:"ellipsis",
                    whiteSpace:"nowrap", width:"250px",
                  }}>
                    {this.renderTextWithHighlight(item.attributes.name)}
                  </Typography>
                  <Typography style={{
                    fontFamily:"Nexa-Regular", fontWeight:400, fontSize:"14px",
                    color:"#FFFFFF", overflow:"hidden", textOverflow:"ellipsis",
                    whiteSpace:"nowrap", width:"250px",
                  }}>
                    {item.attributes.podcast_name}
                  </Typography>
                </Box>
              </Box>
            ))} 
          </Box>
        }
      </>
    )
  }

  renderCreatePost = () => {
    return (
      <Box className="podcast-card" style={{backgroundColor: `${this.getSecondaryColor()}`}}>
        <PodcastInnerBox className="podcast-inner-box">
          {!this.state.showComments && (
            <PodcastContent className="post-podcast-inner-box-content">
              <Box sx={{ display: 'flex' }}>
                <Avatar
                  src={this.state.userProfileData.attributes?.profile_picture}
                  alt="Avatar"
                  sx={{
                    width: '56px',
                    height: '56px',
                    borderRadius: '50%',
                    marginRight: '10px',
                  }}
                />
                <Box sx={{ flex: 1, position: 'relative' }}>
                  <TextareaWrapper>
                    <StyledTextFieldSecond
                      value={this.state.textareaValue}
                      onChange={this.handleTextareaChange}
                      fullWidth
                      multiline
                      data-test-id="createPostTextArea"
                      placeholder="Create A Post"
                      rows={4}
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                    />
                    {Object.keys(this.state.openEditPost).length === 0 && this.renderMentionsPopup()}
                    {this.state.selectedFile && (
                      <FilePreviewBox>
                        <FileImage
                          src={URL.createObjectURL(this.state.selectedFile)}
                          alt="Uploaded Preview"
                        />
                        <FileRemoveIconButton onClick={this.handleRemoveFile}>
                          <CancelRoundedIcon style={{ color: '#312F2D' }} />
                        </FileRemoveIconButton>
                      </FilePreviewBox>
                    )}
                  </TextareaWrapper>
                  <ActionButtonsBox>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      id="file-upload"
                      onChange={this.handleFileUpload}
                    />
                    <FileUploadButton
                      style={{ backgroundColor: `${this.getButtonColor()}` }}
                      data-test-id="file-uploadId"
                      onClick={() => document.getElementById('file-upload')?.click()}
                    >
                      <AttachFileIcon style={{ fill: `${this.getSecondaryColor()}`, padding:"5px" }} />
                    </FileUploadButton>
                    <SendButton
                      sx={{ 
                        backgroundColor: this.checkIfDisabled1(),
                        '&:hover': {
                          backgroundColor: this.checkIfDisabled1(),
                        },
                      }}
                      disabled={this.checkIfDisabled1() === '#DADADA'}
                      data-test-id="handleSend"
                      onClick={this.handleSend}
                    >
                      <SendRoundedIcon style={{ fill:`${this.getSecondaryColor()}`, width: '13.5px', height: '12.6px' }} />
                    </SendButton>
                  </ActionButtonsBox>
                </Box>
              </Box>
            </PodcastContent>
          )}
        </PodcastInnerBox>
      </Box>

    )
  };

  renderEditedPost(key: number) {
    return (
      <Box className="podcast-card" style={{ backgroundColor: `${this.getSecondaryColor()}`}}>
        <PodcastInnerBox className="podcast-inner-box">
          {!this.state.showComments && (
            <PodcastContent className="post-podcast-inner-box-content">
              <Box sx={{ display: 'flex' }}>
                <Avatar
                  alt="Avatar"
                  src={this.state.userProfileData.attributes?.profile_picture}
                  sx={{
                    height: '56px',
                    width: '56px',
                    marginRight: '10px',
                    borderRadius: '50%',
                  }}
                />
                <Box sx={{ flex: 1, position: 'relative' }}>
                  <TextareaWrapper>
                    <StyledTextFieldSecond
                      value={this.state.textareaValueForEdit[key]}
                      fullWidth
                      multiline
                      onChange={(event) => this.handleTextareaChangeForEdit(key, event)}
                      variant="standard"
                      placeholder="Create A Post"
                      InputProps={{ disableUnderline: true }}
                      rows={4}
                      data-test-id="textAreaEditTestBtn"
                    />
                    {this.state.openEditPost[key] && this.renderMentionsPopup()}
                    {(this.state.selectedFileUrlForEdit[key] || this.state.selectedFileForEdit[key]) && (
                      <FilePreviewBox>
                        <FileImage
                          alt="Uploaded Preview"
                          src={this.state.selectedFileUrlForEdit[key] || URL.createObjectURL(this.state.selectedFileForEdit[key])}
                        />
                        <FileRemoveIconButton data-test-id="removeFileFromEditTestBtn" onClick={this.handleRemoveFileForEdit}>
                          <CancelRoundedIcon style={{ color: '#312F2D' }} />
                        </FileRemoveIconButton>
                      </FilePreviewBox>
                    )}
                  </TextareaWrapper>
                  <ActionButtonsBox>
                    <input
                      id="file-upload-edit"
                      type="file"
                      data-test-id="inputFileForEditTestBtn"
                      style={{ display: 'none' }}
                      onChange={(event) => this.handleFileUploadForEdit(event, key)}
                    />
                    <FileUploadButton
                      data-test-id="file-uploadIdEditTestBtn"
                      style={{ backgroundColor: `${this.getButtonColor()}` }}
                      onClick={() => document.getElementById('file-upload-edit')?.click()}
                    >
                      <AttachFileIcon style={{ padding:"5px", fill: `${this.getSecondaryColor()}` }} />
                    </FileUploadButton>
                    <SendButton
                      sx={{ 
                        '&:hover': {
                          backgroundColor: this.checkIfDisabled5(key),
                        },
                        backgroundColor: this.checkIfDisabled5(key),
                      }}
                      data-test-id="handleSendEditTestBtn"
                      disabled={this.checkIfDisabled5(key) === '#DADADA'}
                      onClick={() => this.handleSendForEditPost(key)}
                    >
                      <SendRoundedIcon style={{ width: '13.5px', height: '12.6px', fill:`${this.getSecondaryColor()}` }} />
                    </SendButton>
                  </ActionButtonsBox>
                </Box>
              </Box>
            </PodcastContent>
          )}
        </PodcastInnerBox>
      </Box>
    )
  };

  renderEpisodeCommunityPost = () => {
    return <Box>
        <TextComment style={{ color:`${this.getTextColor()}` }}>
          Comments
        </TextComment>
       {this.state.nestedCommentId == "" && this.renderCommentInputBox(this.state.parentId, "episode")}
      {this.renderComments(this.state.postComments, 43, this.state.openPopup, "mainComment")}
    </Box>
  }

  renderAllReviewsScreen() {
    return (
      <div className="scrollableWrapper" id="scrollableWrapper" style={{ height: "65rem" }}>
        <Box className="left-body-section" sx={{ marginTop:"20px", scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '64rem' }}>
          <Box style={{ display:"flex", flexDirection:"column", gap:"20px", }}>
            {this.state.allReviewsData.current_user_reviews?.data.map((item: any, index: number) => (
              <Box style={{
                display:"flex", height:"auto", maxHeight:"271px", width:"100%", padding:"30px 20px 20px 40px",
                background: this.getSecondaryColor(), borderRadius: "20px", gap:"18px",
                border: this.setBorder1(index)
              }}>
                <Avatar
                  style={{ height:"56px", width:"56px" }}
                  alt="Avatar"
                  src={item.attributes.profile_picture}
                />
                <Box style={{
                  display:"flex", flexDirection:"column", gap:"20px",
                }}>
                  <Box style={{
                    display:"flex", flexDirection:"column", marginTop:"5px", gap:"6px", 
                  }}>
                    <Typography style={{
                      color: this.getTextColor(),
                      fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"14px",
                    }}>
                      {item.attributes.user_name}
                    </Typography>
                    <Typography style={{
                      fontFamily:"Nexa-Regular", fontWeight:100, fontSize:"10px",
                      color: this.getTextColor()
                    }}>
                      {moment(item.attributes.created_at).format('DD/MM/YYYY')}
                    </Typography>
                    <Rating 
                      sx={{ 
                        color: this.getTextColor(),
                        '& .MuiSvgIcon-root': {
                          fontSize: '31px', 
                        },
                      }}
                      emptyIcon={<StarBorder sx={{ color: this.getTextColor() }} fontSize="inherit" />}
                      max={5}
                      value={item.attributes.rating} 
                      precision={0.5} readOnly
                    />
                  </Box>
                  <Box style={{
                    display:"flex", flexDirection:"column", gap:"18px",
                  }}>
                    <Typography style={{
                      fontFamily:"Nexa-Regular", fontWeight:100, fontSize:"14px",
                      color: this.getTextColor()
                    }}>
                      {item.attributes.description}
                    </Typography>
                    <Box style={{
                      height:"30px", display:"flex", gap:"6px", alignItems:"center",
                    }}>
                      {
                        item.attributes.like.liked ? (
                          <IconButton onClick={() => this.handleUnLikeReview(item.attributes.like.liked_id)} data-test-id="unLikeReviewTestBtn">
                            <ThumbUp style={{ color: this.getTextColor() }}/>
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => this.handleLikeReview(item.id)} data-test-id="likeReviewTestBtn">
                            <ThumbUpOffAlt style={{ color: this.getTextColor() }}/>
                          </IconButton>
                        )
                      }
                      <Typography style={{
                        color: this.getTextColor(),
                        fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"14px",
                      }}>
                        {item.attributes.like.count}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
            {this.state.allReviewsData.other_reviews?.data.map((item: any, index: number) => (
              <Box style={{
                display:"flex", height:"auto", maxHeight:"271px", 
                background: this.getSecondaryColor(), borderRadius: "20px", gap:"18px",
                width:"100%", padding:"30px 20px 20px 40px",
              }}>
                <Avatar
                  src={item.attributes.profile_picture}
                  style={{ height:"56px", width:"56px" }}
                  alt="Avatar"
                />
                <Box style={{
                  display:"flex", flexDirection:"column", gap:"20px",
                }}>
                  <Box style={{
                    display:"flex",  marginTop:"5px", gap:"6px", flexDirection:"column", 
                  }}>
                    <Typography style={{
                      color: this.getTextColor(), fontFamily:"Nexa-Heavy",
                       fontWeight:900, fontSize:"14px",
                    }}>
                      {item.attributes.user_name}
                    </Typography>
                    <Typography style={{
                      color: this.getTextColor(), fontFamily:"Nexa-Regular",
                      fontWeight:100, fontSize:"10px",
                    }}>
                      {moment(item.attributes.created_at).format('DD/MM/YYYY')}
                    </Typography>
                    <Rating 
                      sx={{ 
                        '& .MuiSvgIcon-root': {
                          fontSize: '31px', 
                        },
                        color: this.getTextColor(),
                      }}
                      value={item.attributes.rating} 
                      emptyIcon={<StarBorder sx={{ color: this.getTextColor() }} fontSize="inherit" />}
                      max={5}
                      precision={0.5} readOnly
                    />
                  </Box>
                  <Box style={{
                    display:"flex", flexDirection:"column", gap:"18px",
                  }}>
                    <Typography style={{
                      fontFamily:"Nexa-Regular", fontSize:"14px",
                      color: this.getTextColor(), fontWeight:100,
                    }}>
                      {item.attributes.description}
                    </Typography>
                    <Box style={{
                      height:"30px", alignItems:"center",  display:"flex", gap:"6px",
                    }}>
                      {
                        item.attributes.like.liked ? (
                          <IconButton onClick={() => this.handleUnLikeReview(item.attributes.like.liked_id)} data-test-id="unLikeResidentReviewTestBtn">
                            <ThumbUp style={{ color: this.getTextColor() }}/>
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => this.handleLikeReview(item.id)} data-test-id="likeResidentReviewTestBtn">
                            <ThumbUpOffAlt style={{ color: this.getTextColor() }}/>
                          </IconButton>
                        )
                      }
                      <Typography style={{
                        color: this.getTextColor(), fontWeight:900,
                        fontFamily:"Nexa-Heavy", fontSize:"14px",
                      }}>
                        {item.attributes.like.count}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
    )
  }

  renderMenuList = (item: Post, key: number) => {
    return (
      <Box>
        {
          this.state.openDropdownForMyOrOthersPost === "My Post" ? (
            <Menu
              anchorEl={this.state.openDropDown}
              open={this.openMenu(item)}
              onClose={this.handleMenuCloseForHostUser}
              data-test-id="closeMenuForHostTestBtn"
              sx={menuStyle}
            >
              <MenuItem
                onClick={() => this.handleEditMyPost(item, key)}
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                data-test-id="editMyPostTestBtn"
              >
                <ListItemIcon>
                  <EditIcon sx={{ fontSize: "36px", color: "#FFFFFF", }} />
                </ListItemIcon>
                <ListItemText style={{ marginLeft: "8px", fontSize: "14px", fontWeight: 400, fontFamily: "Nexa-Regular", }}>{configJSON.dropDownEdit}</ListItemText>
              </MenuItem>
              <Divider sx={{ borderColor: "#ffffff", }} style={{ margin: "0px" }} />
              {this.renderSaveUnsaveText(item)}
              <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
              <MenuItem
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                onClick={() => this.handleTurnOffNotificationOfMyPost(item.id)}
                data-test-id="notificationTurnOffAndOnOfMyPostTestBtn"
              >
                <ListItemIcon>
                  <NotificationsNoneIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
                </ListItemIcon>
                {this.renderNotificationText(item)}
              </MenuItem>
              <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
              <MenuItem
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                onClick={() => this.handleDeletePost(item.id)}
                data-test-id="deletePostTestBtn"
              >
                <ListItemIcon>
                  <DeleteIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
                </ListItemIcon>
                <ListItemText style={{ fontWeight: 400, fontFamily: "Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.dropDownDelete}</ListItemText>
              </MenuItem>
            </Menu>
          ) : (
            <Menu
              anchorEl={this.state.openDropDownForOtherPost}
              open={this.openMenuForOthersPost(item)}
              onClose={this.handleMenuCloseForOtherUser}
              data-test-id="closeMenuForOthersPostTestBtn"
              sx={menuStyle}
            >
              {this.renderSaveUnsaveText(item)}
              <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
              <MenuItem
                onClick={() => this.handleTurnOffNotificationOfMyPost(item.id)}
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                data-test-id="notificationTurnOffAndOnOfOthersPostTestBtn"
              >
                <ListItemIcon>
                  <NotificationsNoneIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
                </ListItemIcon>
                {this.renderNotificationText(item)}
              </MenuItem>
              <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
              <MenuItem
                onClick={() => this.handleHidePost(item.id)}
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                data-test-id="hidePostTestBtn"
              >
                <ListItemIcon>
                  <VisibilityOffIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
                </ListItemIcon>
                <ListItemText style={{ fontWeight: 400, fontFamily: "Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.hideText}</ListItemText>
              </MenuItem>
              <Divider sx={{ borderColor: "#ffffff", }} style={{ margin: "0px" }} />
              <MenuItem
                onClick={() => this.handleBlockUser(item.attributes.account.id)}
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", }, }}
                data-test-id="blockUserPostTestBtn"
              >
                <ListItemIcon>
                  <BlockIcon sx={{ fontSize: "36px", color: "#FFFFFF", }} />
                </ListItemIcon>
                <ListItemText style={{ marginLeft: "8px", fontSize: "14px", fontWeight: 400, fontFamily: "Nexa-Regular", }}>{configJSON.blockUserText}</ListItemText>
              </MenuItem>
            </Menu>
          )
        }
      </Box>
    )
  };

  renderCommunitySingleComment = (item: Post, key:number) => {
    return(
      <Card className="podcast-card" style={{ border: this.renderPinnedBorder(item.attributes), backgroundColor: `${this.getSecondaryColor()}`, borderRadius:"20px"}}>
      <Box className="podcast-inner-box">
      <CardContent className="post-podcast-inner-box-content" style = {{display : "flex"}}>
        <Box sx = {{padding : "26px 0px 0px 30px", cursor: "pointer",}}>
          <Avatar
              src={item?.attributes.account.attributes?.profile_picture || ""}
              alt="Avatar"
              style={{
                width: "56px",
                height: "56px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
        </Box>
        <Box style={{width:"100%"}}>
      <Box sx = {{margin : "20px 20px 5px 20px", display:"flex", flexDirection:"column", gap:"6px" }}>
        <Box className = "community-list">
          <Typography style={{ width:"100%", cursor: "pointer", color: `${this.getTextColor()}`, fontWeight:900, fontSize:"14px" }} className="podcast-inner">{item.attributes.name}</Typography>
          {this.renderPinnedIcon(item)}
          <IconButton data-test-id= "isOpenMenuID" size="small" sx={{ color: `${this.getTextColor()}` }}
            aria-label="more options"
            onClick={(event) => this.handleOpenDropDownForHostUser(event, item.id, key)}
          >
            <MoreHorizIcon sx={{ color: `${this.getTextColor()}`, fontSize:"36px" }} />
          </IconButton>
          {this.renderMenuList(item, key)}
        </Box>
          <Typography style={{ color: `${this.getTextColor()}`, fontWeight:100, fontSize:"10px" }} className="podcast-inner">{moment(item.attributes?.created_at).format('DD/MM/YYYY')}</Typography>
          <Typography sx={{  margin:"0px", color: `${this.getTextColor()}`, fontFamily: 'Nexa-Regular', fontSize:"14px", fontWeight:100 }}>
            {this.renderTextBold(item.attributes?.body)}
          </Typography>
          <Box sx = {{padding : "10px", display: this.renderBlockOrFlex(item)}}>
            <img style={{ maxWidth: "734px", width:"100%", height:"343px", borderRadius: "43px", cursor: "pointer" }} src = {this.renderPostImage(item)}  />
          </Box>
       </Box>
      <Box sx = {{marginLeft: "20px", width:"fit-content"}} className = "community-list-buttons" >
         <Typography sx={{ color: `${this.getTextColor()}`, fontSize:"14px", fontFamily:"Nexa-Heavy", fontWeight:900 }} className="podcast-inner">{moment(item.attributes?.updated_at).fromNow()}</Typography>                                                       
          {this.checkIfPostLiked(item)}                            
          <Typography sx={{ color: `${this.getTextColor()}`, marginRight: '15px', fontSize:"14px", fontFamily:"Nexa-Heavy", fontWeight:900 }} className="podcast-inner">{item.attributes.liked_post.count}</Typography>
                <IconButton data-test-id="showCommentsId" aria-label="comment" sx={{ color: 'white' }} onClick={() => this.showComments(item.id, key)}>
                  <ChatBubbleOutline sx={{ color: `${this.getTextColor()}`, height: "24px", width: "24px" }} />
                </IconButton>
          <Typography sx={{ color: `${this.getTextColor()}`, marginRight: '15px', fontSize:"14px", fontFamily:"Nexa-Heavy", fontWeight:900 }} className="podcast-inner">{item.attributes.comments.data.length}</Typography>
          <IconButton data-test-id="sharePostDialogTextBtn" aria-label="sharepost" sx={{ color: 'white' }} onClick={() => this.handleOpenSharePostModal(item.id)}>
            <Share sx={{ color: `${this.getTextColor()}`, height:"24px", width:"24px" }} />
          </IconButton>
          <Modal
            open={this.openSharePost(item)}
            onClose={this.handleCloseSharePostModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{background:"rgba(0,0,0,0.5)" ,  backdropFilter: 'blur(5px)',}}
          >
            <Box sx={SharePostModalstyle}>
              <Box className="modalBox">
                <IconButton  data-test-id="closeSharePostDialog" onClick={this.handleCloseSharePostModal}>
                  <CloseIcon sx={{color: "#fff", position: "absolute", left: "920px"}} />
                </IconButton>
                <ShareChannelModal>
                  <ShareIcon aria-label="share">
                    <Share />
                  </ShareIcon>
                  <ShareChannelText mb={6} mt={4}>{"Share Post"}</ShareChannelText>
                </ShareChannelModal>
                <ShareChannelText variant="subtitle1" gutterBottom>{"Post URL"}</ShareChannelText>
                <UrlBox>
                  <ChannelTextFeild id="postUrl">{item.attributes.post_url}</ChannelTextFeild>
                  <CopyButton data-test-id="copyPostUrlTestBtn" onClick={() => this.handleCopyPostUrl()} ><ContentCopy />{configJSON.copyText}</CopyButton>
                </UrlBox>
              </Box>
            </Box>
          </Modal>
          <Typography sx={{ color: `${this.getTextColor()}`, fontSize:"14px", fontFamily:"Nexa-Heavy", fontWeight:900 }} className="podcast-inner">{item.attributes.share_post_count}</Typography>
        </Box>
        </Box>
        <Box>
      </Box>
      </CardContent>
    </Box>
  </Card>
    )
  };

  renderSingleComment = (item: Post, key:number) => {
    return(
      <Box>
        {this.renderCommunitySingleComment(item, key)}
        <TextComment style={{ color:`${this.getTextColor()}` }}>
          Comments
        </TextComment>
        {this.state.nestedCommentId == "" && this.renderCommentInputBox(item.id, "post")}
        {this.renderComments(this.state.postComments, 43, this.state.openPopup, "mainComment")}
      </Box>
    )
  };

  renderChannelPlansInfo() {
    return (
      <Dialog
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(5px)',
            },
          },
        }}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "1682px",
            width: "1682px",
            background: "#44403C",
            height:"854px",
            borderRadius: '33px',
            boxSizing:"border-box"
          },
        }}
        data-test-id="closePlansInfoDialogTestBtn"
        open={this.state.openChannelInfoPlansDialog}
        onClose={() => this.handleCloseChannelPlansInfoDialog()}
      >
        <IconButton sx={{ position:"relative", top:"15px", right:"15px", alignSelf:"flex-end" }} onClick={this.handleCloseChannelPlansInfoDialog}>
          <CloseIcon sx={{ color:"#FFFFFF", }} />
        </IconButton>
        <Box style={{
          display:"flex", flexDirection:"column", padding:"5px 50px 30px 50px", 
          boxSizing:"border-box", gap:"20px"
        }}>
          <Box style={{
            display:"flex", gap:"40px", alignItems:"center", width:"100%"
          }}>
            <img src={podcastImage} style={{ width:"130px", height:"125px", borderRadius:"3px" }} />
            <Box style={{
              display:"flex", flexDirection:"column", gap:"10px",
            }}>
              <Typography style={{ fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"30px", color:"#FFF", }}>The True Podcast</Typography>
              <Typography style={{ fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"18px", color:"#FFF", }}>1.5K Reviews</Typography>
              <Box style={{ display:"flex", gap:"5px", alignItems:"center" }}>
                <LocationOnIcon sx={{ color:"#FFFFFF", height:"20px", width:"20px" }}/>
                <Typography style={{ fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"18px", color:"#FFF", }}>London, UK</Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ 
            display:"flex", justifyContent:"space-between", gap:"15px",
            width:"100%", marginTop:"25px", flexWrap:"wrap",
          }}>
            <Box style={{ 
              width:"333px", height:"512px", borderRadius:"15px", 
              background:"#1C1917", display:"flex", flexDirection:"column"
            }}>
              <Box style={{ 
                width:"100%", flexGrow:1, display:"flex",
                flexDirection:"column", justifyContent:"space-evenly"
              }}>
                <StyledTypo1>TIER 1</StyledTypo1>
                <StyledTypo2>$1.99</StyledTypo2>
              </Box>
              <List sx={{ 
                width:"100%", color: "#FFFFFF", flexGrow: 1, 
                padding:"0px 30px", boxSizing:"border-box",  gap:"20px",
                display:"flex", flexDirection:"column",
              }}>
                <ListItem sx={{ display: "flex", alignItems: "center", padding:"0px", gap:"5px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                  <ListItemText 
                    sx={{ fontFamily:"Nexa-Regular", fontWeight:400, fontSize:"16px" }} 
                    primary="Access to Premium Episodes"
                  />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Box>
      </Dialog>
    )
  }

  renderSubscribePlansInfo() {
    return (
      <Dialog
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(5px)',
            },
          },
        }}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "1682px",
            width: "1682px",
            background: "#44403C",
            height:"854px",
            borderRadius: '33px',
            boxSizing:"border-box"
          },
        }}
        data-test-id="closeSubscribePlansInfoDialogTestBtn"
        open={this.state.openSubscribePlansDialog}
        onClose={() => this.handleCloseSubscribePlansInfoDialog()}
      >
        <IconButton sx={{ alignSelf:"flex-end", position:"relative", top:"15px", right:"15px", }} onClick={this.handleCloseSubscribePlansInfoDialog}>
          <CloseIcon sx={{ color:"#FFFFFF" }} />
        </IconButton>
        <Box style={{
          boxSizing:"border-box", gap:"20px",
          display:"flex", flexDirection:"column", padding:"5px 50px 30px 50px", 
        }}>
          <Box style={{
            display:"flex", alignItems:"center", width:"100%", gap:"40px",
          }}>
            <img src={podcastImage} style={{ borderRadius:"3px", width:"130px", height:"125px" }} />
            <Box style={{
              display:"flex", flexDirection:"column", gap:"10px",
            }}>
              <Typography style={{ fontSize:"30px", fontFamily:"Nexa-Heavy", fontWeight:900, color:"#FFF", }}>The True Podcast</Typography>
              <Typography style={{ fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"18px", color:"#FFF", }}>1.5K Reviews</Typography>
              <Box style={{ display:"flex", alignItems:"center",gap:"5px", }}>
                <LocationOnIcon sx={{ color:"#FFFFFF", height:"20px", width:"20px" }}/>
                <Typography style={{ fontSize:"18px", fontFamily:"Nexa-Heavy", fontWeight:900, color:"#FFF", }}>London, UK</Typography>
              </Box>
            </Box>
          </Box>
          <Typography style={{ fontSize:"24px", fontFamily:"Nexa-Heavy", fontWeight:900, color:"#FFF", }}>Select a Plan</Typography>
          <Box style={{ 
            width:"100%", marginTop:"25px", padding:"0px 20px", flexWrap:"wrap",
            display:"flex", justifyContent:"space-between", gap:"15px",
          }}>
            <Box style={{
              display:"flex", flexDirection:"column", borderRadius:"15px",
              width:"333px", height:"512px", background:"#1C1917",
            }}>
              <Box style={{ 
                width:"100%", display:"flex",
                flexDirection:"column", justifyContent:"space-evenly", flexGrow:1,
              }}>
                <StyledTypo1>TIER 1</StyledTypo1>
                <StyledTypo2>$1.99</StyledTypo2>
              </Box>
              <List sx={{ 
                width:"100%", color: "#FFFFFF", flexGrow: 1, 
                display:"flex", flexDirection:"column",
                padding:"0px 30px", boxSizing:"border-box",  gap:"20px",
              }}>
                <ListItem sx={{ display: "flex", alignItems: "center", gap:"5px", padding:"0px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                  <ListItemText 
                    primary="Access to Premium Episodes"
                    sx={{ fontFamily:"Nexa-Regular", fontWeight:400, fontSize:"16px" }} 
                  />
                </ListItem>
              </List>
              <Box style={{ 
                display:"flex", alignItems:"flex-end", justifyContent:"center",
                paddingBottom:"40px"
              }}>
                <Button style={{
                  width:"296px", height:"44px", borderRadius:"8px",
                  background:"#FF0807", color:"#FFFFFF", textTransform:"none",
                  fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"18px" 
                }}
                >
                  Select Plan
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider style={{ borderColor: '#FFFFFF' }} />
        <Box style={{ display:"flex", alignSelf:"center", gap:"22px", padding:"20px 0px" }}>
          <Button style={{
            width:"120px", height:"56px", borderRadius:"8px",
            color:"#FFFFFF", background:"#000000", borderColor:"#030303",
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"16px",
            textTransform:"none",
          }}
            onClick={this.handleCloseSubscribePlansInfoDialog}
          >
            Cancel
          </Button>
          <Button style={{
            width:"120px", height:"56px", borderRadius:"8px",
            color:"#FFFFFF", background:"#B2B3B3", textTransform:"none",
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"16px",
          }}
            data-test-id="newTierSuccessTestBtn"
            onClick={() => this.handleNewTierSuccessPopup()}
          >
            Save
          </Button>
          {this.renderCardPaymentsDatails()}
          {this.renderNewTierSuccessPopup()}
        </Box>
      </Dialog>
    )
  }

  renderNewTierSuccessPopup() {
    return (
      <Dialog
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(5px)',
            },
          },
        }}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "600px",
            width: "600px",
            background: "#44403C",
            height:"348px",
            borderRadius: '8px 8px 32px 8px',
            boxSizing:"border-box"
          },
        }}
        data-test-id="closeNewTierSuccessDialogTestBtn"
        open={this.state.openNewTierSuccessPopup}
        onClose={() => this.handleCloseNewTierSuccessPopup()}
      >
        <IconButton sx={{ position:"relative", top:"15px", alignSelf:"flex-end", right:"15px", }} onClick={this.handleCloseNewTierSuccessPopup}>
          <CloseIcon sx={{ color:"#FFFFFF" }} />
        </IconButton>
        <Box style={{
          display:"flex", flexDirection:"column", gap:"12px", alignItems:"center",
          paddingTop:"25px"
        }}>
          {
            this.state.isCardDetailsNeededForSubscription ? (
              <StarRoundedIcon sx={{ color:"#FF0807", width:"45px", height:"45px" }} />
            ) : (
              <AttachMoney sx={{ color:"#FF0807", width:"45px", height:"45px" }} />
            )
          }
          <Typography style={{
            color:"#FFFFFF",
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"24px",
          }}>
            Awesome! 
          </Typography>
          <Typography style={{
            fontFamily:"Inter", fontWeight:400, fontSize:"20px",
            color:"#FFFFFF",
          }}>
            Now you’ll pay $9.99 per month for this new tier
          </Typography>
          <Divider style={{ width:"100%", borderColor: '#FFFFFF', marginTop:"14px" }} />
          <Box style={{
            display:"flex", gap:"12px", alignSelf:"center", paddingTop:"22px"
          }}>
            <Button style={{
              width:"fit-content", minWidth:"152px", height:"56px", borderRadius:"8px",
              background:"#030303", color:"#FFFFFF", textTransform:"none",
              fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"16px" 
            }}
              data-test-id="goToDashBaordTestBtn"
              onClick={() => this.goToDashBoard()}
            >
              Go to Dashboard
            </Button>
            <Button style={{
              width:"152px", height:"56px", borderRadius:"8px",
              background:"#FF0807", color:"#FFFFFF", textTransform:"none",
              fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"16px" 
            }}
              data-test-id="goToChannelTestBtn"
              onClick={this.goToChannel}
            >
              Go To Channel
            </Button>
          </Box>
        </Box>
      </Dialog>
    )
  }

  renderCardPaymentsDatails() {
    return (
      <Dialog
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(5px)',
            },
          },
        }}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "1018px",
            width: "1018px",
            background: "#44403C",
            borderRadius: '8px 8px 32px 8px',
            height:"797px",
            boxSizing:"border-box"
          },
        }}
        data-test-id="closePaymentsDetailsDialogTestBtn"
        open={this.state.openPaymentsDetailsPopup}
        onClose={() => this.handleClosePaymentsDetailsPopup()}
      >
        <IconButton sx={{ position:"relative", top:"15px", alignSelf:"flex-end", right:"15px", }} onClick={this.handleClosePaymentsDetailsPopup}>
          <CloseIcon sx={{ color:"#FFFFFF" }} />
        </IconButton>
        <Box style={{
          display:"flex", padding:"10px 45px 35px 35px", justifyContent:"space-between",
          gap:"25px", width:"100%", boxSizing:"border-box"
        }}>
          <Box style={{
            display:"flex", flexDirection:"column", background:"#030303",
            width:"439px", height:"599px", borderRadius:"24px",
          }}>
            <Box style={{ display:"flex", height:"96px", alignItems:"center" }}>
              <Typography style={{
                fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"24px",
                color:"#FFFFFF", paddingLeft:"40px"
              }}>
                Card Details
              </Typography>
            </Box>
            <Divider style={{ borderColor:"#FFFFFF" }} />
            <Box style={{
              display:"flex", flexDirection:"column", gap:"11px", padding:"20px",
              width:"100%", boxSizing:"border-box"
            }}>
              <Typography style={{...webStyles.cardText}}>
                Card Number
              </Typography>
              <TextField
                sx={{
                  input: {
                    fontFamily:"Nexa-Heavy", fontSize:"14px", fontWeight:900,
                    color:"#FFFFFF", width:"100%", marginLeft:"10px"
                  },
                  display:"flex", border:"1px solid #CBD5E1", justifyContent:"center",
                  width:"392px", height:"56px", borderRadius:"8px"
                }}
                variant="standard"
                InputProps={{ disableUnderline: true }}
              />
              <Typography style={{...webStyles.cardText}}>
                Name on card
              </Typography>
              <TextField
                sx={{
                  input: {
                    color:"#FFFFFF", width:"100%", marginLeft:"10px",
                    fontFamily:"Nexa-Heavy", fontSize:"14px", fontWeight:900,
                  },
                  display:"flex", border:"1px solid #CBD5E1", borderRadius:"8px",
                  width:"392px", height:"56px", justifyContent:"center",
                }}
                variant="standard"
                InputProps={{ disableUnderline: true }}
              />
              <Box style={{ display:"flex", gap:"10px" }}>
                <Box style={{
                  display:"flex", flexDirection:"column", gap:"14px"
                }}>
                  <Typography style={{...webStyles.cardText}}>
                    Expire Date
                  </Typography>
                  <TextField
                    sx={{
                      input: {
                        color:"#FFFFFF",  marginLeft:"10px", fontSize:"14px",
                        fontFamily:"Nexa-Heavy", width:"100%", fontWeight:900,
                      },
                      display:"flex", border:"1px solid #CBD5E1",
                      borderRadius:"8px", justifyContent:"center",
                      width:"191px", height:"56px", 
                    }}
                    InputProps={{ disableUnderline: true }}
                    variant="standard"
                    placeholder="MM/YY"
                  />
                </Box>
                <Box style={{
                  display:"flex", flexDirection:"column", gap:"14px"
                }}>
                  <Typography style={{...webStyles.cardText}}>
                    Security Code
                  </Typography>
                  <TextField
                    variant="standard"
                    placeholder="0000"
                    sx={{
                      input: {
                        color:"#FFFFFF",  marginLeft:"10px", fontSize:"14px",
                        fontFamily:"Nexa-Heavy", width:"100%", fontWeight:900,
                      },
                      display:"flex", border:"1px solid #CBD5E1",
                      width:"190px", height:"56px", 
                      borderRadius:"8px", justifyContent:"center",
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
              </Box>
              <Typography style={{...webStyles.cardText}}>
                Billing Address
              </Typography>
              <TextField
                sx={{
                  input: {
                    width:"100%", marginLeft:"10px", fontFamily:"Nexa-Heavy",
                    fontSize:"14px", fontWeight:900, color:"#FFFFFF",
                  },
                  display:"flex", border:"1px solid #CBD5E1", borderRadius:"8px",
                  width:"392px", height:"56px", justifyContent:"center",
                }}
                InputProps={{ disableUnderline: true }}
                variant="standard"
              />
              <Box style={{
                borderLeft:"4px solid #FF0807", display:"flex", gap:"4px",
                borderRadius:"4px", width:"360px", height:"50px",
                alignItems:"center", paddingLeft:"15px", cursor:"pointer"
              }}>
                <Typography style={{
                  ...webStyles.cardText, 
                  textDecoration:"underline", textDecorationColor:"#1D4ED8"
                }}>
                  Edit
                </Typography>
                <Typography style={{
                  fontFamily:"Nexa-Heavy", fontSize:"14px", fontWeight:900,
                  color: "#1D4ED8", textDecoration:"underline"
                }}>
                  Billing Details
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{
            display:"flex", flexDirection:"column", background:"#030303",
            width:"439px", height:"599px", borderRadius:"24px"
          }}>
            <Box style={{ display:"flex", alignItems:"center", height:"96px" }}>
              <Typography style={{
                color:"#FFFFFF", paddingLeft:"40px",
                fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"24px",
              }}>
                Address Information
              </Typography>
            </Box>
            <Divider style={{ borderColor:"#FFFFFF" }} />
            <Box style={{
              display:"flex", flexDirection:"column", gap:"11px",
              width:"100%", boxSizing:"border-box", padding:"20px",
            }}>
              <Typography style={{...webStyles.cardText}}>
                Street Address
              </Typography>
              <TextField
                sx={{
                  input: {
                    marginLeft:"10px", fontFamily:"Nexa-Heavy",
                    fontSize:"14px", fontWeight:900, color:"#FFFFFF", width:"100%",
                  },
                  display:"flex", height:"56px", borderRadius:"8px",
                  width:"392px", border:"1px solid #CBD5E1", justifyContent:"center",
                }}
                InputProps={{ disableUnderline: true }}
                variant="standard"
              />
              <Typography style={{...webStyles.cardText}}>
                Street Address (Optional)
              </Typography>
              <TextField
                sx={{
                  input: {
                    color:"#FFFFFF", width:"100%", fontFamily:"Nexa-Heavy", marginLeft:"10px",
                    fontSize:"14px", fontWeight:900,
                  },
                  display:"flex", border:"1px solid #CBD5E1", 
                  borderRadius:"8px", width:"392px", height:"56px", justifyContent:"center",
                }}
                InputProps={{ disableUnderline: true }}
                variant="standard"
              />
              <Box style={{ display:"flex", gap:"10px" }}>
                <Box style={{
                  display:"flex", gap:"14px", flexDirection:"column",
                }}>
                  <Typography style={{...webStyles.cardText}}>
                    Poste Code
                  </Typography>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      input: {
                        color:"#FFFFFF", marginLeft:"10px", fontSize:"14px",
                        fontFamily:"Nexa-Heavy", width:"100%", fontWeight:900,
                      },
                      width:"191px", height:"56px",
                      display:"flex", border:"1px solid #CBD5E1",
                      borderRadius:"8px", justifyContent:"center",
                    }}
                    variant="standard"
                  />
                </Box>
                <Box style={{
                  display:"flex", flexDirection:"column", gap:"14px"
                }}>
                  <Typography style={{...webStyles.cardText}}>
                    City
                  </Typography>
                  <TextField
                    variant="standard"
                    sx={{
                      input: {
                        color:"#FFFFFF",  marginLeft:"10px", fontSize:"14px",
                        fontFamily:"Nexa-Heavy", width:"100%", fontWeight:900,
                      },
                      display:"flex", border:"1px solid #CBD5E1",
                      borderRadius:"8px", justifyContent:"center",
                      width:"190px", height:"56px",
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
              </Box>
              <Typography style={{...webStyles.cardText}}>
                Country
              </Typography>
              <TextField
                select
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon sx={{ color:"#FFFFFF" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  input: {
                    fontFamily:"Nexa-Heavy", marginLeft:"10px",
                    fontSize:"14px", fontWeight:900, width:"100%",
                  },
                  display:"flex", border:"1px solid #CBD5E1", width:"392px", height:"56px",
                  borderRadius:"8px", justifyContent:"center",
                  "& fieldset": { border: 'none' },
                  '& .MuiOutlinedInput-root': {
                    color:"#FFFFFF",
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: '#030303',
                        color:"#FFFFFF"
                      },
                    },
                  },
                }}
              >
                <MenuItem key={"India"} value={"India"}>
                  {"India"}
                </MenuItem>
              </TextField>
            </Box>
          </Box>
        </Box>
        <Divider style={{ borderColor:"#FFFFFF" }} />
        <Box style={{ display:"flex", gap:"12px", padding:"26px 0px", alignSelf:"center" }}>
          <Button style={{
            width:"fit-content", minWidth:"187px", height:"56px", borderRadius:"8px",
            background:"#030303", color:"#FFFFFF", textTransform:"none",
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"16px" 
          }}
            onClick={this.handleClosePaymentsDetailsPopup}
            data-test-id="newNowTestBtn"
          >
            Not Now
          </Button>
          <Button style={{
            width:"152px", height:"56px", borderRadius:"8px",
            background:"#FF0807", color:"#FFFFFF", textTransform:"none",
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"16px" 
          }}
            data-test-id="setUpTestBtn"
            onClick={() => this.handleCardSetUp()}
          >
            Set Up
          </Button>
        </Box>
      </Dialog>
    )
  }

  renderSupportHostDialog() {
    return (
      <Dialog
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(5px)',
            },
          },
        }}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "600px",
            width: "600px",
            background: "#44403C",
            borderRadius: '8px',
            height:"806px",
            boxSizing:"border-box"
          },
        }}
        data-test-id="closeSupportHostDialogTestBtn"
        open={this.state.openSupportHostDialog}
        onClose={() => this.handleCloseSupportHostDialog()}
      >
        <IconButton sx={{ position:"relative", alignSelf:"flex-end", right:"15px", top:"15px", }} onClick={this.handleCloseSupportHostDialog}>
          <CloseIcon sx={{ color:"#FFFFFF" }} />
        </IconButton>
        <Typography style={{
          fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"24px",
          color:"#FFFFFF", paddingLeft:"40px", marginTop:"14px"
        }}>
          Support host
        </Typography>
        <Box style={{
          display:"flex", justifyContent:"space-between", padding:"20px 40px",
        }}>
          <Box style={{
            display:"flex", width:"160px", height:"272px", background:"#FFFFFF", gap:"15px",
            borderRadius:"26px", border: "1px solid #B2B3B3", flexDirection:"column",
            alignItems:"center"
          }}>
            <Box style={{ 
              background:"#FF0807", height:"56px", borderRadius:"14px", marginTop:"26px",
              width:"56px", display:"flex", justifyContent:"center", alignItems:"center",
            }}>
              <PlayCircleOutlineIcon style={{ color:"#FFFFFF", height:"33px", width:"33px" }} />
            </Box>
            <Typography style={{
              fontFamily:"Inter", fontSize:"24px", fontWeight:900, color:"#3C3C3C" 
            }}>
              Send Tip
            </Typography>
            <Typography style={{
              fontFamily:"Nexa-Heavy", fontSize:"60px", fontWeight:900, color:"#1B223C" 
            }}>
              $1
            </Typography>
          </Box>
          <Box style={{
            display:"flex", width:"160px", height:"272px", background:"#000000",
            borderRadius:"26px", border: "1px solid #B2B3B3", alignItems:"center",
            flexDirection:"column", gap:"15px"
          }}>
            <Box style={{ 
              borderRadius:"14px", justifyContent:"center", alignItems:"center", 
              background:"#FFFFFF", height:"56px", width:"56px", display:"flex", marginTop:"26px",
            }}>
              <PlayCircleOutlineIcon style={{ color:"#030303", width:"33px", height:"33px" }} />
            </Box>
            <Typography style={{
              fontFamily:"Inter", fontSize:"24px", fontWeight:900, color:"#FFFFFF" 
            }}>
              Send Tip
            </Typography>
            <Typography style={{
              fontFamily:"Nexa-Heavy", fontSize:"60px", fontWeight:900, color:"#FFFFFF" 
            }}>
              $5
            </Typography>
          </Box>
          <Box style={{
            display:"flex", width:"160px", height:"272px", background: "#FF0807",
            borderRadius:"26px", border: "1px solid #B2B3B3", alignItems:"center",
            flexDirection:"column", gap:"15px"
          }}>
            <Box style={{ 
              background:"#000000", height:"56px", width:"56px", display:"flex",
              borderRadius:"14px", justifyContent:"center", alignItems:"center", marginTop:"26px"
            }}>
              <PlayCircleOutlineIcon style={{ color:"#FFFFFF", width:"33px", height:"33px" }} />
            </Box>
            <Typography style={{
              fontFamily:"Inter", fontSize:"24px", color:"#FFFFFF", fontWeight:900,
            }}>
              Send Tip
            </Typography>
            <Typography style={{
              fontFamily:"Nexa-Heavy", fontSize:"60px", fontWeight:900, color:"#FFFFFF" 
            }}>
              $10
            </Typography>
          </Box>
        </Box>
        <Box style={{
          display:"flex", flexDirection:"column", gap:"14px", padding:"0px 38px 20px 38px",
        }}>
          <Typography style={{
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"14px", color:"#FFFFFF",
          }}>
            Custom Tip
          </Typography>
          <TextField
            variant="standard"
            sx={{
              input: {
                color:"#FFFFFF",  marginLeft:"10px", fontSize:"16px",
                fontFamily:"Nexa-Regular", width:"100%", fontWeight:400,
              },
              display:"flex", border:"1px solid #FFFFFF", background:"#030303",
              borderRadius:"8px", width:"520px", height:"36px",
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Box>
        <Box style={{
          display:"flex", flexDirection:"column", gap:"14px", padding:"0px 38px 20px 38px",
        }}>
          <Typography style={{
            fontFamily:"Nexa-Heavy", fontWeight:900, color:"#FFFFFF", fontSize:"14px"
          }}>
            Description
          </Typography>
          <TextField
            variant="outlined"
            multiline
            rows={4}
            sx={textFieldStylesSupportHost}
          />
        </Box>
        <Divider style={{ borderColor:"#FFFFFF", paddingTop:"30px" }} />
        <Box style={{ display:"flex", padding:"25px 30px", gap:"14px" }}>
          <Button style={{
            width:"268px", height:"56px", textTransform:"none",
            borderRadius:"8px", border:"1px solid #030303",
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"16px",
            background:"#030303", color:"#FFFFFF"
          }}
            onClick={this.handleCloseSupportHostDialog}
          >
            Cancel
          </Button>
          <Button style={{
            width:"268px", height:"56px", textTransform:"none",
            fontFamily:"Nexa-Heavy", fontWeight:900, fontSize:"16px",
            borderRadius:"8px", background:"#B2B3B3", color:"#FFFFFF"
          }}>
            Send
          </Button>
        </Box>
      </Dialog>
    )
  }

  BackButton = ()=>{
    return <>
      <BackButtonFrame >
        <BackIcon
          data-test-id="backButton"
          src={leftArrow}
          style={{
            width: "100%",
            height: "100%"
          }}
          alt="Arrow"
          onClick={() => { this.showEpisodeComments(false, '') }}
        />
        <TextBackButton>Podcast Episode</TextBackButton>
      </BackButtonFrame>

    </>
  }

  // Customizable Area End
  
  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        <div style={webStyles.container}>
          <StyledWrapperSetting style={{backgroundColor: `${this.getBackgroundColor()}`}}>
            {this.state.EpisodeComments && <Box>
              {this.BackButton()}
            </Box>}
            <Box className="header-section" style={{backgroundColor: `${this.getSecondaryColor()}`}}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" gap="20px">
                <Box display="flex" flexDirection="row" gap="20px">
                  <img src={this.handleImageRender()} alt="Podcast" className="podacst-image" />
                  <Box className="podcast-header-content">
                    <Typography style={{ color: `${this.getTextColor()}`, fontWeight: 900, width:"fit-content", fontSize: "40px", fontFamily: "Nexa-Heavy" }}>{this.state.saveEpisodeListData.podcast.data.attributes.name}</Typography>
                    <Typography className="podcast-name" style={{color: `${this.getTextColor()}`}}>{this.state.saveEpisodeListData.podcast.data.attributes.subtitle}</Typography>
                    <Box 
                      display="flex" alignItems="center"
                      onClick={() => this.openAllReviewsScreen()}
                      data-test-id="showRatingScreenTestBtn"
                      style={{ width:"fit-content", cursor:"pointer" }}
                    >
                      <Rating 
                        sx={{ 
                          color: this.getTextColor(),
                          '& .MuiSvgIcon-root': {
                            fontSize: '31px', 
                          },
                        }} 
                        emptyIcon={<StarBorder sx={{ color: this.getTextColor() }} fontSize="inherit" />}
                        max={5}
                        value={this.state.saveEpisodeListData?.podcast?.data.attributes?.ratings?.ratings} 
                        precision={0.5} readOnly
                      />
                    </Box>
                    
                    <Box display="flex" gap="8px">
                      <Button style={{ width:"164px", height:"44px", }} variant="outlined" data-test-id="rate-testID" onClick={this.handleOpenRateOrReview} className="left-section-buttons">{configJSON.rateOrReviewText}</Button>
                      <Dialog
                      slotProps={{
                        backdrop: {
                          sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          },
                        },
                      }}
                      sx={{
                        "& .MuiDialog-paper": {
                          maxWidth: "600px",
                          width: "600px",
                          background: "#44403C",
                          borderRadius: '8px 8px 32px 8px',
                        },
                      }}
                      open={this.state.openRateOrReview}
                      onClose={this.handleCloseRateOrReview}
                      >
                      <Box data-test-id="rate-review-testID" onClick={this.handleCloseRateOrReview} display={"flex"} justifyContent={"flex-end"} paddingTop="20px" paddingRight="30px">
                        <CloseIcon sx={{ color: "white" , cursor:"pointer"}} />
                      </Box>
                      <Box sx={{padding:"0px 40px"}}>
                        <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"20px"}}>
                          {configJSON.rateThisPodcast}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          {[...Array(this.state.totalStars)].map((_, index) => (
                            <IconButton disabled={this.checkIfDisabled4()} key={`empty-${index}`} style={{ padding: 0 }} data-test-id="ratingStarsTestBtn" onClick={() => this.handleProvideStars(index)}>
                              {this.renderRatingStar(index)}
                            </IconButton>
                          ))}
                      </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "24px" , marginTop:"16px"}} component="label">
                          {configJSON.leaveAReview}
                          </Typography>
                          <TextField
                            data-test-id="review"
                            name="review"
                            value={this.state.review}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            multiline
                            rows={4} 
                            disabled={this.checkIfDisabled4()}
                            sx={textFieldStylesReview}
                          />
                        </Box>
                      </Box>
                      <Divider sx={{ marginTop:"74px", borderColor: 'white' }} />
                      <Box display={"flex"} margin="30px 24px 30px 24px" gap={2} justifyContent={"flex-end"}>
                      <Button  onClick={this.handleCloseRateOrReview} sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "#fff",
                        background: "black",
                        height:"56px",
                        width:'120px',
                        "&:hover": {
                            background: "#000", 
                        }
                        }}
                        variant="contained"
                        >
                          {configJSON.cancelText}
                        </Button>
                        <Button
                          disabled={this.checkIfDisabled3()}
                          onClick={() => this.postRateOrReviewData()}
                          data-test-id="rateOrReviewTestBtn"
                          sx={{
                            ...buttonStyles,
                            border: "none",
                            color: "#FFFFFF",
                            background: "#FF0807" ,
                            height:"56px",
                            width:'120px',
                            "&:hover": {
                                background:  "#FF0807" ,
                            },
                            "&.Mui-disabled": {
                                backgroundColor: "#B2B3B3",
                                color: "#fff",
                            },
                            }}
                          variant="contained"
                        >
                          {configJSON.publishText}
                        </Button>
                      </Box>
                    </Dialog>
                      <Button style={{width:"164px", height:"44px", }} variant="outlined" data-test-id="share-testID" onClick={this.handleOpenShareChannel} className="left-section-buttons">{configJSON.shareChannelModal}</Button>
                      <Modal
                        open={this.state.open}
                        onClose={this.handleCloseShareChannel}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{background:"rgba(0,0,0,0.5)" ,  backdropFilter: 'blur(5px)',}}
                      >
                        <Box sx={ShareChannelModalstyle}>
                          <Box className="modalBox" >
                            <IconButton  data-test-id="share-close-testID" onClick={this.handleCloseShareChannel}>
                              <CloseIconModal />
                            </IconButton>
                            <ShareChannelModal>
                              <ShareIcon aria-label="share">
                                <Share />
                              </ShareIcon>
                              <ShareChannelText variant="h6" mb={6} mt={4}>{configJSON.shareChannelModal}</ShareChannelText>
                            </ShareChannelModal>
                            <ShareChannelText variant="subtitle1" gutterBottom>{configJSON.channelUrlText}</ShareChannelText>
                            <UrlBox>
                              <ChannelTextFeild id="podcastUrl">{this.state.saveEpisodeListData?.podcast?.data?.attributes?.public_url}</ChannelTextFeild>
                              <CopyButton data-test-id="copyChannelUrlTestBtn" onClick={() => this.handleCopyChannelUrl()} ><ContentCopy />{configJSON.copyText}</CopyButton>
                            </UrlBox>
                            <ShareChannelText variant="subtitle1" mt={4} gutterBottom>{configJSON.embedText}</ShareChannelText>
                            <LinkContainer>
                              <EmbededTextFeild id="embedUrl">{this.state.saveEpisodeListData?.podcast?.data?.attributes?.embed_code}</EmbededTextFeild>
                              <CopyButton2 data-test-id="copyEmbedUrlTestBtn" onClick={() => this.handleCopyEmbedUrl()} ><ContentCopy />{configJSON.copyText}</CopyButton2>
                            </LinkContainer >
                          </Box>
                        </Box>
                      </Modal>
                    </Box>
                    <Typography style={{color: `${this.getTextColor()}`}} className="total-reviews">{this.state.saveEpisodeListData?.podcast?.data.attributes?.ratings?.reviews} Reviews</Typography>
                    <Box display="flex" gap="10px">
                      {!this.state.saveEpisodeListData?.podcast?.data?.attributes?.follower?.following ?
                       <Button variant="contained" className="left-section-bottom-buttons" data-test-id="followButton" sx={{ backgroundColor: `${this.getButtonColor()}`, "&:hover": { backgroundColor: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`} }} onClick={() => this.handleFollowButton(this.state.saveEpisodeListData?.podcast?.data?.id)}>Follow</Button> 
                      :  <Button variant="contained" className="left-section-bottom-buttons" data-test-id="unfollowButton" sx={{ backgroundColor: "#030303", "&:hover": { backgroundColor: "#030303", borderColor: "#030303"} }} onClick={() => this.handleUnFollowButton(this.state.saveEpisodeListData?.podcast?.data?.attributes?.follower?.follow_id)}>Followed</Button>}
                      <Button variant="contained" className="left-section-bottom-buttons" data-test-id="subscribePlanTestBtn" onClick={() => this.handleOpenSubscribePlansDialog()} sx={{ backgroundColor: `${this.getButtonColor()}`, "&:hover": {backgroundColor: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`} }}><AttachMoney style={{ width: "24px", height: "24px" }} />Subscribe</Button>
                      <IconButton data-test-id="channelInfoTestBtn" onClick={() => this.handleOpenChannelPlansInfoDialog()} className="podcast-header-info-icon" sx={{ backgroundColor: `${this.getButtonColor()}`, "&:hover": {backgroundColor: `${this.getButtonColor()}` } }}><InfoOutlined /></IconButton>
                    </Box>
                    { this.state.openSubscribePlansDialog && this.renderSubscribePlansInfo() }
                    { this.state.openChannelInfoPlansDialog && this.renderChannelPlansInfo() }
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-start" style={{ marginTop: '40px' }}>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box display="flex" flexDirection="row" gap="20px">
                      <Box display="flex" flexDirection="column" gap="10px">
                        <Button variant="outlined" style={{ backgroundColor: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`, color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><Laptop style={{ width: "24px", height: "20px" }} />Webinar</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><img src={tshirtIcon} />Buy Swag</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }} data-test-id="supportHostTextBtn" onClick={() => this.handleOpenSupportHostDialog()}><AttachMoney style={{ width: "24px", height: "24px" }} />Support Host</Button>
                      </Box>
                      {this.renderSupportHostDialog()}
                      <Box display="flex" flexDirection="column" gap="10px">
                        <Button variant="outlined" style={{ backgroundColor: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`, color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><ShoppingCartOutlined style={{ width: "24px", height: "20px" }} />Buy Now</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><MenuBook style={{ width: "24px", height: "20px" }} />Learn More</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px", textTransform: 'capitalize', fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><LocalOfferOutlined style={{ width: "24px", height: "20px" }} />Get Offer</Button>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="10px">
                      <Button 
                        onClick={this.onSendHandler}
                        variant="outlined" style={{ color: `${this.getTextColor()}`, width: '345px', height: "44px", borderRadius: "8px", border: `1px solid ${this.getTextColor()}`, fontWeight: "bold", textTransform: "capitalize", fontFamily: "Nexa-Heavy" }} startIcon={<Phone sx={{color: `${this.getTextColor()}`}} />}>
                        Contact Host
                      </Button>
                    </Box>
                    <Dialog
                      slotProps={{
                        backdrop: {
                          sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          },
                        },
                      }}
                      sx={{
                        "& .MuiDialog-paper": {
                          maxWidth: "600px",
                          width: "600px",
                          background: "#44403C",
                          borderRadius: '8px',
                        },
                      }}
                      open={this.state.isContactHost}
                      onClose={this.onCancelHandler}
                      >
                      <Box onClick={this.onCancelHandler} display={"flex"} justifyContent={"flex-end"} paddingTop="20px" paddingRight="30px">
                        <CloseIcon sx={{ color: "white" , cursor:"pointer"}} />
                      </Box>
                      <Box sx={{padding:"0px 40px"}}>
                        <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"20px"}}>
                          Contact Host
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" }} component="label">
                            Name *
                          </Typography>
                          <TextField
                            data-test-id="name"
                            value={this.state.name}
                            name="name"
                            onChange={this.handleInputChange}
                            variant="outlined"
                            sx={textFieldStyles}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" , marginTop:"16px"}} component="label">
                            Title *
                          </Typography>
                          <TextField
                            data-test-id="playlistName"
                            name="title"
                            value={this.state.title}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            sx={textFieldStyles}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" , marginTop:"16px"}} component="label">
                            Description *
                          </Typography>
                          <TextField
                            data-test-id="description"
                            name="description"
                            value={this.state.description}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            multiline
                            rows={4} 
                            sx={textFieldStyles}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" , marginTop:"16px"}} component="label">
                            Phone Number (optional)
                          </Typography>
                          <TextField
                            name="phone_num"
                            data-test-id="phoneNum"
                            value={this.state.phone_num}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            inputProps={{ maxLength: 10 }}
                            sx={textFieldStyles}
                          />
                        </Box>
                        <div style={{color : "red", fontFamily : "Nexa-Heavy", marginTop:'8px'}}>{this.state.errorMsg}</div>
                      </Box>
                      <Divider sx={{ marginTop:"74px", borderColor: 'white' }} />
                      <Box display={"flex"} margin="30px 24px 30px 24px" gap={2} justifyContent={"space-between"}>
                      <Button onClick={this.onCancelHandler} sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "black",
                        height:"56px",
                        width:'268px',
                        "&:hover": {
                            background: "black", 
                        }
                        }}
                        variant="contained"
                        >
                          {configJSON.cancelText}
                        </Button>
                        <Button
                          onClick={this.saveContactHostData}
                          disabled={this.checkIfDisabled2()}
                          sx={{
                            ...buttonStyles,
                            border: "none",
                            color: "white",
                            background: "#FF0807" ,
                            height:"56px",
                            width:'268px',
                            "&:hover": {
                                background:  "#FF0807" ,
                            },
                            "&.Mui-disabled": {
                                backgroundColor: "#B2B3B3",
                                color: "white",
                            },
                            }}
                          variant="contained"
                        >
                          {configJSON.saveText}
                        </Button>
                      </Box>
                    </Dialog>
                    <Dialog
                      slotProps={{
                        backdrop: {
                          sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          },
                        },
                      }}
                      sx={{
                        "& .MuiDialog-paper": {
                          maxWidth: "600px",
                          width: "600px",
                          background: "#44403C",
                          borderRadius: '8px 8px 32px 8px',
                        },
                      }}
                      open={this.state.contactSuccess}
                      onClose={this.onCloseHandler}
                      >
                      <Box onClick={this.onCloseHandler} display={"flex"} justifyContent={"flex-end"} paddingTop="20px" paddingRight="30px">
                        <CloseIcon sx={{ color: "white" , cursor:"pointer"}} />
                      </Box>
                      <Box sx={{padding:"0px 113px", textAlign:"center"}}>
                        <img src = {messageLogo} style={{ marginBottom:"10px"}}/>
                        <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"10px"}}>
                          Message sent!
                        </Typography>
                        <Typography sx={{...webStyles.titledesc, fontSize:"20px"}}>
                          Your message has been sent to the host. They will get back to you soon!                       
                        </Typography>
                      </Box>
                      <Divider sx={{ marginTop:"24px", borderColor: 'white' }} />
                      <Box display={"flex"} margin="24px 0px 52px 0px" justifyContent={"center"}>
                      <Button onClick={this.onCloseHandler} sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "black",
                        height:"56px",
                        width:'187px',
                        "&:hover": {
                            background: "black", 
                        }
                        }}
                        variant="contained"
                        >
                          {configJSON.closeText}
                        </Button>
                      </Box>
                    </Dialog>
                  </Box>
                </Box>
              </Box>
            </Box>
            {this.state.commentType !== "nestedComment" && this.renderTabs()}
            <Box sx={{ display: 'grid', gridTemplateColumns: '65% 35%', gap: '24px' }}>
              { !this.state.openReviewsScreen ? 
              <div className="scrollableWrapper" style={{ height: this.checkHeight4() }}>
                {this.state.commentType == "nestedComment" &&
                  <Box display="flex" alignItems="center" mt={3}>
                    <img data-test-id="backCommentBtn" src={goBack} onClick={this.handleGoBackComment} alt="Go Back" style={{ width: "25px" }} />
                    <Typography variant="h4" sx={{ color: '#fff', marginLeft: "15px", fontWeight: "bold" }}>
                      Back
                    </Typography>
                  </Box>}
                  {this.state.commentType == "nestedComment" ? this.renderComments(this.state.postComments,43,false,"mainCommnet"):<>
                    <Box className="left-body-section" sx={{  scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '64rem' }}>
                  {(this.state.selectedTab == "Episodes" && this.state.templateType === "Audio Focused" ) && this.renderPodcastCard()}
                  {(this.state.selectedTab == "Episodes" && this.state.templateType === "Video Focused" ) && this.renderPodcastCardForVideo()}
                  {(this.state.selectedTab == "Episodes & Community" && this.state.templateType === "Audio + Community Focused" ) && this.renderPostCardForSocialAudio()}
                  {(this.state.selectedTab == "Episodes & Community" && this.state.templateType === "Video + Community Focused" ) && this.renderPostCardForSocialVideo()}
                  {this.state.selectedTab == "Community" &&
                    <>
                      {this.renderCreatePost()}
                      {this.state.showSingleComment && this.state.communityListData.map((item: Post, key: number) => (
                        <>
                          {this.state.openEditPost[key] && this.renderEditedPost(key)}
                          {this.renderCommunitySingleComment(item, key)}
                        </>
                      ))}
                      {!this.state.showSingleComment && this.renderSingleComment(this.state.communityItem, 2)}
                    </>
                  }
                  {this.state.selectedTab == "ChannelInfo" && this.renderChannelInfo()
                  }
                </Box>
                {(this.state.selectedTab == "Episodes & Community" && this.state.templateType === "Audio + Community Focused") &&
                  <>
                    <Typography style={{
                      fontFamily:"Nexa-Heavy", fontSize:"30px", fontWeight:900,
                      color:`${this.getTextColor()}`, marginTop:"30px"
                    }}>
                      Episodes
                    </Typography>
                    <Box className="left-body-section" sx={{  scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '28rem' }}>
                      {this.renderPodcastCard()}
                    </Box>
                  </>
                }
                {(this.state.selectedTab == "Episodes & Community" && this.state.templateType === "Video + Community Focused") &&
                  <>
                    <Typography style={{
                      fontFamily:"Nexa-Heavy", fontSize:"30px", fontWeight:900,
                      color:`${this.getTextColor()}`, marginTop:"30px"
                    }}>
                      Episodes
                    </Typography>
                    <Box className="left-body-section" sx={{  scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '28rem' }}>
                      {this.renderPodcastCardForVideo()}
                    </Box>
                  </>
                }
                </>}
              </div> : (this.renderAllReviewsScreen())}
               <Box sx={{ minHeight: '100vh' }}>
                <Grid container direction="column" spacing={2} className="right-body-section">
                  <Typography style={{color: `${this.getTextColor()}`}} className="sponsored-title">Sponsored Recommendations</Typography>
                  <Grid item>
                    <Card className="recommended-cards" style={{backgroundColor: `${this.getSecondaryColor()}`, borderLeft: `4px solid ${this.getButtonColor()}`}}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: "Nexa-Heavy" }}>
                            The True
                          </Typography>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: "Nexa-Regular" }}>
                            Cost of Success Ep. 9
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_1}
                        alt="The True Episode"
                        sx={{
                          width: "20%",
                          height: "100%",
                          marginLeft: 'auto',
                          objectFit: 'cover',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards" style={{backgroundColor: `${this.getSecondaryColor()}`, borderLeft: `4px solid ${this.getButtonColor()}`}}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: 'Nexa-Heavy' }}>
                            All Consuming
                          </Typography>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: 'Nexa-Regular' }}>
                            Fantomy Ep. 12
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_2}
                        alt="The True Episode"
                        sx={{
                          width: "20%",
                          height: "100%",
                          marginLeft: 'auto',
                          objectFit: 'cover',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards" style={{backgroundColor: `${this.getSecondaryColor()}`, borderLeft: `4px solid ${this.getButtonColor()}`}}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: 'Nexa-Heavy' }}>
                            This American Life
                          </Typography>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: 'Nexa-Regular' }}>
                            Love your era Ep. 8
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_3}
                        alt="The True Episode"
                        sx={{
                          width: "20%",
                          height: "100%",
                          marginLeft: 'auto',
                          objectFit: 'cover',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                </Grid>
                <Box display="flex" flexDirection="column" gap="25px" >
                  <Typography sx={{ color: `${this.getTextColor()}`, marginTop: 4, fontFamily: 'Nexa-Heavy' }}>
                    Advertising
                  </Typography>
                  <Box
                    component="img"
                    src={ad_1}
                    alt="Burger Ad"
                    sx={{ height: '20rem', borderRadius: "13px" }}
                  />
                  <Box
                    component="img"
                    src={ad_2}
                    alt="Heinz Ad"
                    sx={{ height: '20rem', borderRadius: "13px" }}
                  />
                </Box>
              </Box>
            </Box>
          </StyledWrapperSetting>
          <Footer navigation={this.props.navigation} id={"1"} />
        </div>
      </>

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const BackButtonFrame = styled('div')({
  display:'flex', gap:'30px', maxHeight:'40px', alignItems:'center'
})

const TextBackButton = styled('strong')({
  fontFamily:'Nexa-Heavy',
  weight:'900',
  fontSize:'30px'


})

const BackIcon =styled('img')( {
  backgroundColor:'#44403C',
  maxWidth:'40px',
  maxHeight:'40px',
  borderRadius:'8px'
})

const buttonStyles = {
  borderRadius: "8px",
  fontFamily: "Nexa-Heavy",
  fontSize: "16px",
  textTransform: "capitalize",
  height: "34px",
};

const textFieldStyles = {
  '& .MuiInputBase-root': {
    backgroundColor: '#030303',
    borderRadius: '8px',
    color: 'white',
    fontFamily: 'Nexa-Heavy',
    fontWeight: 900,
    fontSize: '16px',
    border: '1px solid #FFFFFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

const textFieldStylesReview = {
  '& .MuiInputBase-root': {
    backgroundColor: '#030303',
    borderRadius: '8px',
    color: 'white',
    fontFamily: 'Nexa-Heavy',
    fontWeight: 900,
    fontSize: '16px',
    border: '1px solid #FFFFFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#FFFFFF",
  },
}

const textFieldStylesSupportHost = {
  '& .MuiInputBase-root': {
    backgroundColor: '#030303',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontFamily: 'Nexa-Regular',
    fontWeight: 400,
    fontSize: '16px',
    border: '1px solid #FFFFFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#FFFFFF",
  },
}


const StyledWrapperSetting = styled("div")({
  height: "auto",
  padding:"20px",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  "& .header-section": {
    backgroundColor: "#44403C",
    width: "102%",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    padding: "1rem",
    marginTop: "18px"
  },
  "& .podcast-name": {
    fontWeight: "900",
    fontSize: "16px",
    fontFamily: "Nexa-Heavy",
    width:"371px",
    textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"
  },
  "& .podacst-image": {
    width: "269px",
    height: "259px",
    borderRadius: "5px",
    marginTop: "13px",
    marginLeft: "8px"
  },
  "& .podcast-header-content": {
    display:"flex", flexDirection:"column", gap:"10px",
    color: "white",
    fontWeight: "bold",
  },
  "& .left-section-buttons": {
    color: "white",
    border: "1px solid white",
    borderRadius: "8px",
    fontWeight: "bold",
    textTransform: 'capitalize',
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      color: "white",
      border: "1px solid white",
    }
  },
  "& .podcast-header-info-icon": {
    width: "44px",
    height: '44px',
    borderRadius: "8px",
    color: "white",
  },
  "& .total-reviews": {
    fontWeight: "900",
    fontSize: "18px",
    fontFamily: "Nexa-Heavy"
  },
  "& .left-section-bottom-buttons": {
    color: "white",
    borderRadius: "8px",
    width: "164px",
    height: '44px',
    fontWeight: "bold",
    textTransform: 'capitalize',
    fontFamily: "Nexa-Heavy",
  },
  "& .left-body-section-tabs": {
    height: "52px",
    marginTop: "22px"
  },
  "& .section-tabs": {
    color: 'white',
    textTransform: "capitalize",
    borderColor: "#44403C",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: '#44403C',
      borderColor: "#44403C",
    },
    marginBottom: "0px"
  },
  "& .scrollableWrapper": {
    position: "relative",
    paddingRight: "20px",
    overflow: "hidden",
  },
  "& .left-body-section": {
    height: "100%",
    width: "100%",
    overflowY: "scroll",
    paddingRight: "10px",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#4D4D4D",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FF4500",
      borderRadius: "10px",
    },
  },
  "& .podcast-card": {
    display: 'flex',
    gap: "24px",
    height: "auto",
    "&:first-of-type": {
      marginTop: "0px",
    },
    "&:not(:first-of-type)": {
      marginTop: "20px",
    }
  },
  "& .podcast-card-comment": {
    display: 'flex',
    backgroundColor: '#44403C',
    gap: "24px",
    height: "auto",
    "&:first-of-type": {
      marginTop: "0px",
    }
  },
  "& .podcast-card-add-comment": {
    display: 'flex',
    backgroundColor: '#44403C',
    gap: "24px",
    height: "auto",
    borderRadius:"20px 20px 0px 0px",
    "&:first-of-type": {
      marginTop: "0px",
    }
  },
  "& .podcast-inner-box": {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  "& .podcast-inner-box-content": {
    flex: '1 0 auto',
    paddingBottom: 0
  },
  "& .post-podcast-inner-box-content": {
    flex: '1 0 auto',
  },
  "& .podcast-action-buttons": {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 2,
    paddingBottom: 10
  },
  "& .podcast-saving-options": {
    display: 'flex',
    flexDirection: 'column',
    gap: "9px"
  },
  "& .podcast-save-button": {
    color: 'white',
    marginRight: "0px",
    width: "118px",
    height: "26px",
    borderRadius: "8px",
    textTransform: "capitalize",
    gap: "8px",
    "&:first-of-type": {
      marginTop: "25px",
    },
    "&:not(:first-of-type)": {
      marginTop: "0px",
    }
  },
  "& .podcast-media": {
    width: "123px",
    height: "118px",
    marginTop: "34px",
    marginLeft: "44px",
    borderRadius: "3px"
  },
  "& .play-icon": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    position:"sticky",
    right:"10px"
  },
  "& .sponsored-title": {
    marginLeft: "15px",
    fontFamily: 'Nexa-Heavy'
  },
  "& .right-body-section": {
    marginTop: "-35px",
  },
  "& .recommended-cards": {
    display: 'flex',
    color: '#fff',
    height: '80px',
    borderRadius: '6px',
  },
  "& .podcast-inner": {
    fontFamily: 'Nexa-Heavy'
  },
  "& .community-list": {
   display : "flex",
   justifyContent : "space-between",
   alignItems: "center",
   height:"32px"
  },
  
  "& .community-list-buttons": {
    display : "flex",
    alignItems: "center"
   },
  "& .otherSectionContainer":{
   marginTop:"50px",
   width:"100%",
  },
  "& .otherSectionCardContainer":{
    display:"flex",
    justifyContent :"center",
    flexWrap: "wrap",
    gap: "35px",
    borderRadius: "8px",
  },
  "& .internalContainer":{
    display : "flex",
    justifyContent : "space-around",
    flexWrap : "wrap",
    width : "1000px",
  },
"& .otherSectionCard-img":{
    width:"100%", 
    maxWidth:"250px", 
    height:"223px"
},
  "& .otherSectionCard":{
    maxWidth: "250px",
    width: "100%",
    height: "300px",
    backgroundColor: "#000",
    borderRadius:"0px 0px 16px 16px",
    position : "relative"
  },
  "& .otherSectionCard-text1":{
    fontFamily: "Nexa-Heavy", 
    fontSize: "14px", 
    fontWeight: 900, 
    textTransform :"uppercase"
  },
"& .otherSectionCard-text2":{
  fontFamily : "Nexa-Heavy",
  fontSize : "16px",
  color : "#fff",
  width : "250px", 
},
"& .otherSectionCard-textContainer":{
  margin: "20px"
}, 
"& .channelInfoImg":{
  position :"relative",
  width: "100px", 
  height: "100px", 
  borderRadius: "8px"
}, 
"& .channelInfoHeader":{
 display : "flex",
 flexDirection : "column",
 justifyContent : "center"
}, 
"& .channelInfoHost":{
 display : "flex",
 alignItems : "center",
 gap : "30px"
}, 

"& .follow-btn":{
  position : "absolute",
  right : "10px",
  top : "12px",
  minWidth: "72px", height:"32px", borderRadius: "8px", fontFamily: "Nexa-Heavy", 
  fontSize:"16px", fontWeight: 900, padding: "6px 10px", textTransform:"none"
}, 
"& .modalBox":{
  display : "flex",
  justifyContent : "space-between",
  alignItems:"center",
  gap : "30px",
  position :"relative",
},

});
const webStyles = {
  title : {
    fontWeight: 900,
    letter: "-0.5%",
    color: "#fff",
    fontFamily: "Nexa-Heavy"
  },
  titledesc : {
    fontWeight: 400,
    color: "#fff",
    fontFamily: "Inter"
  },
  container: {
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "red",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "gray",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  } as const,
  cardText: {
    fontFamily:"Nexa-Heavy",
    fontSize:"14px",
    fontWeight:900,
    color:"#FFFFFF"
  }
}

const ShareChannelModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height : 466,
  bgcolor: '#44403C',
  borderRadius:2,
  p: 4,
  boxSizing:"border-box"
};

const SharePostModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 999,
  height : 343,
  bgcolor: '#44403C',
  borderRadius: "8px 8px 32px 8px",
  p: 4, 
  boxSizing:"border-box"
};


const DropDown = styled(Box)(
  {
    display : "flex",
    flexDirection : "column",
    backgroundColor : "#44403C",
    width: "185px",
    border : "1px solid white",
    position: "absolute",
    right: "94px",
  }
)



const StyledTypo1 = styled(Typography)({
  fontFamily:"Nexa-Heavy",
  fontWeight: 900,
  fontSize:"25px",
  color:"#FFFFFF",
  textAlign:"center"
})

const StyledTypo2 = styled(Typography)({
  fontFamily:"Nexa-Heavy",
  fontWeight: 900,
  fontSize:"50px",
  color:"#FFFFFF",
  textAlign:"center"
})


const CloseIconModal = styled(CloseIcon)({ color: "#fff", position: "absolute", left: "1120px" })

const CheckBoxModal = styled(Checkbox)(
  { color: "#44403c", '&.Mui-checked': { color: "white", }, borderColor: "#fff", width: "40px", height: "40px", border: "1px solid #fff", borderRadius: "10px" }
)
const ShareChannelModal = styled(Box)({
  display: "flex",
  alignItems: "center"
})

const CopyButton = styled(Button)(
  {
    width: "136px",
    height:"56px",
    marginLeft: "15px",
    borderRadius: "8px", color:"#FFF",
    fontSize:"16px", fontWeight:900, fontFamily:"Nexa-Heavy",
    background:"#FF0807", textTransform:"none",
    "&:hover": {
      backgroundColor: "#FF0807"
    }
  }
)

const CopyButtonEpisode = styled(Button)(
  {
    width: "136px",
    height:"56px",
    borderRadius: "8px", color:"#FFF",
    fontSize:"16px", fontWeight:900, fontFamily:"Nexa-Heavy",
    background:"#FF0807", textTransform:"none",
    "&:hover": {
      backgroundColor: "#FF0807"
    }
  }
)

const ShareIcon = styled(IconButton)({
  color: '#fff',
  marginBottom: "11px"
})

const UrlBox = styled(Box)(
  {
    display: "flex",
    width: "100%",
    alignItems:"center"
  }
)


const ContentCopy = styled(ContentCopyIcon)(
  {
    color: "#fff",
    marginRight: "12px"
  }
)

const ChannelTextFeild = styled(Typography)(
  {
    borderRadius: "5px",
    backgroundColor: "#000",
    border: "2px solid #fff",
    display: "flex",
    width: "61%",
    color: "#fff",
    padding: "20px",
    justifyContent: "start",
  }
)

const EpisodeTextFeild = styled(Typography)(
  {
    width: "707px", height:"56px",
    backgroundColor: "#000000",
    border: "1px solid #FFF",
    padding: "10px 8px",
    borderRadius: "8px",
    justifyContent: "start", alignItems:"center",
    color: "#FFFFFF",
    display: "flex", boxSizing:"border-box",
  }
)

const EpisodeStartAtTextFeild = styled(Typography)(
  {
    padding: "10px 8px",
    border: "1px solid #FFF",
    display: "flex", boxSizing:"border-box",
    borderRadius: "8px",
    width: "58px", height:"56px",
    color: "#FFFFFF",
    justifyContent: "start", alignItems:"center",
    backgroundColor: "#000000",
  }
)

const EmbededTextFeild = styled(Typography)(
  {
    borderRadius: "5px",
    backgroundColor: "#000",
    color: "#fff",
    border: "2px solid #fff",
    width: "61%",
    display: "flex",
    padding: "20px",
    justifyContent: "start",
  }
)

const EpisodeEmbededTextFeild = styled(Typography)(
  {
    backgroundColor: "#000000",
    border: "1px solid #FFF",
    borderRadius: "8px",
    width: "707px", height:"56px",
    color: "#FFFFFF",
    display: "flex", boxSizing:"border-box",
    justifyContent: "start", alignItems:"center",
    padding: "10px 8px"
  }
)

const ShareChannelText = styled(Typography)({
  fontFamily: "Nexa-Heavy",
  color: "#fff"
})

const ZeroInput = styled(Typography)({
  backgroundColor: "#000",
  border: "2px solid #fff",
  borderRadius: "5px",
  width: "46px",
  height: "46px",
  padding: "10px",
  color: "#fff",
  display: "flex",
  justifyContent: "start",
  alignItems: "center"
})

const LinkContainer = styled(Box)({
  display: "flex",
  width: "100%",
  alignItems: "center"
})

const CopyButton2 = styled(Button)({
  width: "136px",
  height:"56px",
  marginLeft: "15px",
  borderRadius: "8px", color:"#FFF",
  fontSize:"16px", fontWeight:900, fontFamily:"Nexa-Heavy",
  background:"#FF0807", textTransform:"none",
  "&:hover": {
    backgroundColor: "#FF0807"
  }
}
)

const PodcastContentBox = styled(CardContent)({
  padding: '22px 33px 22px 43px',
})

const AvatarImage = styled('img')({
  width: '56px',
  height: '56px',
  borderRadius: '50%',
  marginRight: '10px',
});

const TextFieldContainer = styled(Box)({
  backgroundColor: 'black',
  borderRadius: '20px 20px 0px 0px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const StyledTextField = styled(TextField)({
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'black',
    borderRadius: '8px',
    padding: '10px',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
})

const ReplyButtonBox = styled(Box)({
  position: 'absolute',
  bottom: '10px',
  right: '10px',
  display: 'flex',
  gap: '10px',
});

const StyledIconButton = styled(IconButton)({
  color: 'white',
  backgroundColor: '#DADADA',
});

const TextComment = styled(Typography)({
  fontSize: "30px", 
  marginTop: "25px", 
  lineHeight: "40px",
})

const StyledMenu = styled(Menu)({
  '.MuiList-root': {
    padding: 0,
  },
  '.MuiPaper-root': {
    borderRadius: 0,
  },
})

const StyledMenuItem = styled(MenuItem)({
  color: "#FFFFFF",
  border: "1px solid #FFFFFF",
  fontSize: "14px",
  width: "233px",
  display: "flex",
  alignItems: "center",
  '& img': {
    marginRight: "8px",
  },
});

const PodcastInnerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const PodcastContent = styled(CardContent)({
  padding: '16px',
});

const TextareaWrapper = styled(Box)({
  backgroundColor: '#030303',
  borderRadius: '0px 0px 7px 7px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const StyledTextFieldSecond = styled(TextField)({
  height: '90px',
  backgroundColor: '#030303',
  color: 'white',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'black',
    padding: '15px',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
  input: {
    fontFamily: 'Nexa-Heavy',
    fontWeight: 100,
    fontSize: '14px',
    color: '#FFFFFF',
  },
});

const FilePreviewBox = styled(Box)({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  backgroundColor: 'black',
  borderRadius: '8px',
  padding: '10px',
  position: 'relative',
});

const FileImage = styled('img')({
  width: '238px',
  height: '170px',
  borderRadius: '8px',
});

const FileRemoveIconButton = styled(IconButton)({
  position: 'absolute',
  left: '210px',
  top: '12px',
});

const ActionButtonsBox = styled(Box)({
  position: 'absolute',
  bottom: '10px',
  right: '10px',
  display: 'flex',
  gap: '10px',
});

const FileUploadButton = styled(IconButton)({
  height: '27px',
  width: '27px',
  padding: '10px',
  '&:hover': {
    backgroundColor: '#1976D2',
  },
});

const SendButton = styled(IconButton)({
  height: '27px',
  width: '27px',
  padding: '10px',
  '&:disabled': {
    backgroundColor: '#DADADA',
  },
});

const menuStyle = {
  "& .MuiPaper-root": {
      backgroundColor: "#44403C",
      color: "#FFFFFF",
      border: "1px solid #ffffff",
      width: "233px",
  },
  "& .MuiList-root": {
    padding:"0px"
  },
}
// Customizable Area End
