// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { createRef } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface S {
  selectedAds: Record<AdCategory, string[]>;
  microtargeting: Record<MicrotargetingCategory, "Yes" | "No">; 
}
export type AdCategory = "prePostRollAds" | "displayAds" | "sponsoredPosts" | "sponsoredRecommendations";
export type MicrotargetingCategory = "prePostRollAds" | "displayAds";

export interface SS {
  id: any;
}

export default class AdOffersSetupController extends BlockComponent<
  Props,
  S,
  SS
> {
  scrollRef: any = createRef();

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    
    this.state = {
      selectedAds: {
        prePostRollAds: [],
        displayAds: [],
        sponsoredPosts: [],
        sponsoredRecommendations: []
      },
      microtargeting: {
        prePostRollAds: "No",
        displayAds: "No",
      }
  
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }
  toggleSelection = (category: AdCategory, value: string) => {
    this.setState((prevState) => {
      const updatedAds = prevState.selectedAds[category].includes(value)
        ? prevState.selectedAds[category].filter((ad) => ad !== value)
        : [...prevState.selectedAds[category], value];
  
      return {
        selectedAds: { ...prevState.selectedAds, [category]: updatedAds }
      };
    });
  };
  
  handleMicrotargetingChange = (category: MicrotargetingCategory, value: string) => {
    this.setState((prevState) => ({
      microtargeting: { ...prevState.microtargeting, [category]: value }
    }));
  };
  isButtonDisabled = (): boolean => {
    const { selectedAds } = this.state;
    return Object.values(selectedAds).some((ads) => ads.length === 0);
  };

}
// Customizable Area End
