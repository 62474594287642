Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.userDetails ="account_block/accounts/profile";
exports.audioPlayer = "bx_block_audiomusic2/audio_players";
exports.lastPlayed = "bx_block_audiomusic2/audio_players/last_played";
exports.getChannelDataEndPoint = "bx_block_settings/settings/channel";
exports.getSavedEpisodesEndPoint= "bx_block_login/podcasts"
// Customizable Area End