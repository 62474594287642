// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
    Typography,
    Card,
    List,
    ListItem,
    Avatar,
} from "@mui/material";
import BlockedUsersController, {
    Props,
} from "./BlockedUsersController";
import BlockIcon from "@mui/icons-material/Block";
import Footer from "../../navigationmenu/src/Footer.web";
import { goBack } from "./assets";


export default class BlockedUsers extends BlockedUsersController {
    constructor(props: Props) {
        super(props);
    }
   blockedUsers = [
      { name: "Jenny Martinez", avatar: "https://via.placeholder.com/40" },
      { name: "THE ULTIMATE", avatar: "https://via.placeholder.com/40" },
      { name: "Jeanine Benes", avatar: "https://via.placeholder.com/40" },
      { name: "Fred Jacobs", avatar: "https://via.placeholder.com/40" },
      { name: "90’s TIME", avatar: "https://via.placeholder.com/40" },
    ];
    
    render() {
        return (
            <Box ref={this.scrollRef}>
              <StyledWrapper>
                  <Box sx={{ backgroundColor: "#000", color: "#fff",width:"85%",margin:"Auto",maxWidth:"1600px"}}>
                   <Box display="flex" alignItems="center" gap="20px">
                    <img onClick={this.handleGoBack} src={goBack} alt="Go Back" className="image" />
                    <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
                      Blocked Users
                    </Typography>
                    </Box>
                    <Card className="card">
                      <List>
                        {this.blockedUsers.map((user, index) => (
                          <ListItem key={index} className="listItem">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Avatar src={user.avatar} sx={{ mr: 2, height:"56px",width:"56px" }} />
                              <Box>
                                <Typography className="userName">{user.name}</Typography>
                                <Typography variant="body2" className="subText">
                                  This user has been blocked
                                </Typography>
                              </Box>
                            </Box>
                            <Button variant="contained" className="btn" startIcon={<BlockIcon />}>
                              Blocked
                            </Button>
                          </ListItem>
                        ))}
                      </List>
                    </Card>
                  </Box>
                  </StyledWrapper>
                <Footer navigation={this.props.navigation} id={"1"} />
                </Box>
        );
    }

}
const StyledWrapper = styled("div")({
  padding: "20px 50px",
    "& *": {
        boxSizing: "border-box",
    },
    "& .btn" :{
      background:"#FF0807",
      color:"#FFFFFF",
      fontFamily:"Nexa-Regular",
      fontWeight:900,
      fontSize:"16px",
      textTransform:"none",
        "&:hover": {
         backgroundColor: "#FF0807",
        }
    },
    "& .image":{
      marginBottom: '20px',
      width: "3%"    
    },
    "& .card":{
     backgroundColor: "#44403C",
     padding: "10px 20px 5px", 
     borderRadius: "10px"
    },
    "& .listItem":{
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "14px",
      backgroundColor: "#312F2D",
      borderRadius: "8px",
      p: 2,
      height:"100px" 
    },
    "& .userName":{
       fontFamily:"Nexa-Heavy",
       fontWeight:900,
       fontSize:"16px",
       color:"#F5F5F5"
    },
    '& .subText':{
      fontFamily:"Nexa-Regular",
      fontWeight:400,
      fontSize:"14px",
      color: "#F5F5F5"
    }
})   
// Customizable Area End
