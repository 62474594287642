export const monetizationBackImg = require("../assets/monetization.svg").default;
export const  walletImg = require("../assets/monetize1.jpeg").default;
export const PartnerPortalImg = require("../assets/monetize2.jpeg").default;
export const inventoryImg = require("../assets/monetize3.jpeg").default;
export const partnerPortalBackImg = require("../assets/partnerPortalBackImg.svg").default;
export const acceptedOffers = require("../assets/acceptedOffers.jpeg").default;
export const pendingOffers = require("../assets/pendingOffers.jpeg").default;
export const rejectedOffers = require("../assets/rejectedOffers.jpeg").default;
export const offersImg = require("../assets/offersImg.svg").default;


