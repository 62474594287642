import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    speed: number;
    isPlaying: boolean;
    currentTime: number;
    duration: number;
    currentTrack: number;
    videoTracks: any;
    volume: number;
    isPlay: boolean;
    isMuted: boolean;
    showShareModal: boolean;
    shareValue: string,
    embedValue: string,
    episodeTiming: string,
    // Customizable Area End
}

interface SS {
    id: number;
    // Customizable Area Start
    // Customizable Area End
}

export default class VideoPlayerComponentController extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start
            speed: 1,
            isPlaying: true,
            currentTime: 0,
            duration: 0.1,
            currentTrack: 0,
            videoTracks: {
                podcast_id: "",
                title: "",
                artist: "",
                src: "",
                episode_number: "",
                liked_count: "",
                liked: true,
                cover: "",
                liked_id: "",
                episode_id: ""
            },
            volume: 0.5,
            isPlay: false,
            isMuted: false,
            showShareModal: false,
            shareValue: "0",
            embedValue: "0",
            episodeTiming: "0",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    // Customizable Area End
}
