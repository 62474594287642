 // Customizable Area End
import { Box, Button, Card, CardContent, CardMedia, Grid, styled, Typography} from "@mui/material"
import React from 'react'; 
import Footer from "../../navigationmenu/src/Footer.web";
import AcceptedOffersController,{Props,AdData} from "./AcceptedOffersController.web";

const MIN_WINDOW_HEIGHT = 900

export default class AcceptedOffers extends AcceptedOffersController {
    constructor(props: Props) {
      super(props);
    }
  
    renderAdCards = (ads: AdData[]) => (
        <Grid container spacing={2}>
          {ads.map((ad, index) => (
            <Grid item key={index} sx={{width:"450px",height:"190px"}}>
              <Card sx={{ bgcolor: '#030303', color: 'white',padding:"10px" }}>
                <Typography className="description">{ad.description}</Typography>
                <Box sx={{display:"flex",flexDirection:"row"}}>
                <CardMedia component="img" image={ad.image} alt={ad.title} sx={{width:"130px",borderRadius:"8px",height:"115px" }} />
                <CardContent className="card">
                  <Box>
                  <Typography variant="h6" sx={{fontWeight:"900"}}>{ad.title}</Typography>
                  <Typography variant="body2" sx={{fontWeight:"900"}}>Deal Expires: {ad.expiry}</Typography>
                  </Box>
                  <Button size="small" className="viewBtn" >
                    View Offer
                  </Button>                
                </CardContent>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      );    
    render() { 
    return (
      <Box ref={this.scrollRef} sx={{ maxHeight: "100%", overflowY: "auto" }}>
      <StyledOffersWrapper>
      <Box sx={{ p: 3, backgroundColor: '#44403C', color: 'white', minHeight: '100vh',borderRadius:"8px" }}>
      <Typography className="title">Accepted Offers</Typography>

      <Box sx={{ mb: 4 }}>
        <Box className="subHeading">
        <Typography className="subtitle">Display Ads</Typography>
        <Button className="viewAllBtn">View All Accepted Display Ads</Button>
        </Box>
        {this.renderAdCards(this.state.displayAds)}
      </Box>

      <Box sx={{ mb: 4 }}>
      <Box className="subHeading">
        <Typography className="subtitle">Pre-Roll Ads</Typography>
        <Button className="viewAllBtn">View All Accepted Podcast Ads</Button>
        </Box>
        {this.renderAdCards(this.state.preRollAds)}
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography className="subtitle">Post-Roll Ads</Typography>
        {this.renderAdCards(this.state.postRollAds)}
      </Box>

      <Box sx={{ mb: 4 }}>
      <Box className="subHeading">
        <Typography className="subtitle">Sponsored Recommendations</Typography>
        <Button className="viewAllBtn">View All Sponsored Recommendations</Button>
      </Box>
        {this.renderAdCards(this.state.sponsoredRecommendations)}
      </Box>

      <Box sx={{ mb: 4 }}>
      <Box className="subHeading">
        <Typography className="subtitle">Sponsored Posts</Typography>
        <Button className="viewAllBtn">View All Sponsored Posts</Button>
      </Box>
        {this.renderAdCards(this.state.sponsoredPosts)}
      </Box>
      </Box>
      </StyledOffersWrapper>
      <Footer navigation={this.props.navigation} id={"1"} />
      </Box>  
      );
    }
  }

  

const StyledOffersWrapper = styled("div")({
  padding: "20px 50px",
  background: "black",
  "& *": {
      boxSizing: "border-box",
  },
  "& .container": {
      height: "auto",
      backgroundColor: "green",
      width:"95%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItens: "center",
      margin: 0,
      borderRadius: "0px 0px 8px 8px",
    },
    "& .title":{
      margin: "auto",
      height: "40px",
      color: "white",
      fontFamily: "Nexa-Heavy",
      fontWeight: 900,
      fontSize: "30px",
      marginBottom: "21px",
      textAlign:"center"
    },
    "& .subtitle":{
      color: "white",
      fontFamily: "Nexa-Heavy",
      fontWeight: 900,
      fontSize: "24px",
    },
    "& .subHeading":{
      display:"flex",
      flexDirection:"row",
      justifyContent:"space-between",
      width:"100%",
      alignItems:"center",
      padding:"10px 0px"
    },
    "& .viewAllBtn":{
        backgroundColor: "red",
        color: "white",
        padding: "5px 10px",
        textTransform:"capitalize",
        fontSize:"15px",
        fontFamily: "Nexa-Heavy",
        fontWeight:500,
        borderRadius:"8px",
        "&:hover": {
          backgroundColor: "red", 
          color: "white" 
        }
    },
    "& .viewBtn":{
        backgroundColor: "red",
        color: "white",
        padding: "5px 20px",
        textTransform:"capitalize",
        fontSize:"15px",
        fontFamily: "Nexa-Heavy",
        fontWeight:500,
        borderRadius:"8px",
        "&:hover": {
          backgroundColor: "red", 
          color: "white" 
        }
    },
    "& .card":{
     padding:"5px 35px",
     height:"116px",
     display:"flex",
     flexDirection:"column",
     justifyContent:"space-between",
     '&:last-child': { 
      paddingBottom: '5px' 
     }
    },
    "& .description":{
      fontSize:"15px",
      fontFamily: "Nexa-Regular",
      fontWeight:900,
      marginBottom:"10px"
    },

})
// Customizable Area End
