import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from 'react'
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { micImg } from "./assets";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void;
    handleAddAudio: (playable_type: string, playable_id: string) => void;
}

interface SS {
    id: any;
}
  
interface S {
    fullPlaylistInfo: IFullPlaylist;
    isLoading: boolean;
    showFullPlaylist: boolean;
}

interface PlaylistDetail {
  cover_image: string | undefined;
  name: string;
  episode_type: string;
  description: string;
  episode_number: number;
  song_added: boolean;
  episode_content: string | null;
  episodeId: string;
  save_episode_id: number;
  episode_saved: boolean;
}

interface IFullPlaylist {
  title: string;
  image: string | undefined;
  songs: PlaylistDetail[];
}

export default class FullPlayListController extends BlockComponent<Props, S, SS> {

  getFullPlaylistApiCallId: string = ""
  saveEpisodeCallId: string= ""
  unsaveEpisodeCallId: string = ""
  deleteEpisodeAPICallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
        fullPlaylistInfo: {
          title: "",
          image: micImg,
          songs: [
            {
              cover_image: undefined,
              name: "",
              episode_type: "",
              description: "",
              episode_number: 0,
              song_added: false,
              episode_content: null,
              episodeId:"",
              save_episode_id: 0,
              episode_saved: false
            },
          ],
        },
        isLoading: false,
        showFullPlaylist: false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getFullPlaylist();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(this.getFullPlaylistApiCallId === apiRequestCallId) {
        this.handleFullplaylistResponse(from, message);
      }

      if(this.saveEpisodeCallId === apiRequestCallId) {
        this.handleResForSaveEpisode(message);
      }

      if(this.unsaveEpisodeCallId === apiRequestCallId){
        this.handleResForUnSaveEpisode(message);
      }

      if(this.deleteEpisodeAPICallId === apiRequestCallId) {
        this.handleDeleteEpisodeResponse(message)
      }
    }
  }

  handleBack = () => {
    this.props.handleCommonPath("Playlist")
  }

  handleNavigateToPlaylist = () => {
    if(this.state.fullPlaylistInfo.title === "Main Playlist") {
      this.props.handleCommonPath("AddPlayList");
    }
    else {
      this.props.handleCommonPath("OtherPlayListEdit");
    }
  }
  
  getFullPlaylist = async() => {
    this.setState({isLoading: true});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getFullPlaylistApiCallId = requestMessage?.messageId

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    const getPlaylistId = await getStorageData("selected_playlist_id")

    let playlistId = getPlaylistId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.songListApiEndPoint}/${playlistId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFullplaylistResponse = async (from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiResponse?.playlist?.data?.id){
      const transformedPlaylist: IFullPlaylist = {
        title: apiResponse.playlist.data.attributes.title,
        image: apiResponse.playlist.data.attributes.image ? apiResponse.playlist.data.attributes.image : micImg,
        songs: apiResponse.playlist.data.attributes.songs.data.map((song:any) => ({
          episodeId: song.id,
          cover_image: song.attributes.cover,
          name: song.attributes.name,
          episode_type: song.attributes.episode_type,
          description: song.attributes.description,
          episode_number: song.attributes.episode_number,
          song_added: song.attributes.added.song_added,
          episode_content: song.attributes.episode_content,
          save_episode_id: song.attributes.save_episode.saved_id,
          episode_saved: song.attributes.save_episode.saved,
        })),
      };
      this.setState({fullPlaylistInfo: transformedPlaylist})
      this.setState({isLoading: false});
    }
    else {
      this.setState({isLoading: false});
      this.setState({showFullPlaylist: true});
    }

  }

  handleAddToSave = async(id: string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        saveable_type: "BxBlockLogin::Episode", 
        saveable_id: id
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveEpisodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveEpisode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForSaveEpisode = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.meta.message == "Successfully Saved") {
      this.getFullPlaylist();
    }
  };

  async handleRemoveFromSaveLater(id: number) {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unsaveEpisodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unSaveEpisode}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodtype
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForUnSaveEpisode = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Successfully Unsaved") {
      this.getFullPlaylist();                 
    }
  };

  handleAddedBtn = async(id: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const token = await getStorageData('token')
    const selected_playlist_id = await getStorageData('selected_playlist_id')

    let cleanedToken = token.slice(1, -1);
  
    this.deleteEpisodeAPICallId = requestMessage?.messageId

    let formdata = new FormData();
    formdata.append("id", selected_playlist_id);
    formdata.append("song_id", id);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeSong}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodtype
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeleteEpisodeResponse = async( message: Message) => {
    let episodeResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(episodeResponse?.message === "Song removed successfully") {
      this.getFullPlaylist();       
    }
    else {
      this.showAlert("Error", "Song Not Removed")
    }
  }

}
