// Customizable Area Start

import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    styled, Button
} from "@mui/material";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";

import WalletController, {
    Props,
} from "./WalletController";

export default class Wallet extends WalletController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box style={{ overflowY: "auto", maxHeight: "100%" }}>
                <StyledWrapper>
                    <Box style={{
                        background: "#44403C", padding: "35px 50px", flexDirection: "column",
                        gap: "30px", alignItems: "flex-start", display: "flex"
                    }}>
                        <Box style={{
                            display: "flex", background: "#312F2D", width: "492px",
                            height: "195px", borderRadius: "8px", flexDirection: "column",
                            gap: "12px", boxSizing: "content-box", padding: "25px"
                        }}>
                            <Typography style={{
                                fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "24px",
                                color: "#FFFFFF"
                            }}>
                                Amount in Wallet
                            </Typography>
                            <Typography style={{
                                fontFamily: "Inter", fontWeight: 700, fontSize: "50px",
                                color: "#FFFFFF"
                            }}>
                                $500
                            </Typography>
                            <Button style={{
                                fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "16px",
                                color: "#FFFFFF", background: "#FF0807", textTransform: "none",
                                height: "56px", width: "222px", alignSelf: "flex-end"
                            }}>
                                Transfer To Bank
                            </Button>
                        </Box>
                        <Box style={{ 
                            width: "100%", maxHeight: "446px", overflow: "hidden", overflowY: "auto"
                        }}>
                            <Box style={{
                                width:"98%", height:"auto",
                                borderRadius: "8px", background: "#312F2D", padding: "40px 60px",
                            }}>
                                <TableContainer component={Paper}
                                    style={{
                                        backgroundColor: "#312F2D", border: "1px solid #FFFFFF",
                                    }}
                                >
                                    <Table>
                                        <TableHead style={{ borderRadius: "8px 8px 0px 0px" }}>
                                            <TableRow>
                                                <StyledTableHeadCell>Name</StyledTableHeadCell>
                                                <StyledTableHeadCell>Item purchased</StyledTableHeadCell>
                                                <StyledTableHeadCell>Cost</StyledTableHeadCell>
                                                <StyledTableHeadCell>Date</StyledTableHeadCell>
                                                <StyledTableHeadCell>Time</StyledTableHeadCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, index) => (
                                                <TableRow key={index}>
                                                    <StyledTableCell>{row.name}</StyledTableCell>
                                                    <StyledTableCell>{row.item}</StyledTableCell>
                                                    <StyledTableCell>{row.cost}</StyledTableCell>
                                                    <StyledTableCell>{row.date}</StyledTableCell>
                                                    <StyledTableCell>{row.time}</StyledTableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                </StyledWrapper>
                <Footer navigation={this.props.navigation} id={"1"} />
            </Box>
        )
    }
}

const rows = [
    { name: "Joe Bold", item: "Tier 1 Subscription", cost: "Free", date: "Yesterday", time: "20:00" },
    { name: "Kate Jones", item: "Tier 2 Subscription", cost: "$1.99", date: "18 October", time: "35:00" },
    { name: "Kate Jones", item: "Display Ad", cost: "$9.99", date: "15 October", time: "20:00" },
    { name: "Cody Nils", item: "Podcast Ad", cost: "$20.5", date: "10 October", time: "5:00" },
    { name: "Ross Johns", item: "Podcast Ad", cost: "$100", date: "8 October", time: "35:00" },
    { name: "Mary Saeb", item: "Podcast Ad", cost: "$200", date: "5 October", time: "35:00" },
];

const StyledWrapper = styled(Box)({
    padding: "20px 50px",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
});

const StyledTableCell = styled(TableCell)({
    color: "#FFFFFF",
    fontWeight: 900,
    fontSize: "12px", fontFamily: "Nexa-Heavy",
    textAlign: "center",
});

const StyledTableHeadCell = styled(TableCell)({
    color: "#FFFFFF",
    fontWeight: 900, background: "#312F2D",
    fontSize: "24px", fontFamily: "Nexa-Heavy",
    textAlign: "center",
});


// Customizable Area End
