// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
} from "@mui/material";
import TypesOfAdsController, {
    Props,
} from "./TypesOfAdsController";
import Footer from "../../navigationmenu/src/Footer.web";
import {advertisingImg1,advertisingImg2,advertisingImg3,advertisingImg4} from "./assets";



export default class TypesOfAds extends TypesOfAdsController {
    constructor(props: Props) {
        super(props);
    }
    adOptions = [
        { id: 1, title: "Display Ads", image: advertisingImg1 },
        { id: 2, title: "Pre/Post Roll Ads", image: advertisingImg2 },
        { id: 3, title: "Sponsored Recs", image: advertisingImg3 },
        { id: 4, title: "Sponsored Post", image: advertisingImg4 },
      ];
      
    render() {
        return (
                <Box>
                <StyledWrapper>
                <Box sx={{height:"auto"}}>
                    <Typography className="Heading">Types of Ads for Host Offers</Typography>
                    <Typography className="subHeading">What type(s) of Ad Offers would you like to be able to send to hosts?
                   <br/><span style={{fontWeight:100}}>(Select all that apply)</span> </Typography>
                </Box>
                <Grid container spacing={2} justifyContent="center" margin="3% 0 0">
                    {this.adOptions.map((ad) => (
                        <Grid item key={ad.id}>
                        <Card
                        sx={{ backgroundColor: 'black', color: 'white' }}
                            className={
                            `cardStyles ${this.state.selectedAds.includes(ad.id) ? "selected" : ""}`
                            }
                            data-test-id={`ad-card-${ad.id}`}
                            onClick={() => this.toggleSelection(ad.id)}
                        >
                            <CardContent sx={{padding:"0"}}>
                            <img src={ad.image} alt={ad.title} className="ad-image" />
                            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",padding:"5%"}}>
                            <Typography align="center" sx={{fontFamily:"Nexa-Heavy",fontSize:"16px",fontWeight:900}}>{ad.title}</Typography>
                            </Box>
                            </CardContent>
                        </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box textAlign="center" mt={0} mb={3}>
                    <Button variant="contained" onClick={()=>this.props.handleCommonPath("AdOfferSetup")} className={`continueBtn ${this.state.selectedAds ? "disabledbtn" : "activebtn"}`} data-test-id="continueBtn">Continue</Button>
                </Box>
                </StyledWrapper>
                <Footer navigation={this.props.navigation} id={"1"} />
                </Box>
        );
    }

}
const StyledWrapper = styled("div")({
    background: "black",
    padding: "20px 50px",
    "& *": {
        boxSizing: "border-box",
    },
    "& .Heading":{
        color:"#FFFFFF",
        fontFamily:"Nexa-Heavy",
        fontWeight:900,
        fontSize:"30px",
        textAlign:"center"
    },
    "& .subHeading":{
        color:"#FFFFFF",
        fontFamily:"Nexa-Regular",
        fontWeight:400,
        fontSize:"16px",
        textAlign:"center"
    },
    "& .ad-image":{
        minWidth:"370px",
    },
    "& .selected":{
      border:"5px Solid #FF0807"
    },
    "& .cardStyles":{
        borderRadius:"20px"
    },
    "& .scrollHostLogin":{
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
        position: "relative",
        height: "34rem",
        paddingRight: "40px", 
        overflow: "hidden",     
        scrollbarWidth: "thin", 
    },
    "& .continueBtn" :{
        padding:"1% 10%",
        textTransform:"none",
        fontFamily : "Nexa-Heavy",
        cursor : "pointer",
        color:"#FFFFFF",
        fontWeight : 900,
        fontSize : "14px",     
    },
    "& .disabledBtn" :{
        background: "#B2B3B3",
        "&:hover" : {
            background : "#B2B3B3",        
        }
    },
    "& .activeBtn":{
        background: "#FF0807",
        "&:hover" : {
            background : "#FF0807",        
        }
    },
    "& .desc":{
        fontFamily : "Nexa-Regular",
        fontSize : "16px",
        margin : "auto",
        textAlign : "center", 
    },
    "& .title":{
        textAlign : "center",
        fontSize : "30px",
        color : "white",
        fontFamily : "Nexa-Heavy",
        fontWeight : 900
    },
    "& .container":{
    color : "white",
    height:"auto",
    margin:"auto",
    background:"#44403C",
    },
    "& .image":{
        display:"flex",
        height : "50%",
        width : "80%",
        paddingTop : "5%",
        boxSizing: "content-box",
        margin:"auto",       
    }
})   
// Customizable Area End
